import { styled } from '@linaria/react'
import { css } from '@linaria/core'

const getColor = (color, theme) => {
  switch (color) {
    case 'gray':
      return '#a0a0a0'
    case 'white':
      return theme.colors.whiteBase
    default:
      return theme.colors.redBase
  }
}

const spin = css`
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

export const SpinnerSc = styled.div`
  display: ${({ isInline }) => (isInline ? 'inline-block' : 'block')};
  position: relative;
  margin: 0 auto;
  text-align: center;
  ${({ isInline }) => isInline && 'vertical-align: middle;'};

  ${({ isFlex }) =>
    isFlex &&
    `
    display: flex;
    justify-content: center;
  `};
`

export const SpinnerLabelSc = styled.span`
  margin-left: ${({ size }) => (size < 16 ? 6 : 8)}px;
  color: ${({ color, theme }) => getColor(color, theme)};
`

export const SpinnerCircleSc = styled.svg`
  animation: ${spin} 800ms linear infinite;
  fill: ${({ color, theme }) => getColor(color, theme)};
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`

export default {
  SpinnerSc,
  SpinnerLabelSc,
  SpinnerCircleSc,
}
