/* eslint-disable camelcase */
import React, { Component, ComponentType, ReactNode } from 'react'

import { canUseDOM } from '~/lib/utils/can-use-dom'
import localforage from 'localforage'
import _forEach from 'lodash/forEach'
import _get from 'lodash/get'
import _take from 'lodash/take'
import apiEndpoints from '~/lib/config/api-endpoints'
import ContextTypes from '~/lib/config/listings-context-types'
import { trackEvent, eventTypes, contentTypes } from '~/lib/utils/google-tag-manager'
import { isLocalStorageEnabled } from '~/lib/utils/browser-support'
import debug from '~/lib/utils/debug'
import { getLocationName2, findLocationByLatLong } from '~/lib/utils/location-helper'
import StorageAdapter from '~/lib/utils/storage-adapter'
import { SearchAutoCompleteMultiProps } from './search-auto-complete-multi-types'

type StorageAdapterOptionsType = {
  storageObject: object
  cacheInvalidationThreshold: number
  visitedLimit: number
  recentItemLimit: number
  uniqueKey: string
  keyPrefix: string
  updateKey: string
  visitedKey: string
  cacheKeyPrefix: string
  minSearchQueryLength: number
}

const defaultStorageAdapterOptions: StorageAdapterOptionsType = {
  storageObject: localforage,
  cacheInvalidationThreshold: 7, // days to cache searches
  visitedLimit: 8,
  recentItemLimit: 5,
  uniqueKey: 'uri',
  keyPrefix: '',
  updateKey: 'updatedAt',
  visitedKey: 'visited',
  cacheKeyPrefix: '',
  minSearchQueryLength: 0,
}

type Props = SearchAutoCompleteMultiProps & {
  searchMethod?: (...args: any[]) => any
  query?: string
  locations?: object[]
  locationsLimit?: number
  cacheKeyPrefix?: string
  cacheKey?: string
  visitedCacheKey?: string
  showClear?: boolean
  minSearchQueryLength?: number
  onSelectResult?: (...args: any[]) => any
  onSetDefault?: (...args: any[]) => any
  onClear?: (...args: any[]) => any
  onFocus?: (...args: any[]) => any
  label?: string
  isSmall?: boolean
  isInline?: boolean
  prefillLocations?: boolean
  showRecent?: boolean
  alwaysShowRecent?: boolean
  autoFocus?: boolean
  alwaysFocus?: boolean
  showHeaderIcons?: boolean
  useCache?: boolean
  showRemove?: boolean
  moreOptions?: ReactNode
}

type State = {
  value: string
  lastQuery: string
  results: any
  hideFirst: boolean
  showCurrentLocation: boolean
  currentLocationMessage: string
  fields: string[]
}

const searchedLocationsCache = 'searchedLocationsCache'

const storage = localforage.createInstance({
  name: 'location-search',
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
})

const geoOptions = {
  maximumAge: 5 * 60 * 1000,
  timeout: 5 * 1000,
}

const withSearchAutoComplete = <T extends Props = Props>(WrappedComponent: ComponentType<T>) => {
  class WithSearchAutoComplete extends Component<Props, State> {
    static defaultProps = {
      searchMethod: apiEndpoints.locationSearch,
      locations: [],
      locationsLimit: 10,
      query: '',
      cacheKeyPrefix: '',
      cacheKey: 'fn-locations',
      visitedCacheKey: defaultStorageAdapterOptions.visitedKey,
      onSelectResult: null,
      onSetDefault: null,
      onClear: null,
      onFocus: null,
      showClear: true,
      minSearchQueryLength: 2,
      isSmall: false,
      isInline: false,
      label: '',
      prefillLocations: false,
      showRecent: false,
      alwaysShowRecent: false,
      autoFocus: false,
      alwaysFocus: false,
      showHeaderIcons: false,
      useCache: true,
      showRemove: true,
      moreOptions: null,
    }

    hasMounted = false

    storageAdapterOptions: StorageAdapterOptionsType = { ...defaultStorageAdapterOptions }

    constructor(props) {
      super(props)
      this.initCache(props)

      const showCurrentLocation = canUseDOM && navigator && !!navigator.geolocation

      this.state = {
        value: '',
        lastQuery: props.query,
        results: null,
        hideFirst: false,
        showCurrentLocation,
        currentLocationMessage: null,
        fields: ['locations', 'recent'],
      }

      this.onSelect = this.onSelect.bind(this)
      this.handleRemoveRecent = this.handleRemoveRecent.bind(this)
      this.handleRemoveLocation = this.handleRemoveLocation.bind(this)
      this.onSearch = this.onSearch.bind(this)
      this.handleClearSearch = this.handleClearSearch.bind(this)
      this.onSetRecent = this.onSetRecent.bind(this)
      this.flattenResults = this.flattenResults.bind(this)
      this.setRecent = this.setRecent.bind(this)
      this.handleCurrentLocation = this.handleCurrentLocation.bind(this)
    }

    UNSAFE_componentWillMount() {
      const { query } = this.props
      if (query) {
        this.setState({ value: query })
      }
    }

    componentDidMount() {
      this.hasMounted = true
      this.checkRecentCache()

      this.checkSearchedLocationsCache().then(locations => {
        this.prefillAndSetDefault(locations)
      })
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
      const { query: nextQuery, isOpen: nextIsOpen } = nextProps
      const { query: prevQuery, isOpen: prevIsOpen } = this.props
      const { hideFirst } = this.state
      if (prevQuery !== nextQuery) {
        this.setState({ value: nextQuery })
      }
      // Restore hidden first location when closing.
      if (hideFirst && prevIsOpen && !nextIsOpen) {
        this.setState({ hideFirst: false })
      }
    }

    componentWillUnmount() {
      this.hasMounted = false
    }

    handleCurrentLocation() {
      let messageTimeout

      const geoSuccess = position => {
        this.setState({ currentLocationMessage: null })
        clearTimeout(messageTimeout)
        findLocationByLatLong(position.coords).then(location => {
          this.handleSelectResult(location, true, true)
          this.setRecent(location) // Mottey Q: Is this the best place to setRecent?
        })
      }

      const geoError = error => {
        switch (error.code) {
          case error.POSITION_UNAVAILABLE:
          case error.PERMISSION_DENIED:
          case error.TIMEOUT:
            // TODO replace toast
            console.log(
              'There is no location support on this device or it is disabled. Please check your settings.',
            )
            this.setState({ currentLocationMessage: null })
            trackEvent({
              contentType: contentTypes.currentLocationNotFound,
              pageElementPosition: 'current_location_btn',
              extraData: `${error.code}: ${error.message}`,
              event: eventTypes.error,
            })
            break
          default:
            break
        }
      }

      this.setState({ currentLocationMessage: 'spinner' })

      messageTimeout = setTimeout(() => {
        this.setState({ currentLocationMessage: null })
      }, 5000)

      navigator.geolocation.getCurrentPosition(geoSuccess, geoError, geoOptions)
    }

    handleRemoveRecent(identifier) {
      // identifier is an object e.g. { uri: 1234 }
      const { useCache } = this.props
      if (useCache) {
        return StorageAdapter.removeFromVisited(identifier).then((items: []) => {
          const { results } = this.state
          results.recent = _take(items || [], this.storageAdapterOptions.recentItemLimit)
          this.setState({ results })
        })
      }
      return Promise.resolve()
    }

    handleRemoveLocation(location) {
      const { locations, showRemove, context, onSelectResult } = this.props

      if (!showRemove) return

      // Hide first location instead of removing.
      if (context === ContextTypes.Location && (!location || locations.length === 1)) {
        this.setState({ hideFirst: true })
        return
      }
      if (onSelectResult) {
        const filtered = locations.filter(result => result.id !== location?.id)
        onSelectResult(filtered)
        this.addSearchedLocationsCache(filtered)
      }
    }

    handleClearSearch() {
      const resetResults: any = {}
      const { locations, showRecent, onClear, onSelectResult } = this.props

      if (showRecent) {
        resetResults.recent = _get(this.state, 'results.recent')
      }

      this.setState({ results: resetResults, lastQuery: null, value: '', hideFirst: true })
      if (locations.length > 1 && onSelectResult) {
        const results = [locations[0]]
        onSelectResult(results)
        this.addSearchedLocationsCache(results)
      }
      if (onClear) {
        onClear()
      }
    }

    handleSelectResult(location, isRecent = true, isCurrent = false) {
      const { locations, locationsLimit, context, onSelectResult } = this.props
      const { hideFirst } = this.state
      const item = location
        ? {
            ...location,
            isRecent,
            isCurrent,
            name: getLocationName2(location), // add formatted location name.
            suburb: location?.name,
          }
        : null

      const isLocationContext = context === ContextTypes.Location

      const exists = isLocationContext
        ? locations.findIndex(location => location.id === item.id) !== -1
        : false

      if (item && (!hideFirst ? !exists : true) && onSelectResult) {
        let results = [...locations, item].slice(-locationsLimit)

        // Replace first location if hidden or drawn/area search.
        if (!isLocationContext || (hideFirst && locations.length === 1)) {
          results = [item]
          this.setState({ hideFirst: false })
        }
        onSelectResult(results, item)

        this.addSearchedLocationsCache(results)
      }
    }

    onSelect(index) {
      const { results } = this.state

      if (results === null) {
        return
      }

      // Flatten results into a single array
      const flattenedArray = this.flattenResults()
      const visitedItem = flattenedArray && flattenedArray.length ? flattenedArray[index] : null
      const isRecent = results?.recent?.findIndex(item => item.id === visitedItem?.id) !== -1

      if (visitedItem) {
        this.setRecent(visitedItem, isRecent)
      }
    }

    onSearch(query, value) {
      const { searchMethod } = this.props
      const { results } = this.state
      const url = searchMethod(query)
      const newResults = results
      if (query === '' && newResults && newResults.locations) {
        newResults.locations = []
      }
      this.setState({
        lastQuery: query,
        value,
        results: newResults,
      })
      if (query.length > 0) {
        this.retrieveOrFetchRecords(query, url)
      }
    }

    onSetRecent() {
      const { useCache } = this.props
      const { results } = this.state
      if (isLocalStorageEnabled() && useCache) {
        return StorageAdapter.getSearchHistory().then((items: []) => {
          const recent = _take(items || [], this.storageAdapterOptions.recentItemLimit)
          const newResults = results || {}
          newResults.recent = recent
          if (this.hasMounted) {
            this.setState({ results: newResults })
          }
        })
      }
      return Promise.resolve
    }

    setRecent(visitedItem, isRecent = false) {
      const { useCache } = this.props
      const { results } = this.state
      const promise =
        isLocalStorageEnabled() && useCache
          ? this.addOrUpdateCache([visitedItem])
          : Promise.resolve()
      promise
        .then(() => {
          if (this.hasMounted) {
            const newResults = { ...results, locations: [] }
            this.setState({
              value: '',
              results: newResults,
            })
          }
          this.onSetRecent()
          this.handleSelectResult(visitedItem, isRecent)
        })
        .catch(err => {
          debug.error(err)
        })
    }

    addSearchedLocationsCache = locations => storage.setItem(searchedLocationsCache, locations)

    checkSearchedLocationsCache = () => storage.getItem(searchedLocationsCache)

    checkRecentCache() {
      const { showRecent, useCache } = this.props

      // Recent.
      if (canUseDOM && isLocalStorageEnabled() && useCache) {
        if (!showRecent) {
          return Promise.resolve()
        }
        return StorageAdapter.getSearchHistory()
          .then((historyItems: []) => {
            if (historyItems && historyItems.length && this.hasMounted) {
              const { results } = this.state
              const visitedItems = _take(historyItems, 5)
              const newResults = results || {}
              newResults.recent = visitedItems
              this.setState({ results: newResults })
            }
          })
          .catch(err => {
            debug.error(err)
          })
      }
      return Promise.resolve()
    }

    initCache(props) {
      const { useCache, visitedCacheKey, minSearchQueryLength, cacheKeyPrefix } = props
      this.storageAdapterOptions.visitedKey = visitedCacheKey
      this.storageAdapterOptions.minSearchQueryLength = minSearchQueryLength
      this.storageAdapterOptions.cacheKeyPrefix = cacheKeyPrefix

      if (canUseDOM && isLocalStorageEnabled() && useCache) {
        // Ensure we don't trip over other instances if there's more than
        // one autocomplete on a page
        StorageAdapter.constructor(this.storageAdapterOptions)

        // Check if cached data has expired based on adapter options
        StorageAdapter.invalidateCache([this.storageAdapterOptions.visitedKey])
      }
    }

    flattenResults() {
      const { fields, results } = this.state
      let flattenedArray = []
      if (results) {
        _forEach(fields, field => {
          if (results[field]) {
            flattenedArray = flattenedArray.concat(results[field])
          }
        })
      }
      return flattenedArray
    }

    prefillAndSetDefault(locations) {
      const { prefillLocations, onSetDefault } = this.props
      if (prefillLocations && onSetDefault && locations && locations.length > 0) {
        onSetDefault(locations)
      }
    }

    updateLatest(query, items) {
      const { alwaysShowRecent } = this.props
      const { lastQuery, results } = this.state
      if (query === lastQuery) {
        const recent =
          alwaysShowRecent && results && results.recent ? { recent: results.recent } : null

        const newResults = { ...items, ...recent }
        this.setState({ results: newResults })
      }
    }

    addOrUpdateCache(visitedItem) {
      return StorageAdapter.addOrUpdateCache(this.storageAdapterOptions.visitedKey, visitedItem)
    }

    retrieveOrFetchRecords(query, url) {
      const { cacheKey, useCache } = this.props
      const cacheable = isLocalStorageEnabled() && useCache
      return StorageAdapter.retrieveOrFetchRecords(query, url, cacheKey, cacheable).then(items => {
        // Ensure we return an object if the results are an array
        if (items && Array.isArray(items)) {
          this.updateLatest(query, { items })
        } else {
          this.updateLatest(query, items)
        }
      })
    }

    render() {
      const { locations, placeholder } = this.props
      const { value, results, fields, hideFirst, showCurrentLocation, currentLocationMessage } =
        this.state
      return (
        <WrappedComponent
          {...(this.props as T)}
          fields={fields}
          locations={!hideFirst ? locations : []}
          placeholder={!hideFirst ? placeholder : null}
          value={value}
          results={results}
          resultsLength={this.flattenResults().length}
          showCurrentLocation={showCurrentLocation}
          currentLocationMessage={currentLocationMessage}
          onRemoveRecent={this.handleRemoveRecent}
          onRemoveLocation={this.handleRemoveLocation}
          onSelect={this.onSelect}
          onSearch={this.onSearch}
          onClearSearch={this.handleClearSearch}
          onCurrentLocation={this.handleCurrentLocation}
        />
      )
    }
  }

  return WithSearchAutoComplete
}

export default withSearchAutoComplete
