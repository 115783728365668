import React from 'react'

import { getCdnPath } from '~/lib/utils/cdn-image'

import ExternalHyperlink from '~/components/external-hyperlink'

import {
  FooterPartnersBgSc,
  FooterPartnersSc,
  ColumnSc,
  FooterTitleSc,
  PartnersSc,
} from './footer-partners.style'

const FooterPartners = () => (
  <FooterPartnersBgSc>
    <ColumnSc>
      <FooterPartnersSc>
        <FooterTitleSc>Partner sites</FooterTitleSc>
        <PartnersSc>
          <ExternalHyperlink href="https://nationalpropertygroup.com.au/" nofollow={false}>
            <img
              src={getCdnPath('/assets/images/footer-npg-logo.png')}
              width={54}
              height={22}
              alt="National Property Group"
              loading="lazy"
            />
          </ExternalHyperlink>
          <ExternalHyperlink href="https://au.hougarden.com/" nofollow={false}>
            <img
              src={getCdnPath('/assets/images/footer-hougarden-logo.png')}
              width={75}
              height={28}
              alt="Hougarden"
              loading="lazy"
            />
          </ExternalHyperlink>
          <ExternalHyperlink href="https://wellmoney.com.au/" nofollow={false}>
            <img
              src={getCdnPath('/assets/images/footer-well-money-logo.png')}
              width={86}
              height={18}
              alt="Well Money"
              loading="lazy"
            />
          </ExternalHyperlink>
          <ExternalHyperlink href="https://tenantsconnect.com.au/" nofollow={false}>
            <img
              src={getCdnPath('/assets/images/footer-ten-ants-logo.png')}
              width={64}
              height={20}
              alt="Ten Ants"
              loading="lazy"
            />
          </ExternalHyperlink>
        </PartnersSc>
      </FooterPartnersSc>
    </ColumnSc>
  </FooterPartnersBgSc>
)

export default FooterPartners
