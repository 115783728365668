import React, { ReactNode, useRef } from 'react';

import useOnClickOutside from '~lib/hooks/useOnClickOutside';

import { NavDropdownSc } from './nav-dropdown.style';

interface Props {
  isOpen: boolean;
  isRight?: boolean;
  children: ReactNode;
  onHide: () => void;
}

const NavDropdown = ({ isOpen, isRight = false, children, onHide }: Props) => {
  const ref = useRef() as React.RefObject<HTMLElement>;

  useOnClickOutside(ref, () => onHide?.());

  return (
    <NavDropdownSc ref={ref} isOpen={isOpen} isRight={isRight}>
      {children}
    </NavDropdownSc>
  );
};

export default NavDropdown;