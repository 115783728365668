import { styled } from '@linaria/react';

export const HomeListingsSc = styled.div`
  margin: 0 0 -28px 0;
  @media (min-width: 1336px) {
    margin: 0 -20px -28px 0;
  }
`;

export const HomeListingsLinkSc = styled.div`
  margin: 32px 0 0;
  min-height: 32px;
`;
