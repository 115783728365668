const links = [
  {
    title: 'Real estate for sale',
    keywords: 'real estate',
    uri: '/buy/locationUri',
    slug: '/for-sale/locationSlug/real-estate',
  },
  {
    title: 'Real estate for rent',
    keywords: 'rentals',
    uri: '/rent/locationUri',
    slug: '/for-rent/locationSlug/real-estate',
  },
  {
    title: 'Top rated suburbs',
    keywords: 'reviews',
    uri: '/suburb-profile/locationUri',
    slug: '/suburb-profile/locationSlug',
  },
  {
    title: 'Suburbs with questions',
    keywords: 'questions',
    uri: '/locationUri/questions',
  },
  {
    title: 'Real estate agents',
    keywords: 'real estate agents',
    uri: '/real-estate-agents/locationUri',
    slug: '/real-estate-agents/locationSlug',
  },
];

export default links;
