export enum NavTypes {
  None = '',
  Home = 'home',
  Buy = 'buy',
  Rent = 'rent',
  Sold = 'sold',
  AgentFinder = 'agent-finder',
  Locations = 'locations',
  Questions = 'questions',
  Blog = 'blog',
  Collections = 'collections',
  Alerts = 'alerts',
  User = 'user',
}
