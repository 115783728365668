import categories from '~/modules/home/components/home-search/home-search-categories'

import SearchOptionButton from './components/search-option-button'

import { SearchOptionsSc } from './search-options.style'

type Props = {
  active: string
  onSelect: (...args: any[]) => any
}

const SearchFilterMode = ({ active, onSelect }: Props) => (
  <SearchOptionsSc>
    {categories.map(category => (
      <SearchOptionButton
        key={category.value}
        category={category}
        isActive={active === category.value}
        onClick={onSelect}
      />
    ))}
  </SearchOptionsSc>
)

export default SearchFilterMode
