import React, { Fragment } from 'react'
import { Link } from '@remix-run/react'

import navOptions, { FooterLinkItem } from '~/lib/config/footer-nav-options'
import { get } from '~/lib/config/app-config'

import Logo from '~/components/logo'
import FooterSocial from '~/components/footer-social'
import MobileAppBadges from '~/components/mobile-app-badges'

import FooterPartners from './components/footer-partners'

import {
  FooterSc,
  FooterNavSc,
  FooterBrandingContainerSc,
  LogoContainerSc,
  FooterListsContainerSc,
  FooterListSc,
  FooterListGroupTitleSc,
  FooterLinkSc,
  FooterLinkNewLabelSc,
  MobileAppsContainerSc,
  FooterCopyrightNavSc,
  CopyrightTextSc,
  LegalLinksContainerSc,
  LegalLinkSc,
  LineSc,
} from './footer.style'

type Props = {
  isSearch?: boolean
  paddingBottom?: boolean
  stickyAdMobile?: boolean
  stickyAdDesktop?: boolean
  showBorder?: boolean
}

const Footer = ({
  isSearch = false,
  paddingBottom = false,
  stickyAdMobile = false,
  stickyAdDesktop = false,
  showBorder = true,
}: Props) => {
  const renderLink = (item: FooterLinkItem) => {
    return (
      <FooterLinkSc href={item.url}>
        {item.label}
        {item.new && <FooterLinkNewLabelSc>New</FooterLinkNewLabelSc>}
      </FooterLinkSc>
    )
  }

  return (
    <>
      {showBorder && <LineSc />}
      <FooterSc
        isSearch={isSearch}
        paddingBottom={paddingBottom}
        stickyAdMobile={stickyAdMobile}
        stickyAdDesktop={stickyAdDesktop}
        role="contentinfo"
      >
        <FooterNavSc>
          <FooterBrandingContainerSc>
            <LogoContainerSc>
              <Logo width={180} height={42} />
            </LogoContainerSc>
            <MobileAppsContainerSc>
              <MobileAppBadges pageElementPosition="footer" />
            </MobileAppsContainerSc>
          </FooterBrandingContainerSc>
          <FooterListsContainerSc>
            {navOptions.map(nav => (
              <FooterListSc key={nav.title}>
                <FooterListGroupTitleSc>{nav.title}</FooterListGroupTitleSc>
                {nav.items.map(item => (
                  <Fragment key={item.label}>
                    {item.canRoute ? (
                      <Link to={item.url} prefetch={false}>
                        {renderLink(item)}
                      </Link>
                    ) : (
                      renderLink(item)
                    )}
                  </Fragment>
                ))}
              </FooterListSc>
            ))}
          </FooterListsContainerSc>
        </FooterNavSc>

        <FooterCopyrightNavSc isSearch={isSearch}>
          <CopyrightTextSc isSearch={isSearch} title={`v${get().appVersion}`}>
            {`Copyright \u00A9 ${new Date().getFullYear()} Homely Group Pty Ltd.`}
            <span> All rights reserved.</span>
            <LegalLinksContainerSc isSearch={isSearch}>
              <Link to="/privacy-policy" prefetch={false}>
                <LegalLinkSc>Privacy Policy</LegalLinkSc>
              </Link>
              <Link to="/terms-of-use" prefetch={false}>
                <LegalLinkSc>Terms of Use</LegalLinkSc>
              </Link>
            </LegalLinksContainerSc>
          </CopyrightTextSc>
          <FooterSocial isSearch={isSearch} />
        </FooterCopyrightNavSc>
        <FooterPartners />
      </FooterSc>
    </>
  )
}

export default Footer
