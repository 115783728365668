import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const SectionSc = styled.section`
  margin: 100px 0;
  @media ${theme.breakpoints.desktop} {
    margin: 120px 0;
  }

  content-visibility: auto;
`
