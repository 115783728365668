import React from 'react';
import { IconWrapperSc, IconSc } from './icon.style';

type Props = {
  icon: string;
  isHidden?: boolean;
  isSmall?: boolean;
  isTiny?: boolean;
  wrapper?: boolean;
  rotate?: number;
};

const Icon = ({
  icon,
  isHidden = false,
  isSmall = false,
  isTiny = false,
  wrapper = true,
  rotate = 0,
}: Props) => {
  const image = (
    <IconSc
      className="icon"
      isSmall={isSmall}
      isTiny={isTiny}
      aria-hidden={isHidden}
      rotate={rotate}
    >
      <use xlinkHref={`#icon-${icon}`} />
    </IconSc>
  );
  return wrapper ? (
    <IconWrapperSc className="icon-wrapper" role="presentation" isSmall={isSmall} isTiny={isTiny}>
      {image}
    </IconWrapperSc>
  ) : (
    image
  );
};

export default Icon;
