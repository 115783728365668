import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const ListingCardEmptySc = styled.div<{ isSearch: boolean }>`
  width: ${({ isSearch }) => (isSearch ? '100%' : '310px')};

  @media ${theme.breakpoints.desktop} {
    width: ${({ isSearch }) => (isSearch ? '100%' : '288px')};
  }
`

export const ListingCardEmptyImageSc = styled.div<{ isSearch: boolean }>`
  padding-bottom: 66.6667%;
  background-color: ${theme.colors.grayLight2};
  border-radius: ${({ isSearch }) => (isSearch ? '0' : '12px 12px 0 0')};

  @media ${theme.breakpoints.desktop} {
    border-radius: 12px 12px 0 0;
  }
`

export const ListingCardEmptyContentSc = styled.div`
  padding: 16px;
  height: auto;
`
