// import styled, { css } from 'styled-components';
import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const ScrollListSc = styled.div<{ isSearch: boolean }>`
  position: relative;
  margin: -20px;

  @media (min-width: 1336px) {
    margin-right: ${({ isSearch }) => (isSearch ? '-20px' : 0)};
  }
`

export const ScrollListTrackSc = styled.div`
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  user-select: none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const ScrollListContentSc = styled.div`
  display: flex;
  float: left; /* To size to content & not be clamped to available width. */
  padding: 20px 20px 20px 0;

  @media print {
    flex-wrap: wrap;
  }
`

export const ScrollListItemSc = styled.div<{ isCenter: boolean }>`
  padding: 0 0 0 20px;
  scroll-snap-align: ${({ isCenter }) => (isCenter ? 'center' : 'start')};
  &:not(:last-child) {
    margin-right: -4px;
  }
`

export const ScrollListButtonHolderSc = styled.div<{
  isPrev?: boolean
  isSearch: boolean
  isAlt: boolean
}>`
  position: absolute;
  top: 0;
  left: ${({ isPrev }) => (isPrev ? 0 : 'auto')};
  right: ${({ isPrev }) => (!isPrev ? 0 : 'auto')};

  @media (min-width: 1336px) {
    left: ${({ isPrev, isSearch }) => (isPrev ? (isSearch ? '0' : '-48px') : 'auto')};
    right: ${({ isPrev, isSearch }) => (!isPrev ? (isSearch ? '0' : '-68px') : 'auto')};
  }

  align-items: center;
  justify-content: center;

      height: 100%;
  button {
    width: 48px;
    height: 100%;
    color: ${theme.colors.grayMedium};
    background: ${({ isAlt }) => (isAlt ? theme.colors.grayLighter2 : theme.colors.whiteBase)};
    transform: ${({ isPrev }) => (isPrev ? 'scaleX(-1)' : 'none')};
  }

  display: none;
  @media ${theme.breakpoints.desktop} {
    display: flex;
  }
  @media print {
    display: none;
  }
`
