import React, { ReactNode } from 'react';
import { SkeletonCardSc } from './skeleton-card.style';

type Props = {
  isAlt?: boolean;
  children: ReactNode;
};

const SkeletonCard = ({ isAlt = false, children }: Props) => (
  <SkeletonCardSc isAlt={isAlt}>{children}</SkeletonCardSc>
);

export default SkeletonCard;
