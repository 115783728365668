import React, { useRef, useState, useEffect } from 'react'
import { usePageInfo } from '~/lib/providers/pageInfo-context'
import KeyEvent from '~/lib/utils/key-event'
import SearchAutoCompleteOptions from '~/components/search-auto-complete-options'
import Icon from '../icon'
import ResultList from './components/result-list'
import SearchTag from './components/search-tag'
import {
  SearchAutoCompleteSc,
  SearchFormSc,
  SearchInputSc,
  SearchMoreOptionsSearchSc,
  SearchMoreOptionsResultsSc,
  SearchResultsSc,
  SearchOptionsSeperatorSc,
  SearchBarSc,
  SearchBoxSc,
  SearchIconSc,
  SearchTagListSc,
  SearchOptionsSc,
  SearchClearSc,
  SearchButtonSc,
  SearchMoreSc,
  SearchMoreOptionsButtonSc,
  SearchPlaceholderSc,
} from './search-auto-complete-multi.style'
import withSearch from './with-search'
import { SearchAutoCompleteMultiProps } from './search-auto-complete-multi-types'

const SearchAutoCompleteMulti = ({
  context = null,
  locations,
  fields,
  isOpen,
  locationsLimit = 10,
  results = null,
  resultsLength = 0,
  tagDisplayLimitDefault = 1,
  placeholder = null,
  onSelect = null,
  onSearch = null,
  onClearSearch = null,
  onRemoveRecent = null,
  onRemoveLocation = null,
  showIcon = false,
  showClearLarge = false,
  isListingsModal = false,
  showSurrounding = false,
  isSurroundSearch = true,
  isHero = false,
  isMap = false,
  isInline = false,
  value = '',
  showOptions = false,
  showRemove = true,
  showButtonIcon = false,
  showButtonText = false,
  currentLocationMessage = null,
  moreOptionsSearch = null,
  moreOptionsResults = null,
  onOpen = null,
  onClose = null,
  onSearchButton = null,
  onCurrentLocation = null,
  onToggleSurrounding = null,
}: SearchAutoCompleteMultiProps) => {
  const { isDesktop, width } = usePageInfo()

  const inputRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const handleBarClick = () => {
    if (isOpen) {
      inputRef?.current?.focus()
    }
    if (!isOpen && onOpen) {
      onOpen()
    }
  }

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus()
    }
  }, [isOpen])

  const handleSearch = event => {
    const { value: eventValue } = event.target
    onSearch(eventValue.trim().toLowerCase(), eventValue)
    setSelectedIndex(0)

    if (!isOpen && onOpen) {
      onOpen()
    }
  }

  const handleHover = index => setSelectedIndex(index)

  const navigateList = event => {
    let direction = null
    const { keyCode } = event

    if (resultsLength < 1) {
      return
    }
    if (keyCode === KeyEvent.DOM_VK_UP) {
      direction = -1
    }
    if (keyCode === KeyEvent.DOM_VK_DOWN) {
      direction = 1
    }

    const withinLowerLimit = selectedIndex > 0 || false
    const withinUpperLimit = selectedIndex < resultsLength - 1 || false
    const withinRange =
      (direction === -1 && withinLowerLimit) || (direction === 1 && withinUpperLimit)

    if (withinRange) {
      setSelectedIndex(selectedIndex + direction)
    }
  }

  const handleSelect = e => {
    if (onSelect) {
      onSelect(selectedIndex)
      setSelectedIndex(0)
      inputRef?.current?.focus()
    }
    e.preventDefault()
  }

  const handleClear = e => {
    if (onClearSearch) {
      onClearSearch()
      setSelectedIndex(0)
    }
    e.preventDefault()
  }

  const handleSearchButton = e => {
    if (onSearchButton) {
      onSearchButton()
      e.stopPropagation()
    }
    if (isOpen) {
      onClose()
    }
  }

  const handleKeyDown = e => {
    // Don't hijack the tab key:
    const tabKeyCode = 9
    if (e.keyCode === tabKeyCode) {
      return
    }

    if (e.keyCode === KeyEvent.DOM_VK_UP || e.keyCode === KeyEvent.DOM_VK_DOWN) {
      navigateList(e)
      e.preventDefault()
    }
    if (e.keyCode === KeyEvent.DOM_VK_RETURN || e.keyCode === KeyEvent.DOM_VK_ENTER) {
      if (!isDesktop && value === '') {
        handleSearchButton(e)
      } else {
        handleSelect(e)
      }
      e.preventDefault()
    }
    if (e.keyCode === KeyEvent.DOM_VK_ESCAPE) {
      onClose()
      e.preventDefault()
    }
    if (e.keyCode === KeyEvent.DOM_VK_BACK_SPACE && value === '') {
      onRemoveLocation(locations[locations.length - 1])
    }
  }

  // Tags.
  const tagsRef = useRef(null)
  const moreRef = useRef(null)

  // TODO: Use ViewWidth for split resize...
  const [tagDisplayLimit, setTagDisplayLimit] = useState(tagDisplayLimitDefault)

  const extraTagCount = locations.length - tagDisplayLimit
  const tagDisplayList = !isOpen ? locations.slice(0, tagDisplayLimit) : locations
  const isCurrent = locations.some(location => location.isCurrent)

  useEffect(() => {
    if (isDesktop) {
      if (moreRef.current) {
        const moreOffsetTop = moreRef.current.offsetTop
        const topDist = 18
        if (moreOffsetTop > topDist) {
          // Getting Smaller - check its disappearing...
          if (tagDisplayLimit === 3) {
            setTagDisplayLimit(tagDisplayLimit - 1)
          }
          if (tagDisplayLimit === 2) {
            setTagDisplayLimit(tagDisplayLimit - 1)
          }
        }
      }
    }
  }, [locations, tagDisplayLimit, isDesktop])

  return (
    <SearchAutoCompleteSc isListingsModal={isListingsModal}>
      <SearchBarSc
        isListingsModal={isListingsModal}
        isHero={isHero}
        isMap={isMap}
        isInline={isInline}
      >
        <SearchBoxSc
          isOpen={isOpen}
          isListingsModal={isListingsModal}
          isHero={isHero}
          onClick={handleBarClick}
          aria-label="Location search box"
        >
          {showIcon && (
            <SearchIconSc isHero={isHero}>
              <Icon icon="search" isSmall wrapper={false} />
            </SearchIconSc>
          )}
          <SearchTagListSc
            ref={tagsRef}
            isOpen={isOpen}
            isHero={isHero}
            isListingsModal={isListingsModal}
          >
            {placeholder && (
              <SearchTag
                key={placeholder}
                label={placeholder}
                onRemove={showRemove && onRemoveLocation}
              />
            )}
            {!placeholder &&
              tagDisplayList.map(location => (
                <SearchTag
                  key={location.id}
                  label={
                    location.name.includes(location?.zipCode?.name)
                      ? location.name
                      : `${location.name}, ${location.stateCode} ${location.postcode}`
                  }
                  location={location}
                  onRemove={showRemove && onRemoveLocation}
                />
              ))}
            {!placeholder && !isOpen && locations.length === 0 && (
              <SearchPlaceholderSc>Suburb or postcode</SearchPlaceholderSc>
            )}
            {!isOpen && extraTagCount > 0 && (
              <SearchMoreSc
                ref={moreRef}
                isHero={isHero}
                isListingsModal={isListingsModal}
              >{`& ${extraTagCount} more`}</SearchMoreSc>
            )}
            {locations.length < locationsLimit && (
              <SearchFormSc action="" isListingsModal={isListingsModal}>
                <SearchInputSc
                  ref={inputRef}
                  type="search"
                  name="search"
                  autoComplete="no"
                  value={value}
                  aria-label="Search for suburb or postcode"
                  isListingsModal={isListingsModal}
                  isOpen={isOpen}
                  onChange={handleSearch}
                  onKeyDown={handleKeyDown}
                />
              </SearchFormSc>
            )}
          </SearchTagListSc>
          {showOptions && (
            <SearchOptionsSc>
              <SearchClearSc
                type="button"
                title="Clear all locations"
                aria-label="Clear all locations"
                isHero={isHero}
                onClick={handleClear}
              >
                <Icon icon="close" wrapper={false} />
              </SearchClearSc>
              <SearchButtonSc
                type="button"
                showButtonText={showButtonText}
                isHero={isHero}
                onClick={handleSearchButton}
              >
                {showButtonIcon && <Icon icon="search" isTiny wrapper={false} />}
                {showButtonText && 'Search'}
              </SearchButtonSc>
            </SearchOptionsSc>
          )}
        </SearchBoxSc>
        {moreOptionsSearch && moreOptionsSearch}
        {isListingsModal && (
          <>
            <SearchOptionsSeperatorSc />
            <SearchMoreOptionsButtonSc>
              <SearchButtonSc type="button" aria-label="Search" onClick={handleSearchButton}>
                <Icon icon="search" isSmall wrapper={false} />
              </SearchButtonSc>
            </SearchMoreOptionsButtonSc>
          </>
        )}
        {showClearLarge && (
          <>
            <SearchOptionsSeperatorSc />
            <SearchMoreOptionsSearchSc>{moreOptionsSearch}</SearchMoreOptionsSearchSc>
          </>
        )}
      </SearchBarSc>
      {isOpen && (
        <SearchResultsSc>
          <SearchMoreOptionsResultsSc>
            <SearchAutoCompleteOptions
              context={context}
              isCurrent={isCurrent}
              showSurrounding={showSurrounding}
              isSurroundSearch={isSurroundSearch}
              onCurrentLocation={onCurrentLocation}
              currentLocationMessage={currentLocationMessage}
              onToggleSurrounding={onToggleSurrounding}
              moreOptionsResults={moreOptionsResults}
            />
          </SearchMoreOptionsResultsSc>
          <ResultList
            items={results}
            fields={fields}
            selectedIndex={selectedIndex}
            value={value}
            isListingsModal={isListingsModal}
            onSetIndex={handleHover}
            onSelect={handleSelect}
            onRemoveRecent={onRemoveRecent}
          />
        </SearchResultsSc>
      )}
    </SearchAutoCompleteSc>
  )
}

export default withSearch(SearchAutoCompleteMulti)
