const fallback = (callback) => {
  return setTimeout(() => {
    const start = Date.now();
    callback({
      didTimeout: false,
      timeRemaining: () => {
        return Math.max(0, 50 - (Date.now() - start));
      }
    })
  }, 1);
};

const isSupported = (typeof requestIdleCallback !== 'undefined');

export default isSupported ? requestIdleCallback : fallback;
export const cancelIdleCallback = () => isSupported ? cancelIdleCallback : clearTimeout;
