import _pick from 'lodash/pick';
import _invert from 'lodash/invert';
import _values from 'lodash/values';

export enum AgentTrackingTypes {
  // ListingSearchCard = 1,
  // ListingSearchMapCard = 2,
  // ListingContactForm = 3, // used to track views of agent photos on listing details
  // ListingLeadForm = 4,
  // ListingSimilarPropsCard = 5,
  // AlertsCard = 6,
  // CollectionsCard = 7,
  // SearchCard = 8,
  // ProfileView = 9,
  // ProfileQuestionsView = 10,
  // ProfileSuburbsView = 11,
  // ProfileActivitiesView = 12,
  // EmailEnquiry = 13,
  // LeadEnquiry = 14,
  // LocationOverviewView = 15,
  // QuestionDetailView = 16,
  // LocationSearchView = 17,
  AgentPhotoView = 17,
  OfficeLogoView = 18,
  AgentEmailEnquiry = 19,
  AgentCallEnquiry = 20,
  AgentFollow = 21,
  AgentReview = 22,
};

export function getAgentTrackingTypeNameById(ids) {
  const invertedTypes = _invert(AgentTrackingTypes);
  return _values(_pick(invertedTypes, ids)).join(', ').replace(/_/g, ' ');
}

export default AgentTrackingTypes;
