import React, { ReactNode } from 'react';

interface Props {
  href?: string;
  title?: string;
  className?: string;
  nofollow?: boolean;
  children: ReactNode;
  onClick?: any;
}

const ExternalHyperlink = ({
  href = '',
  title = '',
  nofollow = true,
  children,
  onClick,
  ...otherProps
}: Props) => {
  const hyperlink = href && href.indexOf(':') > 0 ? href : `http://${href}`;
  const titleProps = title.length > 0 ? { title, 'aria-label': title } : null;
  const externalProps = {
    target: '_blank',
    rel: `${nofollow ? 'nofollow ' : ''}noopener`,
  };
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  return (
    <a href={hyperlink} onClick={handleClick} {...otherProps} {...titleProps} {...externalProps}>
      {children}
    </a>
  );
};

export default ExternalHyperlink;
