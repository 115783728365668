import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const NavAuthDropdownHeaderSc = styled.div`
  font-weight: ${theme.fontWeights.bold};
  font-size: 16px;
  line-height: 40px;
  color: ${theme.colors.grayDark};
`

export const NavAuthDropdownDividerSc = styled.div`
  margin: 16px 0;
`

export const MobileItemSc = styled.div`
  @media ${theme.breakpoints.desktop} {
    display: none;
  }
`
