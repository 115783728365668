export const officeListingsPrefix = 'real-estate-agency';

export const officeListingsModeSlugs = {
  buy: 'properties-for-sale',
  rent: 'properties-for-rent',
  sold: 'sold-properties',
};

// Validate URL (must have only one of the valid search modes in the canonical URL)
export const isValidOfficeListingsUrl = (search: string[] | undefined): boolean => {
  if (!search) return false;

  // Filter out pagination parameters
  const filteredSearch = search.filter((param) => !param.startsWith('page-'));

  return (
    filteredSearch.length === 1 &&
    Object.values(officeListingsModeSlugs).includes(filteredSearch[0])
  );
};

export const getQuestionDetailsUrl = (locationSlug) => `/${locationSlug}/questions`;

export const getAgentSearchUrl = (locationSlug) => `/real-estate-agents/${locationSlug}`;

export const routes = {
  alertDetails: '/alerts/[id]',
  alertEdit: '/alerts/[id]/edit',
  alerts: '/alerts',
  agentFinder: '/real-estate-agents',
  agentDetails: '/agent/[username]',
  agentsSearch: '/real-estate-agents/[slug]',
  propertyManagerSearch: '/property-managers/[slug]',
  collections: '/collections',
  collectionDetails: '/collections/[uri]/[collection]',
  listingDetails: '/homes/[uri]/[...id]',
  listingsSearch: '/[mode]/[location]/[[...facets]]',
  locationSearch: '/search/[uri]',
  locationDetails: '/[mode]',
  locationsIndex: '/find',
  locationsSearch: '/find/[locationType]/[locationUri]',
  newApartments: '/new-apartments/[...id]',
  officeListings: `/${officeListingsPrefix}/[officeUri]/[[...search]]`,
  officeSearch: '/real-estate-agencies/[slug]',
  questionDetails: '/[mode]/questions/[uri]',
  questionsSearch: '/[mode]/questions',
  questionsIndex: '/discussions',
  suburbProfile: '/suburb-profile/[slug]',
};

const routingConfig = {
  routes,
};

export default routingConfig;
