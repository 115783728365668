import React, { ReactNode } from 'react';
import { SkeletonMarginSc } from './skeleton-margin.style';

type Props = {
  children: ReactNode;
};

const SkeletonMargin = ({ children }: Props) => <SkeletonMarginSc>{children}</SkeletonMarginSc>;

export default SkeletonMargin;
