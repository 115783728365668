/* eslint-disable import/no-cycle */
'use client'
import React, { useState } from 'react'

import { PageTypes } from '~/lib/config/page-types'
import { AdTypes } from '~/lib/config/ad-types'
import { NavTypes } from '~lib/config/nav-types'

import { DesktopOnly, MobileOnly } from '~/components/responsive'
import Ad from '~/components/ad'
import ScreenOnly from '~/components/screen-only'
import Header from '~/components/header'
import Footer from '~/components/footer'
import LeftNav from '~/components/left-nav'

import withTracking, { WithTrackingProps } from './with-tracking'

import { PageTemplateSc, MainSc } from './page-template.style'

type Props = {
  active?: NavTypes
  pageType: PageTypes
  showHeader?: boolean
  showFooter?: boolean
  isFixed?: boolean
  isFixedDesktop?: boolean
  isFlex?: boolean
  paddingBottom?: boolean
  stickyAdMobile?: boolean
  stickyAdDesktop?: boolean
  showFooterBorder?: boolean
  children?: React.ReactNode
  onNavClick?: (...args: any[]) => any
} & WithTrackingProps

const PageTemplate = ({
  active = NavTypes.None,
  pageType,
  showHeader = true,
  showFooter = true,
  isFixed = false,
  isFixedDesktop = false,
  isFlex = false,
  paddingBottom = false,
  stickyAdMobile = false,
  stickyAdDesktop = false,
  showFooterBorder = true,
  children = null,
  onNavClick = null,
}: Props) => {
  const [showNav, setShowNav] = useState(false)
  const handleToggleNav = () => setShowNav(!showNav)

  // Horrible ad ABOVE the nav :(
  const showAd =
    !isFixedDesktop && [NavTypes.Locations, NavTypes.Questions, NavTypes.Blog].includes(active)

  return (
    <PageTemplateSc>
      {showAd && (
        <>
          <MobileOnly>
            <Ad type={AdTypes.BannerAboveHeader} width="100%" height="50px" format="fluid" />
          </MobileOnly>
          <DesktopOnly>
            <Ad type={AdTypes.BannerAboveHeader} width="100%" height="90px" format="fluid" />
          </DesktopOnly>
        </>
      )}
      {showHeader && (
        <Header
          active={active}
          isFixed={isFixed}
          isFixedDesktop={isFixedDesktop}
          pageType={pageType}
          onNavClick={onNavClick}
          onMenuToggle={handleToggleNav}
        />
      )}
      <MainSc role="main" isFlex={isFlex}>
        {children && children}
      </MainSc>
      {/* <LeftNav active={active} showNav={showNav} onSelectMode={onNavClick} /> */}
      {showFooter && (
        <ScreenOnly>
          <Footer
            paddingBottom={paddingBottom}
            stickyAdMobile={stickyAdMobile}
            stickyAdDesktop={stickyAdDesktop}
            showBorder={showFooterBorder}
          />
        </ScreenOnly>
      )}
    </PageTemplateSc>
  )
}

export default withTracking(PageTemplate)
