import { captureException } from '@sentry/remix'
import _find from 'lodash/find'
import _concat from 'lodash/concat'

import { availableFeatures, isFeatureEnabled } from '~/lib/providers/utils/features'
import type { PageTypes } from '~/lib/config/page-types'
import api from '~/lib/config/api-endpoints'
import cookies from '~/lib/utils/cookies'
import debug from '~/lib/utils/debug'

import { getListingTrackingTypeNameById } from '~/lib/config/listing-tracking-types'
import { getAgentTrackingTypeNameById } from '~/lib/config/agent-tracking-types'

const trackingUtils = {
  consoleMessage(message, data = null, isListings = true, isWarning = false) {
    if (
      (isListings && isFeatureEnabled(availableFeatures.listingTrackingLog.key)) ||
      (!isListings && isFeatureEnabled(availableFeatures.agentTrackingLog.key))
    ) {
      const getName = isListings ? getListingTrackingTypeNameById : getAgentTrackingTypeNameById
      const prefix = isListings ? 'Listings' : 'Agents'
      const types = data
        ? ` ${data.map(item => `${getName(item.type)}-${item.type}`)} (${data?.[0]?.pageType})`
        : ''
      const ids = data ? `: ${data.map(item => item.ids)}` : ''
      if (isWarning) {
        debug.warn(`[${prefix}] ${message}${types}${ids}`)
        return true
      }
      debug.info(`[${prefix}] ${message}${types}${ids}`)
      return true
    }
    return false
  },

  errorMessage(message) {
    debug.error(message)
  },

  async postRecords(recordsToPost, isListings = true) {
    if (!recordsToPost || recordsToPost.length < 1) return Promise.resolve('empty')

    // Trim out any records with no ids in them
    const validRecords = []
    recordsToPost.forEach(record => {
      if (record.ids && record.ids.length > 0) {
        validRecords.push(record)
      }
    })

    if (!validRecords || validRecords.length < 1) return Promise.resolve('empty')

    // Return success if we aren't posting to the backend
    if (!isFeatureEnabled(availableFeatures.tracking.key)) {
      return Promise.resolve()
    }

    const ajax = (await import('~/lib/utils/ajax')).default
    const method = cookies().getAccessToken() ? ajax.postJSONSecure : ajax.postJSON
    const endpoint = isListings ? api.listingTracking() : api.agentTracking()
    const records = isListings ? { listings: validRecords } : { agents: validRecords }

    return method(endpoint, records)
      .then(() => {})
      .catch(err => {
        if (isFeatureEnabled(availableFeatures.sentryTracking.key)) {
          captureException(err, {
            tags: {
              feature: isListings ? 'listingTracking' : 'agentTracking',
            },
            extra: {
              href: window?.location?.href,
              endpoint,
              records: JSON.stringify(records),
            },
          })
        }
        if (err.stack) {
          debug.error(err.stack)
        }
        return validRecords
      })
  },

  mergeNewRecord(batchedRecords, ids: any[], type: any, pageType: PageTypes) {
    const existingRecords = _find(batchedRecords, { type })
    if (existingRecords) {
      existingRecords.ids = _concat(existingRecords.ids, ids)
    } else {
      batchedRecords.push({ type, pageType, ids })
    }
    return batchedRecords
  },
}

export default trackingUtils
