import { styled } from '@linaria/react';

import Image from '../image';

export const LogoSc = styled.div`
  -webkit-touch-callout: none;
  user-select: none;
`;

export const LogoLinkSc = styled.a`
  display: block;
  -webkit-tap-highlight-color: transparent;
`;

export const LogoImageSc = styled.svg<{ width: number; height: number }>`
  display: block;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

export const LogoImageAltSc = styled<any>(Image)`
  display: block;
`;

export default { LogoSc, LogoLinkSc, LogoImageSc, LogoImageAltSc };
