'use client'
import React from 'react'

import { searchTypes } from '~/lib/config/listing-search-mode-types'
import { routes } from '~/lib/config/routing'
import { trackEvent, eventTypes, contentTypes } from '~/lib/utils/google-tag-manager'
import { getSearchUrl } from '~/lib/utils/listings-search-helper'

import BetterDecision from './components/better-decision'

import { BetterDecisionsSc } from './better-decisions.style'

const BetterDecisions = () => {
  const handleClick = (pageElementPosition: any) =>
    trackEvent({
      contentType: contentTypes.listingSearchLink,
      pageElementPosition,
      event: eventTypes.selectContent,
    })

  return (
    <BetterDecisionsSc>
      <BetterDecision
        icon="homely-property-for-sale-sign"
        iconAlt="Property for sale sign on Homely"
        title="Find homes for sale"
        description="With new homes added daily and 45+ search filters, Homely makes finding your next home to purchase simpler."
        linkText="View properties for sale"
        linkHref={routes.listingsSearch}
        linkTo={getSearchUrl(searchTypes.sale)}
        pageElementPosition="for_sale_sect"
        onClick={handleClick}
      />
      <BetterDecision
        icon="homely-property-for-rent-sign"
        iconAlt="Property for rent sign on Homely"
        title="Find rental properties"
        description="Homely is used by millions of Australian renters to find properties for lease and easily apply for them online."
        linkText="View properties for rent"
        linkHref={routes.listingsSearch}
        linkTo={getSearchUrl(searchTypes.rent)}
        pageElementPosition="for_rent_sect"
        onClick={handleClick}
      />
      <BetterDecision
        icon="homely-sold-property-sign"
        iconAlt="Sold property sign on Homely"
        title="Research sold properties"
        description="Wanting to find a sold property price or see what sold on the weekend?"
        linkText="View sold properties"
        linkHref={routes.listingsSearch}
        linkTo={getSearchUrl(searchTypes.sold)}
        pageElementPosition="sold_sect"
        onClick={handleClick}
      />
    </BetterDecisionsSc>
  )
}

export default BetterDecisions
