import React, { ReactNode } from 'react';
import highlighter from '~lib/transforms/highlighter';
import { getLocationName } from '~lib/utils/location-helper';
import Icon from '../../../icon';
import {
  ResultItemSc,
  ResultItemLinkButtonSc,
  ResultItemRemoveButtonSc,
} from './result-item.style';

const formatLabel = (item) => {
  if (!item) return item;
  if (item.label) {
    return item.label;
  }
  return getLocationName(item);
};

type Props = {
  item: object;
  identifier: object;
  itemIndex: number;
  active: boolean;
  isRecent: boolean;
  isListingsModal: boolean;
  value?: string;
  icon?: string;
  children?: ReactNode;
  onSetIndex?: (...args: any[]) => any;
  onSelect?: (...args: any[]) => any;
  onRemove?: (...args: any[]) => any;
};

const SearchAutoCompleteResultItem = ({
  item,
  identifier,
  itemIndex,
  active,
  isRecent,
  isListingsModal,
  value = '',
  icon = 'search',
  children = null,
  onSetIndex = null,
  onSelect = null,
  onRemove = null,
}: Props) => {
  const handleSetIndex = () => {
    onSetIndex && onSetIndex(itemIndex);
  };
  const handleRemoveItem = (e) => {
    e.preventDefault();
    if (onRemove) {
      onRemove(identifier);
    }
  };
  return (
    <ResultItemSc
      role="option"
      aria-selected={active}
      onMouseOverCapture={handleSetIndex}
      onTouchEnd={handleSetIndex}
    >
      <ResultItemLinkButtonSc
        type="button"
        role="link"
        tabIndex={active ? 0 : -1}
        active={active}
        isListingsModal={isListingsModal}
        onClick={onSelect}
      >
        {icon && <Icon icon={icon} isSmall />}
        {highlighter(formatLabel(item), value)}
        {children}
      </ResultItemLinkButtonSc>
      {isRecent && (
        <ResultItemRemoveButtonSc
          type="button"
          tabIndex={active ? 0 : -1}
          aria-label="Remove"
          title="Remove previous search"
          isListingsModal={isListingsModal}
          onClick={handleRemoveItem}
        >
          <Icon icon="close" />
        </ResultItemRemoveButtonSc>
      )}
    </ResultItemSc>
  );
};

export default SearchAutoCompleteResultItem;
