import { types, searchTypes } from '~/lib/config/listing-search-mode-types'

const categories = [
  { value: 'for-sale', label: 'Buy', mode: types.Buy, searchType: searchTypes.sale },
  { value: 'for-rent', label: 'Rent', mode: types.Rent, searchType: searchTypes.rent },
  { value: 'sold', label: 'Sold', mode: types.Sold, searchType: searchTypes.sold },
  { value: 'agents', label: 'Agents', mode: types.Agents },
  { value: 'reviews', label: 'Reviews', mode: types.Reviews },
  { value: 'questions', label: 'Questions', mode: types.Questions },
]

export default categories
