import React from 'react';
import { CircledTextSc } from './circled-text.style';

type Props = {
  isPill?: boolean;
  isNotification?: boolean;
  children?: React.ReactNode;
};

const CircledText = ({ isPill = false, isNotification = false, children = null }: Props) => (
  <CircledTextSc isPill={isPill} isNotification={isNotification}>
    {children}
  </CircledTextSc>
);

export default CircledText;
