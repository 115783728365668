import React from 'react'
import SearchFilterButton from '~/components/search-filter-button'

type Props = {
  category: any
  isActive: boolean
  onClick: (...args: any[]) => any
}

const SearchOptionButton = ({ category, isActive, onClick }: Props) => {
  const handleClick = () => onClick(category)
  return (
    <SearchFilterButton isActive={isActive} onClick={handleClick}>
      {category.label}
    </SearchFilterButton>
  )
}

export default SearchOptionButton
