import React from 'react';
import ResultHeader from '../result-header';
import ResultItem from '../result-item';
import { ResultListSc } from './result-list.style';

type Props = {
  items?: object;
  fields?: string[];
  value?: string;
  selectedIndex?: number;
  isListingsModal?: boolean;
  showLocationsHeader?: boolean;
  onSetIndex?: (...args: any[]) => any;
  onSelect?: (...args: any[]) => any;
  onRemoveRecent?: (...args: any[]) => any;
};

const SearchAutoCompleteResultList = ({
  items = null,
  fields = null,
  value = null,
  selectedIndex = 0,
  isListingsModal = false,
  showLocationsHeader = false,
  onSetIndex = null,
  onSelect = null,
  onRemoveRecent = null,
}: Props) => {
  let itemIndex = -1;

  const totalCount =
    fields?.reduce((total, field) => total + (items?.[field] || []).length, 0) || 0;
  if (totalCount === 0) {
    return null;
  }

  const results = fields.map((field) => {
    const isRecent = field === 'recent';
    const isLocations = field === 'locations';
    const fieldItems = items[field] || [];

    if (!fieldItems || !fieldItems.length) {
      return [];
    }

    const groupItems = fieldItems.map((item) => {
      itemIndex += 1;
      const id = item.uri ? { uri: item.uri } : { id: item.id };
      return (
        <ResultItem
          key={`${itemIndex}-${item.uri || item.id}`}
          item={item}
          identifier={id}
          itemIndex={itemIndex}
          value={value}
          active={itemIndex === selectedIndex}
          isRecent={isRecent}
          isListingsModal={isListingsModal}
          onSetIndex={onSetIndex}
          onSelect={onSelect}
          onRemove={onRemoveRecent}
        />
      );
    });
    if (!isLocations || showLocationsHeader) {
      groupItems.unshift(
        <ResultHeader key={field} field={field} isListingsModal={isListingsModal} />
      );
    }
    return groupItems;
  });
  return <ResultListSc role="listbox">{results}</ResultListSc>;
};

export default SearchAutoCompleteResultList;
