import { styled } from '@linaria/react'

export const FeatureToggleItemSc = styled.div`
  padding: 0 10px;
`

export const FeatureToggleItemLabelSc = styled.span`
  flex: 1;
`

export default {
  FeatureToggleItemSc,
  FeatureToggleItemLabelSc,
}
