import { styled } from '@linaria/react'
import theme from '~/styles/theme'

import { LogoLinkSc } from '~/components/logo/logo.style'

export const HeaderSc = styled.header<{ isFixed: boolean; isFixedDesktop: boolean }>`
  display: flex;
  height: ${theme.sizes.headerHeight}px;
  color: ${theme.colors.grayBase};
  background-color: ${theme.colors.whiteBase};
  border-top: 1px solid ${theme.colors.border2};
  border-bottom: 1px solid ${theme.colors.border2};

  /* Fixed */
  position: ${({ isFixed }) => (isFixed ? 'fixed' : 'relative')};
  top: ${({ isFixed }) => (isFixed ? '0' : 'auto')};
  left: ${({ isFixed }) => (isFixed ? '0' : 'auto')};
  right: ${({ isFixed }) => (isFixed ? '0' : 'auto')};
  z-index: ${({ isFixed }) => (isFixed ? '10' : 'inherit')};

  /* Fixed - desktop only */
  @media ${theme.breakpoints.desktop} {
    position: ${({ isFixed, isFixedDesktop }) => (isFixed || isFixedDesktop ? 'fixed' : 'relative')};
    top: ${({ isFixed, isFixedDesktop }) => (isFixed || isFixedDesktop ? '0' : 'auto')};
    left: ${({ isFixed, isFixedDesktop }) => (isFixed || isFixedDesktop ? '0' : 'auto')};
    right: ${({ isFixed, isFixedDesktop }) => (isFixed || isFixedDesktop ? '0' : 'auto')};
    z-index: ${({ isFixed, isFixedDesktop }) => (isFixed || isFixedDesktop ? '10' : 'inherit')};
  }
`

export const LogoContainerSc = styled.div`
  padding: 10.5px 0 0;

  ${LogoLinkSc} {
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 48px;
    border-radius: 8px;
  }

  @media ${theme.breakpoints.desktop} {
    padding: 10.5px 8px 11.5px 16px;

    ${LogoLinkSc} {
      padding: 0 16px;
    }
  }
`
