import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const SearchOptionsSc = styled.div`
  padding: 12px 20px;
  border-bottom: 1px solid ${theme.colors.border2};
  white-space: nowrap;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;

  @media ${theme.breakpoints.desktop} {
    padding: 32px;
  }

  & > button {
    margin-right: 8px;
  }
`
