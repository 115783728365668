import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const FooterLinkListSc = styled.div`
  display: inline-block;
  margin: 0 0 32px;

  width: 50%;
  vertical-align: top;

  @media ${theme.breakpoints.small} {
    width: 33.33333%;
  }
  @media ${theme.breakpoints.desktop} {
    width: 20%;
  }
`

export const FooterLinkListTitleSc = styled.h3`
  margin: 0 0 16px;
  padding: 0 0 16px;
  font-weight: ${theme.fontWeights.medium};
  font-size: 17px;
  line-height: 29px;
  color: ${theme.colors.headerText};
  border-bottom: 1px solid ${theme.colors.border2};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const FooterLinkListLinkSc = styled.a<{ isHidden: boolean }>`
  display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  padding: 6px 6px 6px 0;
  width: 100%;
  font-size: 14px;
  line-height: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${theme.colors.headerText};
  :hover {
    color: ${theme.colors.redBase};
  }
`

export const FooterLinkListMoreSc = styled.button`
  margin: 0;
  padding: 6px 6px 6px 0;
  min-width: 48px;
  min-height: 44px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: ${theme.colors.headerText};
  background-color: transparent;
  border: 0;
  :hover {
    color: ${theme.colors.redBase};
  }
`
