import { canUseDOM } from '~/lib/utils/can-use-dom'
import { getAbsoluteUrl } from '~/lib/utils/listing-helpers'

type SharePropsType = {
  url?: string
  title: string
  text?: string
  tweet?: string
}

export const getShareProps = (listing, props?: SharePropsType) => {
  if (!listing && !props.title) {
    return null
  }
  const shareProps: SharePropsType = { ...props }
  if (listing) {
    const { listingType, address, propertyType, features, priceDetails } = listing
    const { bedrooms, bathrooms, cars } = features
    const formatValue = (value, label) => `${value && value > 0 ? `${value} ${label}, ` : ''}`
    const bed = formatValue(bedrooms, 'bed')
    const bath = formatValue(bathrooms, 'bath')
    const car = formatValue(cars, 'car')

    shareProps.url = getAbsoluteUrl(listing)
    shareProps.title = `Check out this ${address.suburb} ${
      propertyType || 'property'
    } for ${listingType}`
    shareProps.text = `${address.shortAddress}, ${bed}${bath}${car}${priceDetails?.longDescription}. See more beautiful homes, read local suburb reviews, review agents and much more on homely.com.au`
    shareProps.tweet = `Check out this ${bed}${bath}${car}${address.suburb} ${
      propertyType || 'property'
    } I found on Homely`
  } else if (canUseDOM) {
    // If we don't have a URL or a listing then use the current location.href
    const { url, title } = shareProps
    shareProps.url = url || window.location.href
    shareProps.title = title || document.title
  }
  return shareProps
}

export default { getShareProps }
