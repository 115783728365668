import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const SearchTabButtonSc = styled.button<{ isActive: boolean }>`
  display: inline-block;
  flex-shrink: 0;
  margin: 0 4px 8px;
  padding: 0 18px;
  font-weight: ${({ isActive }) => (isActive ? theme.fontWeights.bold : theme.fontWeights.regular)};
  font-size: 16px;
  line-height: 44px;
  white-space: nowrap;
  color: ${theme.colors.headerText};
  background-color: transparent;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;

  /* Line */
  &:after {
    content: '';
    display: block;
    height: 4px;
    background: ${theme.colors.redBase};
    border-radius: 2px;
    margin-left: -16px;
    margin-right: -16px;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  }

  /* Hover */
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      font-weight: ${theme.fontWeights.bold};
      &:after {
        opacity: 1;
      }
    }
  }
`

export const SearchTabButtonLabelSc = styled.span`
  display: block;
  /* Prevent size jumping on hover. */
  &:after {
    display: block;
    content: attr(title);
    font-weight: ${theme.fontWeights.bold};
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`
