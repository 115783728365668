import { styled } from '@linaria/react';

export const AppStoreBadgeLinkSc = styled.a`
  display: inline-flex;
`;

export const AppStoreBadgeImageSc = styled.img`
  user-select: none;
`;

export const AppStoreBadgeFirstImageSc = styled(AppStoreBadgeImageSc)`
  margin-right: 16px;
`;
