// Colors.
const colors = {
  grayBase: "#343D42", // Master Grey
  grayLighter2: "#F8F8F8",
  grayLight2: "#E0E3E5", // Light Grey 2
  headerText: "#101010", // $color-grayDark
  grayLight: "#F7F7F7",
  grayMedium: "#53627c",
  grayDark: "#101010", // $color-grayDark
  bodyText: "#343D42", // grayBase
  bodyTextLight: "#475A6B",
  whiteBase: "#fff",
  redBase: "#ff0086", // Master Pink
  blueBase: "#0c2c61", // Master Blue
  blueDark: "#00173B", // new blue
  purpleBase: "#0c2c61", // #682BE0
  line: "#b7c1d2",
  button: {
    redBaseHover: "#eb007b", // 8% darker
    hoverRed: "#fff7f9",
  },
  border2: "#E9ECF1",
  homelyConnectDark: "#1B223F",
  grayPlaceholder: "#95A4A7",
  blueLight: "#DFE8EE",
  error: "#cb4240",
  notification: "#FF002B",
  inputFocus: "#adc4d3",
  status: {
    enquired: "#717684", // same as "viewed" for now but John's green is '#2DC997',
    viewed: "#717684",
  },
  avatar: {
    color1: "#C503F6", // light purple
    color2: "#8000E8", // dark purple
    color3: "#2900FF", // blue
    color4: "#006FFF", // light blue
    color5: "#02BDE7", // cyan
    color6: "#00BB46", // green
    color7: "#FFB400", // yellow
    color8: "#F47800", // orange
    color9: "#DC009C", // pink
    color10: "#103672", // dark blue
  },
}

const gradients = {
  purpleToPink: "linear-gradient(270deg, #f07293 0, #eb2CD4 50%, #863ce0 100%)",
  purpleToPinkHover: "linear-gradient(90deg, #7e36c9 0, #d321c1 48.92%, #d86885 100%)",
  purpleToPinkAngle: "linear-gradient(225deg, #f07293 0, #eb2cd4 50%, #863ce0 100%)",
}

const fontFamily = {
  objektiv: "var(--font-objektiv)",
}

const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 700,
  xbold: 900,
}

// Spacing.
const spacing = [0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 80, 96, 112, 128]

// Breakpoints.
const size = {
  small: "641px",
  desktop: "961px",
  desktopMedium: "1024px",
  desktopLarge: "1240px",
}

const breakpoints = {
  small: `(min-width: ${size.small})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopMedium: `(min-width: ${size.desktopMedium})`,
  desktopLarge: `(min-width: ${size.desktopLarge})`,
}

const borderRadius = {
  roundedSm: "4px",
  roundedMd: "8px",
  roundedLg: "12px",
  rounded: "22px",
}

// Sizes.
const sizes = {
  headerHeight: 72,
  footerHeightDesktop: 100,
  footerHeightMobile: 208,
}

// Misc.
const shadows = {
  shadowSm: "1px 1px 4px 0 rgba(0, 0, 0, 0.1)",
  card: "0 6px 12px 0 rgba(0, 0, 0, 0.15)",
  modal: "0 6px 16px 0 rgba(0,0,0,0.15)",
  listingCard: "0 3px 6px 0 rgba(0, 0, 0, 0.1)",
  tooltip: "0 3px 10px rgba(17, 17, 17, 0.15), 0 3px 10px rgba(17, 17, 17, 0.2)",
}

// z-index.
// const zindex = [0, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000];

const theme = {
  colors,
  borderRadius,
  gradients,
  fontWeights,
  fontFamily,
  // fontSizes,
  // lineHeights,
  spacing,
  breakpoints,
  sizes,
  shadows,
  // zindex,
}

export default theme

export type Theme = typeof theme
