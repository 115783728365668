import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const DiscoverDescriptionSc = styled.p`
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 28px;
  color: ${theme.colors.bodyText};

  @media ${theme.breakpoints.desktop} {
    width: 60%;
    font-size: 17px;
    line-height: 29px;
  }
`
