import { styled } from '@linaria/react'
import theme from '~/styles/theme'

import { wideBreakpoint } from '../../utils/helper'

export const HomeImageHolderSc = styled.div`
  width: 256px;
  height: 248px;
  background: ${theme.colors.border2};

  @media ${theme.breakpoints.desktop} {
    width: 356px;
    height: 345px;
  }

  /* Too wide - scale */
  @media (min-width: ${wideBreakpoint}) {
    position: relative;
    padding-bottom: 96.91%;
    width: 100%;
    height: 0;
    overflow: hidden;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      transform: translate(-50%, -50%);
    }
  }
`
