
import { get } from '~/lib/config/app-config'
import { FeatureToggleEnvSc } from './environment-variables.style'

const FeatureToggleEnvironmentVariables = () => {
  const config = get()
  // const env =
  //   ENV.MODE ? `env: ${ENV.MODE}` : 'env: unknown'
  const api = `api: ${config.apiUrl}`
  const bffUrl = `bff: ${config.bffUrl}`
  const release = `release: v${config.appVersion}`
  return (
    <FeatureToggleEnvSc>
      {/* <div>{env}</div> */}
      <div>{api}</div>
      <div>{bffUrl}</div>
      <div>{release}</div>
    </FeatureToggleEnvSc>
  )
}

export default FeatureToggleEnvironmentVariables
