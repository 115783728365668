import React from 'react'
import { useAlertsState } from '~/lib/providers/alerts-context'
import UnreadCountBadge from '~/components/unread-count-badge'
import NavLink from '../nav-link'
import { NavAlertsLinkLabelSc } from './nav-alerts-link.style'

type Props = {
  isHeader?: boolean
  isActive?: boolean
  onClick: (...args: any[]) => any
}

const NavAlertsLink = ({ isHeader = false, isActive = false, onClick = null }: Props) => {
  const { unreadCount } = useAlertsState()
  return (
    <NavLink
      href="/alerts"
      title="My Alerts"
      isHeader={isHeader}
      isActive={isActive}
      onClick={onClick}
    >
      <NavAlertsLinkLabelSc>My Alerts</NavAlertsLinkLabelSc>
      <UnreadCountBadge count={unreadCount} />
    </NavLink>
  )
}

export default NavAlertsLink
