import SkeletonCard from '../skeleton-card'
import SkeletonLine from '../skeleton-line'
import SkeletonMargin from '../skeleton-margin'
import {
  ListingCardEmptySc,
  ListingCardEmptyImageSc,
  ListingCardEmptyContentSc,
} from './listing-card-empty.style'

type Props = {
  showLabels?: boolean
  isSearch?: boolean
}

const ListingCardEmpty = ({ showLabels = true, isSearch = false }: Props) => (
  <SkeletonCard>
    <ListingCardEmptySc isSearch={isSearch}>
      <ListingCardEmptyImageSc isSearch={isSearch} />
      <ListingCardEmptyContentSc>
        <SkeletonMargin>
          <SkeletonLine width50 />
        </SkeletonMargin>
        <SkeletonMargin>
          <SkeletonLine />
        </SkeletonMargin>
        <SkeletonMargin>
          <SkeletonLine width50 />
        </SkeletonMargin>
        {showLabels && (
          <SkeletonMargin>
            <SkeletonLine />
          </SkeletonMargin>
        )}
      </ListingCardEmptyContentSc>
    </ListingCardEmptySc>
  </SkeletonCard>
)

export default ListingCardEmpty
