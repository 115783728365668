import { useEffect, useRef } from 'react';

// Used to hold a previous value for comparison in useEffect
// (handy when looking to prevent duplicate operations)
// Based on https://stackoverflow.com/a/53446665/130811
export const usePrevious = (value) => {
  const ref = useRef();

  // Update value with the latest input
  useEffect(() => {
    ref.current = value;
  });

  // Return current "previous" value (runs before useEffect)
  return ref.current;
};

export default usePrevious;
