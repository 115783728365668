/* eslint-disable react/no-array-index-key */
import React from 'react';

function escapeRegExp(str) {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&');
}

function highlighter(text, highlight) {
  if (!highlight) {
    return text;
  }
  const parts = text.split(new RegExp(`(${escapeRegExp(highlight)})`, 'i'));
  return (
    <span>
      {parts.map((part, i) => (
        <span
          key={`${part}${i}`}
          className={part.toLowerCase() === highlight.toLowerCase() ? 'highlight' : null}
        >
          {part}
        </span>
      ))}
    </span>
  );
}

export default highlighter;
