import { types as listingSearchTypes } from '~/lib/config/listing-search-mode-types'

export const getResultType = (isRecent: boolean, isCurrent: boolean, isPrev = false): string => {
  if (isPrev) return 'prev'
  if (isCurrent) return 'geo'
  return isRecent ? 'recent' : 'new'
}

const getSearchType = type => {
  if (type === listingSearchTypes.Agents) return 'agents'
  if (type === listingSearchTypes.Reviews) return 'reviews'
  if (type === listingSearchTypes.Questions) return 'questions'
  if (type === listingSearchTypes.Sold) return 'sold'
  return type === listingSearchTypes.Buy ? 'for_sale' : 'for_rent'
}

// Format GTM ExtraData.
// eg. 'for_sale_doncaster_vic_3108_mapped_area'
export const getExtraData = (mode, locations, context = null, isSurroundSearch = true) => {
  const searchType = getSearchType(mode)
  const location =
    locations?.length > 1
      ? `multi_${locations.length}_${locations.map(({ id }) => id).join('_')}`
      : locations?.[0]?.id
  const mappedArea = context === 'map' ? '_mapped_area' : ''
  return `${searchType}_${location}${mappedArea}${isSurroundSearch ? '' : '_no_surround'}`
}

export default {
  getResultType,
  getExtraData,
}
