import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const GradientTextSc = styled.span<{ isInlineBlock: boolean }>`
  font-weight: ${theme.fontWeights.bold};
  background: ${theme.colors.redBase};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* iOS has issues with text wrapping so sometimes requires use of inline-block */
  display: ${({ isInlineBlock }) => (isInlineBlock ? 'inline-block' : 'inherit')};
`
