import { styled } from '@linaria/react'
import theme from '~/styles/theme'

import baseButtonStyle from '../utils/base-button-style'

const getPadding = (short, large, tall, shortest, isGallery, isDesktop) => {
  if (isGallery && isDesktop) return '5px 24px'
  if (isGallery) return '2px 16px'
  if (shortest) return '0 20px'
  if (short) return '2px 24px'
  if (large) return '6px 40px'
  if (tall) return '10px 24px'
  return '6px 24px'
}

const getSize = (small, smallest, isGallery, isDesktop) => {
  if (isGallery && isDesktop) return 14
  if (isGallery) return 13
  if (smallest) return 13
  if (small) return 14
  return 16
}

const getBorderRadius = (theme, roundedSm, tall) => {
  if (roundedSm) return theme.borderRadius.roundedSm
  if (tall) return '26px'
  return theme.borderRadius.rounded
}

const getColor = (isGradient, isBlue) => {
  if (isGradient) return theme.gradients.purpleToPink
  if (isBlue) return theme.colors.blueBase
  return theme.colors.redBase
}

const getColorHover = (isGradient, isBlue) => {
  if (isGradient) return theme.gradients.purpleToPinkHover
  if (isBlue) return theme.colors.blueDark
  return theme.colors.button.redBaseHover
}

const SolidButtonSc = styled.button<{
  short: boolean
  large: boolean
  tall: boolean
  shortest: boolean
  small: boolean
  smallest: boolean
  roundedSm: boolean
  isGradient: boolean
  isBlue: boolean
  fullWidth: boolean
  bold: boolean
  isGallery: boolean
}>`
  ${baseButtonStyle}
  display: inline-block;
  padding: ${({ short, large, tall, shortest, isGallery }) => getPadding(short, large, tall, shortest, isGallery, false)};
  width: ${({ fullWidth }) => (fullWidth ? ' 100%' : 'auto')};
  font-weight: ${({ bold }) => (bold ? theme.fontWeights.bold : theme.fontWeights.medium)};
  font-size: ${({ small, smallest, isGallery }) => getSize(small, smallest, isGallery, false)}px;
  line-height: 32px;
  border-radius: ${({ roundedSm, tall }) => getBorderRadius(theme, roundedSm, tall)};
  text-align: center;

  /* Primary */
  color: ${theme.colors.whiteBase};
  background-color: ${({ isGradient, isBlue }) => getColor(isGradient, isBlue)};

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${({ isGradient, isBlue }) => getColorHover(isGradient, isBlue)};
    }
  }

  /* Disabled */
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  opacity: ${({ disabled }) => (disabled ? '0.5' : 'inherit')};

  @media ${theme.breakpoints.desktop} {
    padding: ${({ short, large, tall, shortest, isGallery }) => getPadding(short, large, tall, shortest, isGallery, true)};
    font-size: ${({ small, smallest, isGallery }) => getSize(small, smallest, isGallery, true)}px;
  }
`

export default SolidButtonSc
