import qs from 'qs'
import { searchTypes } from '~/lib/config/listing-search-mode-types'

import { ListingListingType, ListingStatusType, Slugs } from '~/graphql/generated/graphql'
import statusTypes from '~/lib/config/listing-status-types'
import { defaultLocationObject } from './location-helper'

export const defaultPropertyType = '/real-estate'

let facetLevel = null

export const getFacetLevel = (force = false) => {
  facetLevel = force || 2
  return facetLevel
}

const isSold = searchType => [searchTypes.sold, 'sold', 3].includes(searchType)

const getSearchType = (searchType = searchTypes.sale) => {
  if (searchType === searchTypes.sale) return searchTypes.saleFaceted
  if (searchType === searchTypes.rent) return searchTypes.rentFaceted
  if (isSold(searchType)) return searchTypes.sold
  return searchType
}

const getSearchTypeByListing = (listingType, statusType) => {
  let searchType = listingType === 'sale' ? searchTypes.saleFaceted : searchTypes.rentFaceted
  if (searchType === searchTypes.saleFaceted && statusType === statusTypes.Completed) {
    searchType = searchTypes.sold
  }
  return getSearchType(searchType)
}

export const getSearchUrl = (
  searchType = searchTypes.sale,
  locations?,
  isSurroundSearch = true,
) => {
  const primarySlug = locations?.[0]?.slug || defaultLocationObject.slug
  const query = {} as any
  if (locations?.length > 1) {
    query.suburbs = locations
      .slice(1)
      .map(({ slug }) => slug)
      .join(',')
  }
  if (!isSurroundSearch) {
    query.surrounding = false
  }
  const propertyType = !isSold(searchType) ? '/real-estate' : ''
  const querystring =
    Object.keys(query).length > 0 ? `?${qs.stringify(query, { encode: false })}` : ''
  return `/${getSearchType(searchType)}/${primarySlug}${propertyType}${querystring}`
}

export const getSearchUrlByListing = ({
  listingType,
  statusType,
  slugs,
}: {
  listingType: ListingListingType
  statusType: ListingStatusType
  slugs?: Slugs
}) =>
  `/${getSearchTypeByListing(listingType, statusType)}/${
    slugs?.suburbSlug || defaultLocationObject.slug
  }${statusType !== statusTypes.Completed ? '/real-estate' : ''}`

export default getFacetLevel
