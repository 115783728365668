import React, { useRef, useEffect } from 'react';
import { SearchTabButtonSc, SearchTabButtonLabelSc } from './search-tab-button.style';

type Props = {
  category: any;
  isActive: boolean;
  onClick: (...args: any[]) => any;
  onFocus: (...args: any[]) => any;
  onBlur: (...args: any[]) => any;
};

const SearchTabButton = ({ category, isActive, onClick, onFocus, onBlur }: Props) => {
  const ref = useRef(null);

  useEffect(() => {
    if (isActive) {
      ref.current.focus();
    }
  }, [isActive]);

  const handleClick = () => onClick(category);

  return (
    <SearchTabButtonSc
      ref={ref}
      type="button"
      role="tab"
      aria-selected={isActive}
      tabIndex={isActive ? 0 : -1}
      isActive={isActive}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={handleClick}
    >
      <SearchTabButtonLabelSc title={category.label}>{category.label}</SearchTabButtonLabelSc>
    </SearchTabButtonSc>
  );
};

export default SearchTabButton;
