import React from 'react';
import { SpinnerSc, SpinnerLabelSc, SpinnerCircleSc } from './spinner.style';

type Props = {
  color?: string; // 'red' | 'gray' | 'white'
  size?: number;
  text?: string;
  isInline?: boolean;
  isFlex?: boolean;
};

const Spinner = ({ color = 'red', size = 30, text = '', isInline = false, isFlex = false }: Props) => (
  <SpinnerSc isInline={isInline} isFlex={isFlex}>
    <SpinnerCircleSc width="200" height="200" viewBox="0 0 200 200" color={color} size={size}>
      <path d="M100,0 C135.73,0 168.74,19.06 186.6,50 C204.46,80.94 204.46,119.06 186.6,150 C180.77,160.1 173.22,169.1 164.28,176.6 L155.28,165.88 C178.82,146.13 190.03,115.33 184.69,85.07 C179.35,54.81 158.28,29.7 129.41,19.19 C119.98,15.76 110.03,14 100,14 L100,0 Z" />
    </SpinnerCircleSc>
    {text.length > 0 && (
      <SpinnerLabelSc color={color} size={size}>
        {text}
      </SpinnerLabelSc>
    )}
  </SpinnerSc>
);

export default Spinner;
