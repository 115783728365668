import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const NavAuthLinkSc = styled.div<{ isAlt: boolean }>`
  padding: 16px 14px;
  color: ${({ isAlt }) => (!isAlt ? theme.colors.grayDark : theme.colors.whiteBase)};

  /* Same as NavLink */

  @media ${theme.breakpoints.desktop} {
    padding: 16px 6px;
  }

  @media (min-width: 1060px) {
    padding: 16px 8px;
  }

  @media (min-width: 1200px) {
    padding: 16px 12px;
  }

  @media (min-width: 1300px) {
    padding: 16px;
  }
`

export const NavAuthLinkButtonSc = styled.button`
  position: relative;
  display: flex;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 20px;
  background: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${theme.colors.redBase};
    }
  }
`

export const NavAuthDropdownSc = styled.span<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
`

export const BadgeHolderSc = styled.div`
  position: absolute;
  top: -7px;
  right: -7px;

  @media ${theme.breakpoints.desktop} {
    display: none;
  }
`
