import debug from './debug';

export const imageDomain = '//img-4.homely.com.au';
export const imagePathPrefix = '/image/upload';

const CloudinaryOptionDefaults = {
  f: 'auto',
  q: 'auto',
  c: 'fill',
  dpr: '2.0',
};

export const buildCloudinaryOptions = (opts) =>
  Object.keys(opts)
    .map((key) => `${key}_${opts[key]}`)
    .join(',');

export const getImageSrc = (
  identifier = 'default',
  version = null,
  opts: any = {},
  domain = imageDomain,
  pathPrefix = imagePathPrefix
) => {
  const options = { ...CloudinaryOptionDefaults, ...(opts.cloudinary || {}) };

  // Non-integer w and h options cause broken images.
  // So always parseInt these keys when they exist.
  // Components should pass valid Cloudinary options to begin with, so this is a fallback.
  ['w', 'h'].forEach((key) => {
    if (options[key] && options[key] % 1 !== 0) {
      debug.warn(`Non-integer value ${options[key]} provided for Cloudinary option key "${key}".`);
      options[key] = parseInt(options[key], 10);
    }
  });

  const optionsString = buildCloudinaryOptions(options);
  const extension = opts.extension || 'jpg';
  const uri =
    `${domain}${pathPrefix}${optionsString ? `/${optionsString}` : ''}` +
    `${version ? `/v${version}` : ''}` +
    `/${identifier}.${extension}`;

  return uri;
};

export default getImageSrc;
