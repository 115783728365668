export const stateList = {
  'Australian Capital Territory': { code: 'ACT', tz: 'Australia/Canberra' },
  'New South Wales': { code: 'NSW', tz: 'Australia/Sydney' },
  'Northern Territory': { code: 'NT', tz: 'Australia/Darwin' },
  Queensland: { code: 'QLD', tz: 'Australia/Brisbane' },
  'South Australia': { code: 'SA', tz: 'Australia/Adelaide' },
  Tasmania: { code: 'TAS', tz: 'Australia/Hobart' },
  Victoria: { code: 'VIC', tz: 'Australia/Melbourne' },
  'Western Australia': { code: 'WA', tz: 'Australia/Perth' },
};

export const getState = (name) => stateList?.[name] || stateList.Victoria;

export const getCode = (name) => {
  const state = stateList?.[name];
  return state ? state.code : state;
};

export const getStatesForFormSelect = () => {
  return Object.keys(stateList).map((key, ndx) => {
    return {
      id: ndx + 1,
      label: key,
    };
  });
};

export const getStateByFormSelectId = (id) => stateList[Object.keys(stateList)[id - 1]];

export default {
  stateList,
  getState,
  getCode,
};
