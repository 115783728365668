'use client'
import React from 'react'

import { trackEvent, eventTypes } from '~/lib/utils/google-tag-manager'

import Icon from '~/components/icon'

import { DiscoverLinkHolderSc, DiscoverLinkSc } from './discover-link.style'

type Props = {
  href: string
  contentType: string
  children: React.ReactNode
}

const DiscoverLink = ({ href, contentType, children }: Props) => {
  const handleClick = () =>
    trackEvent({
      contentType,
      pageElementPosition: 'neighbourhoods_sect',
      event: eventTypes.selectContent,
    })

  return (
    <DiscoverLinkHolderSc>
      <DiscoverLinkSc href={href} onClick={handleClick}>
        <span>{children}</span>
        <Icon icon="forward-popup" />
      </DiscoverLinkSc>
    </DiscoverLinkHolderSc>
  )
}

export default DiscoverLink
