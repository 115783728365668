import { styled } from '@linaria/react'

export const FormInputHolderSc = styled.div`
  position: relative;
`

export const FormInputLabelSc = styled.span``

export const FormInputSc = styled.input<any>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 15px 16px;
  width: 100%;
  min-height: 55px;
  -webkit-appearance: none;
  font-size: 14px;
  line-height: 23px;
  text-align: left;
  color: ${({ theme }) => theme.colors.bodyText};
  background: ${({ theme }) => theme.colors.whiteBase};
  border: 1px solid ${({ theme }) => theme.colors.grayLight2};
  border-radius: 12px;

  /* NOTE: iOS will zoom if below 16px */
  @media only screen and (max-device-width: 600px) {
    font-size: 16px;
  }

  &:-moz-placeholder {
    color: ${({ theme }) => theme.colors.bodyText};
  }
  &::-moz-placeholder {
    color: ${({ theme }) => theme.colors.bodyText};
  }
  &:-ms-input-placeholder {
    color: ${({ theme }) => theme.colors.bodyText};
  }
  &::-webkit-input-placeholder {
    color: ${({ theme }) => theme.colors.bodyText};
  }

  &[data-noresize] {
    resize: none;
  }

  /* Placeholder */
  & + ${FormInputLabelSc} {
    position: absolute;
    top: 0;
    left: 12px;
    display: inline-block;
    padding: 0 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.grayDark};
    background: ${({ theme }) => theme.colors.whiteBase};
    pointer-events: none;
    -webkit-touch-callout: none;
    user-select: none;
    z-index: 1;
    transform: translate(0, 16px);
    transition: transform 0.2s;
  }

  /* Focus */
  &:focus {
    z-index: 1;
    outline: 0;
    border-color: ${({ theme }) => theme.colors.inputFocus};
    -webkit-box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.inputFocus};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.inputFocus};
  }

  ${({ inFocus, theme }) =>
    inFocus &&
    `
    z-index: 1;
    outline: 0;
    border-color: ${theme.colors.inputFocus};
    -webkit-box-shadow: 0 0 0 1px ${theme.colors.inputFocus};
    box-shadow: 0 0 0 1px ${theme.colors.inputFocus};
  `}

  /* Move above on focus */
  &:focus + ${FormInputLabelSc} {
    transform: translate(4px, -12px);
  }

  /* Move above when populated */
  ${({ hasValue }) =>
    hasValue &&
    `
    & + ${FormInputLabelSc} {
      transform: translate(4px, -12px);
    }
  `}

  /* Error */
  ${({ hasError, theme }) =>
    hasError &&
    `
    border-color: ${theme.colors.error} !important;

    &:focus {
      box-shadow: 0 0 0 1px ${theme.colors.error} !important;
    }

    & + ${FormInputLabelSc} {
      color: ${theme.colors.error};
    }
  `}

  /* Textarea */
  ${({ as }) =>
    as === 'textarea' &&
    `
    min-height: 80px;
  `}

  /* Div */
  ${({ as }) =>
    as === 'div' &&
    `
    cursor: pointer;
    -webkit-touch-callout: none;
    user-select: none;

    // Icon
    padding-right: 50px;
  `}

  /* Div - focus == dropdown */
  ${({ as, inFocus }) =>
    as === 'div' &&
    inFocus &&
    `
    border-radius: 12px 12px 0 0;
  `}
`

export const FormInputInlineErrorSc = styled.div`
  display: flex;
  margin: 8px 0 0;
  font-size: 14px;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.error};
`

export default {
  FormInputHolderSc,
  FormInputSc,
  FormInputLabelSc,
  FormInputInlineErrorSc,
}
