import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const ColumnSc = styled.div`
  position: relative;
  padding: 0 20px;

  @media ${theme.breakpoints.desktop} {
    margin: 0 auto;
    max-width: 1200px;
  }

  @media (min-width: 1240px) {
    padding: 0;
  }
`
