import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const BetterDecisionSc = styled.div`
  flex: 1;

  &:not(:last-child) {
    margin: 0 0 56px 0;
  }

  @media ${theme.breakpoints.desktop} {
    &:not(:last-child) {
      margin: 0 67px 0 0;
    }
  }
`

export const BetterDecisionIconSc = styled.img`
  margin: 0 0 20px;
  user-select: none;
`

export const BetterDecisionTitleSc = styled.h3`
  font-weight: ${theme.fontWeights.bold};
  font-size: 24px;
  line-height: 40px;
  color: ${theme.colors.headerText};

  margin: 0 0 16px;
  @media ${theme.breakpoints.desktop} {
    margin: 0 0 20px;
  }
`

export const BetterDecisionDescriptionSc = styled.p`
  margin: 0 0 20px;
  padding: 0;
  font-size: 16px;
  line-height: 28px;
  color: ${theme.colors.bodyText};

  @media ${theme.breakpoints.desktop} {
    min-height: 87px;
    font-size: 17px;
    line-height: 28px;
  }
`
