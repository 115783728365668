import type { GtmEvent } from '~/lib/utils/google-tag-manager'
import type { PageTypes } from '~/lib/config/page-types'

import ScrollList from '~/components/scroll-list'
import ListEmpty from '~/modules/listing-detail/components/list-empty'
import HomeListingsSkeleton from '~/modules/home/components/home-listings-skeleton'

// import ListingCard from '../listing-card'

import { ListingsScrollListSc, ListingCardSc } from './listings-scroll-list.style'

const gtmOptions: GtmEvent = { pageElementPosition: 'listings_carousel' }

type Props = {
  listings?: any[]
  loading?: boolean
  emptyMessage?: string
  showBoost?: boolean
  showLabels?: boolean
  pageType: PageTypes
}

const ListingsScrollList = ({
  listings = [],
  loading = false,
  emptyMessage = 'No properties found',
  showBoost = false,
  showLabels = true,
  pageType,
}: Props) => (
  <ListingsScrollListSc>
    {!loading && listings.length > 0 && (
      <ScrollList>
        {listings.map(listing => (
          <ListingCardSc key={listing.id}>
            {listing.id}
            {/* <ListingCard
              listing={listing}
              showBoost={showBoost}
              showSchema={false}
              showLabels={showLabels}
              isCarousel
              single
              gtmOptions={gtmOptions}
              pageType={pageType}
            /> */}
          </ListingCardSc>
        ))}
      </ScrollList>
    )}
    {!loading && listings.length === 0 && (
      <ListEmpty image="1">
        <p>{emptyMessage}</p>
      </ListEmpty>
    )}
    {loading && <HomeListingsSkeleton />}
  </ListingsScrollListSc>
)

export default ListingsScrollList
