import { styled } from '@linaria/react';

export const SkeletonMarginSc = styled.div`
  margin-top: 6px;

  &:first-child {
    margin-top: 0;
  }
`;

export default {
  SkeletonMarginSc,
};
