import { styled } from '@linaria/react'
import theme from '~/styles/theme'

const getColor = (theme, text) => {
  if (!text || text.length === 0) return theme.colors.grayLight2
  const colors = Object.values(theme.colors.avatar)
  const charCode = text[0]?.charCodeAt(0) - 65
  return colors[charCode % colors.length]
}

const getSize = width => {
  if (width > 100) return 26
  if (width > 80) return 24
  if (width > 70) return 22
  if (width > 60) return 20
  if (width > 50) return 18
  if (width > 40) return 16
  if (width > 35) return 14
  if (width > 30) return 12
  if (width > 20) return 11
  return 14
}

export const DefaultAvatarSc = styled.span<{ isOutline: boolean; text: string; $width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ $width }) => $width}px;
  height: ${({ $width }) => $width}px;

  font-weight: ${theme.fontWeights.bold};
  font-size: ${({ $width }) => getSize($width)}px;

  color: ${theme.colors.whiteBase};
  background-color: ${({ text }) => getColor(theme, text)};

  border-radius: 50%;

  /* Outline */
  border: ${({ isOutline }) => (isOutline ? `1px solid ${theme.colors.whiteBase}` : 'none')};
`
