import _invert from 'lodash/invert';
import _pick from 'lodash/pick';
import _values from 'lodash/values';

export enum ListingTrackingTypes {
  DetailView = 1,
  // SimilarPropsCard = 2,
  // SearchCard = 3,
  // SearchMapCard = 4,
  // CollectionsCard = 5,
  // AlertsCard = 6,
  // AlertEmailSent = 7,
  GalleryFullScreenView = 8,
  EmailEnquiry = 9,
  AgentProfileClick = 10,
  AuctionSave = 11,
  InspectionSave = 12,
  AddToCollection = 13,
  // EmailShare = 14,
  // SocialShare = 15,
  Print = 16,
  PhotoView = 17,
  FloorPlanView = 18,
  VideoView = 19,
  // ReadMoreClick = 20,
  MapInteraction = 21,
  StreetViewInteraction = 22,
  // AgentProfileCard = 23,
  // AgentProfileMapCard = 24,
  PhoneEnquiry = 25,
  StatementOfInfo = 26,
  CardView = 27,
  SimilarPropsCardView = 28,
  VirtualTourView = 29,
  // AlertPushReceived = 30,
  SmsEnquiry = 31,
  SnugClick = 32,
  SharedListing = 33,
  DescReadMoreClick = 34,
  CopyAddress = 35,
  Rating = 36,
  Comment = 37,
  ExternalLinkClick = 42,
};

export function getListingTrackingTypeNameById(ids) {
  const invertedTypes = _invert(ListingTrackingTypes);
  return _values(_pick(invertedTypes, ids)).join(', ').replace(/_/g, ' ');
}

export default ListingTrackingTypes;
