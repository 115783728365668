import React from 'react'

import { PageTypes } from '~/lib/config/page-types'

import NavAuthMenu from '../nav-auth-menu'
import NavAlertsLink from '../nav-alerts-link'
import NavLink from '../nav-link'
import GradientText from '../../../gradient-text'
import GradientLine from '../../../gradient-line'
import NavDropdown from '../nav-dropdown'
import NavDropdownList from '../nav-dropdown-list'

import { NavLinkSc } from '../nav-link/nav-link.style'
import {
  NavAuthDropdownHeaderSc,
  NavAuthDropdownDividerSc,
  MobileItemSc,
} from './nav-auth-dropdown.style'

type Props = {
  user: any
  active: string
  isOpen: boolean
  pageType: PageTypes
  onNavClick: (...args: any[]) => any
  onAuthClick: (...args: any[]) => any
  onHide: (...args: any[]) => any
}

const NavAuthDropdown = ({
  user = null,
  active = '',
  isOpen = false,
  pageType,
  onAuthClick = null,
  onNavClick = null,
  onHide = null,
}: Props) => (
  <NavDropdown isOpen={isOpen} isRight onHide={onHide}>
    {user && <NavAuthDropdownHeaderSc>{user.username}</NavAuthDropdownHeaderSc>}
    <NavDropdownList>
      {!user && (
        <li>
          <NavLinkSc
            as="button"
            type="button"
            title="Log in"
            aria-label="Log in"
            onClick={onAuthClick}
          >
            <GradientText>LOG IN</GradientText>
          </NavLinkSc>
        </li>
      )}
      <MobileItemSc>
        <NavAlertsLink isActive={active === 'alerts'} onClick={onNavClick} />
      </MobileItemSc>
      <MobileItemSc>
        <NavLink
          href="/collections"
          title="Collections"
          isActive={active === 'collections'}
          onClick={onNavClick}
        >
          Collections
        </NavLink>
      </MobileItemSc>
    </NavDropdownList>
    {user && (
      <>
        <NavAuthDropdownDividerSc>
          <GradientLine />
        </NavAuthDropdownDividerSc>
        <NavAuthMenu user={user} pageType={pageType} onSignOut={onHide} />
      </>
    )}
  </NavDropdown>
)

export default NavAuthDropdown
