import { types as listingSearchTypes } from '~/lib/config/listing-search-mode-types'
import { formatAddress } from './location-helper'

const hostPath = 'https://www.homely.com.au'

const bedroomsMax = 4

export const getLandArea = areaSqm => (areaSqm ? `${areaSqm}m²` : null)

export const getUrl = listing => {
  return `/homes/${listing.uri}/${listing.id}`
}

export const getAbsoluteUrl = listing => {
  return `${hostPath}${getUrl(listing)}`
}

export const listingHasDetails = listing => {
  return listing && listing.uri && listing.uri.length && listing.uri !== 'unknown'
}

export const getShortAddress = (listing, useDefaultForUnknown = false) => {
  if (!listing) {
    return ''
  }
  const { location } = listing
  if (!location) {
    return ''
  }
  return formatAddress(location, useDefaultForUnknown)
}

export const getBedroomsRange = min => {
  const range = []
  if (!min || min <= 1) {
    return range
  }
  if (min >= bedroomsMax) {
    return [bedroomsMax]
  }
  for (let bed = min; bed <= bedroomsMax; bed += 1) {
    range.push(bed)
  }
  return range
}

export const isRental = listing => {
  return (
    listing &&
    listing.info &&
    listing.info.listingClass &&
    listing.info.listingClass === listingSearchTypes.Rent
  )
}

export const getEventSchemaData = (listing, date, type) => {
  return {
    location: listing.location,
    type,
    startDate: date.startsOn,
    endDate: date.endsOn || null,
    url: getAbsoluteUrl(listing),
    image: listing && listing.images && listing.images.length > 0 ? listing.images[0] : null,
  }
}
