import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const SearchTabsSc = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: left;
  align-items: center;
  margin: -2px 0 18px;
  padding: 2px 0 0;
  width: 100%;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;

  @media ${theme.breakpoints.desktop} {
    justify-content: center;
  }
`
