import React from 'react'
import ToggleWithLabel from '~/components/toggle-with-label'
import { FeatureToggleItemSc, FeatureToggleItemLabelSc } from './feature-toggle-item.style'

type Props = {
  name: string
  label?: string
  isChecked?: boolean
  onClick?: (...args: any[]) => any
}

const FeatureToggleItem = ({ name = '', label = '', isChecked = false, onClick = null }: Props) => {
  const handleClick = () => {
    if (onClick) onClick(name)
  }
  return (
    <FeatureToggleItemSc>
      <ToggleWithLabel checked={isChecked} rightAlign justified onChange={handleClick}>
        <FeatureToggleItemLabelSc>{label}</FeatureToggleItemLabelSc>
      </ToggleWithLabel>
    </FeatureToggleItemSc>
  )
}

export default FeatureToggleItem
