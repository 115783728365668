import _invert from 'lodash/invert';

const types = {
  Email: 1,
  Mobile: 2,
  Landline: 3,
  Fax: 4,
  Website: 5,
};

export function getDescriptionForType(type) {
  return _invert(types)[type];
}

export default types;
