import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const FooterPartnersBgSc = styled.div`
  background: ${theme.colors.grayLight};
`

export const FooterPartnersSc = styled.div`
  display: flex;
  flex-wrap: wrap;

  flex-direction: column;

  gap: 16px;

  @media (min-width: 641px) {
    gap: 48px;
    flex-direction: row;
  }
`

export const ColumnSc = styled.div`
  display: flex;
  margin: 32px auto 0;
  padding: 20px;
  max-width: 1200px;
`

export const FooterTitleSc = styled.div`
  font-weight: ${theme.fontWeights.bold};
  text-transform: uppercase;
  font-size: 12px;
  color: ${theme.colors.grayDark};
`

export const PartnersSc = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;

  @media (min-width: 401px) {
    gap: 16px 24px;
  }

  @media ${theme.breakpoints.desktop} {
    gap: 48px;
  }
`
