import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const SkeletonCardSc = styled.div<{ isAlt: boolean }>`
  background-color: ${({ isAlt }) => (!isAlt ? theme.colors.grayLighter2 : theme.colors.whiteBase)};
  border-radius: ${theme.spacing[3]}px;
`

export default {
  SkeletonCardSc,
}
