import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const NavRightSc = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    position: relative;
  }

  @media ${theme.breakpoints.desktop} {
    margin: 0 12px 0 0;
  }
`

export const NavCollapseItemSc = styled.li<{ isLoggedOut: boolean }>`
  display: none;

  /* Responsive breakpoints to hide Collection/Alerts on smaller screens.
  /* Logged out */
  @media (min-width: 1150px) {
    display: ${({ isLoggedOut }) => (isLoggedOut ? 'block' : 'none')};
  }

  /* Logged in */
  @media (min-width: 1080px) {
    display: ${({ isLoggedOut }) => (!isLoggedOut ? 'block' : 'none')};
  }
`

export const NavSignupItemSc = styled.li`
  @media (max-width: 370px) {
    display: none;
  }
`

export const NavAlertsLinkLabelSc = styled.span`
  &:not(:last-child) {
    margin-right: 6px;
  }
`
