import { NavTypes } from '~/lib/config/nav-types'
import { PageTypes } from '~/lib/config/page-types'
import { AdTypes } from '~/lib/config/ad-types'

import PageTemplate from '~/components/page-template'
import TitleBreak from '~/components/title-break'
import Ad from '~/components/ad'

import BetterDecisions from './components/better-decisions'
import Column from './components/column'
import Discover from './components/discover'
import FooterLinks from './components/footer-links-old'
import Header from './components/header'
import HeaderLine from './components/header-line'
import HomeApp from './components/home-app'
import HomeHero from './components/home-hero'
import HomeImages from './components/home-images'
import Section from './components/section'
import Title from './components/title'

import { DiscoverBadgeHolderSc, DiscoverBadgeSc } from './home.style'

import HomeListingsSection from './sections/home-listings'
// import HomeNewsSection from './sections/home-news'
// import AgentsHomeSection from './sections/home-agents'
// import { generateJsonLd } from '@/lib/app/generate-json-ld'

const pageType = PageTypes.Home

const HomePage = () => (
  <PageTemplate pageType={pageType} active={NavTypes.Home} showFooterBorder={false}>
    {/* {generateJsonLd()} */}
    <HomeHero />
    <HomeListingsSection pageType={pageType} />
    <Section>
      <Column>
        <Ad type={AdTypes.HomeAdAfterLatestProperties} />
      </Column>
    </Section>
    <Section>
      <Column>
        <Header>
          <HeaderLine />
          <Title>
            Homely helps you make <TitleBreak inlineMobile>better property decisions</TitleBreak>
          </Title>
        </Header>
        <BetterDecisions />
      </Column>
    </Section>
    <Section>
      <HomeImages />
    </Section>
    <Section>
      <Column>
        <Ad type={AdTypes.HomeAdBeforeDiscoverAndLearn} />
      </Column>
    </Section>
    <Section>
      <Column>
        <Header isShort>
          <HeaderLine />
          <Title>
            {'Discover and learn '}
            <TitleBreak inlineMobile>
              {'about neighbourhoods '}
              <DiscoverBadgeHolderSc>
                <DiscoverBadgeSc>Only on Homely</DiscoverBadgeSc>
              </DiscoverBadgeHolderSc>
            </TitleBreak>
          </Title>
        </Header>
        <Discover />
      </Column>
    </Section>
    <Section>
      <HomeImages isReviews />
    </Section>
    {/* <AgentsHomeSection /> */}
    {/* <HomeNewsSection /> */}
    <Section>
      <HomeApp />
    </Section>
    <Section>
      <Column>
        <Ad type={AdTypes.HomeAdBottom} />
      </Column>
    </Section>
    <FooterLinks />
  </PageTemplate>
)

export default HomePage
