import { styled } from '@linaria/react'

export const ResultItemSc = styled.li<any>`
  position: relative;

  /* Current Location */
  ${({ isCurrent, theme }) =>
    isCurrent &&
    `
    &:not(:first-of-type) {
      border-top: 1px solid ${theme.colors.border2};
    }
  `}
`

interface ResultItemLinkButtonScProps {
  isHero?: boolean
  isListings?: boolean
  isListingsModal?: boolean
  isAgent?: boolean
  isCurrent?: boolean
  active?: boolean
}

export const ResultItemLinkButtonSc = styled.button<ResultItemLinkButtonScProps>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 72px 0 32px;
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
  color: ${({ theme }) => theme.colors.grayBase};
  background: transparent;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  .highlight {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.redBase};
  }

  & > .icon-wrapper {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: -1px;
    margin-right: 8px;
  }

  /* Listings Search - Modal */
  ${({ isListingsModal }) =>
    isListingsModal &&
    `
    padding: 0 20px;
  `}

  /* Active */
  ${({ active, theme }) =>
    active &&
    `
    font-weight: 500;
    color: ${theme.colors.redBase};
    background-color: ${theme.colors.grayLight};

    .icon {
      color: ${theme.colors.redBase};
    }
  `}
`

export const ResultItemRemoveButtonSc = styled.button<any>`
  position: absolute;
  top: 10px;
  right: 18px;
  margin: 0;
  padding: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayBase};
  background: transparent;
  border-radius: 20px;
  border: 0;
  cursor: pointer;

  .icon-wrapper {
    color: ${({ theme }) => theme.colors.grayBase};
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #eaeae9;
    }
  }

  /* Listings Search - Modal */
  ${({ isListingsModal }) =>
    isListingsModal &&
    `
    right: 2px;
  `}
`

export default {
  ResultItemSc,
  ResultItemLinkButtonSc,
  ResultItemRemoveButtonSc,
}
