'use client'
import React from 'react'
import { trackEvent, eventTypes, contentTypes } from '~/lib/utils/google-tag-manager'
import { getCdnPath } from '../../lib/utils/cdn-image'
import {
  AppStoreBadgeLinkSc,
  AppStoreBadgeFirstImageSc,
  AppStoreBadgeImageSc,
} from './mobile-app-badges.style'

type Props = {
  pageElementPosition: string
  extraData?: string
  isLarger?: boolean
}

const MobileAppBadges = ({ pageElementPosition, extraData = null, isLarger = false }: Props) => {
  const handleClick = platform =>
    trackEvent({
      event: eventTypes.selectContent,
      contentType: contentTypes.appStoreBtn,
      pageElementPosition,
      extraData: extraData ? `${extraData}_${platform}` : platform,
    })

  const handleIOSClick = () => handleClick('ios')
  const handleAndroidClick = () => handleClick('android')
  return (
    <>
      <AppStoreBadgeLinkSc
        href="https://itunes.apple.com/au/app/homely.com.au-real-estate/id1004229463"
        title="Download Homely on the App Store"
        aria-label="Download on the App Store"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleIOSClick}
      >
        <AppStoreBadgeFirstImageSc
          src={getCdnPath(`/assets/images/home-app-store-badge-2.svg`)}
          alt="Download on the App Store"
          width={isLarger ? 134 : 119}
          height={isLarger ? 45 : 40}
          loading="lazy"
        />
      </AppStoreBadgeLinkSc>
      <AppStoreBadgeLinkSc
        href="https://play.google.com/store/apps/details?id=au.com.homely.android"
        title="Get Homely on Google Play"
        aria-label="Get it on Google Play"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleAndroidClick}
      >
        <AppStoreBadgeImageSc
          src={getCdnPath(`/assets/images/home-google-play-store-badge-2.svg`)}
          alt="Get it on Google Play"
          width={isLarger ? 152 : 135}
          height={isLarger ? 45 : 40}
          loading="lazy"
        />
      </AppStoreBadgeLinkSc>
    </>
  )
}

export default MobileAppBadges
