'use client';
import React, { useState } from 'react';

import {
  FooterLinkListSc,
  FooterLinkListTitleSc,
  FooterLinkListLinkSc,
  FooterLinkListMoreSc,
} from './footer-link-list.style';

const getUrl = (slug: any, uri: any, locationSlug: any, locationUri: any) =>
  slug ? slug.replace('locationSlug', locationSlug) : uri.replace('locationUri', locationUri);

type Location = {
  name: string;
  uri: string;
};

type Props = {
  locations: Location[];
  title: string;
  keywords: string;
  uri: string;
  slug?: string;
  size?: number;
};

const FooterLinkList = ({
  locations,
  title,
  keywords,
  uri,
  slug = '',
  size: initialSize = 5,
}: Props) => {
  const [size, setSize] = useState(initialSize);
  const handleMore = () => setSize(size === 0 ? initialSize : 0);
  return (
    <FooterLinkListSc>
      <FooterLinkListTitleSc title={title}>{title}</FooterLinkListTitleSc>
      <div>
        {(locations as any).map((location: any, index: any) => (
          <FooterLinkListLinkSc
            key={location.uri}
            href={getUrl(slug, uri, location.slug, location.uri)}
            isHidden={index >= size && size > 0}
          >
            {`${location.name} ${keywords}`}
          </FooterLinkListLinkSc>
        ))}
      </div>
      <FooterLinkListMoreSc type="button" onClick={handleMore}>
        {size > 0 ? 'More' : 'Less'}
      </FooterLinkListMoreSc>
    </FooterLinkListSc>
  );
};

export default FooterLinkList;
