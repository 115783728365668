import _cloneDeep from 'lodash/cloneDeep'
import _concat from 'lodash/concat'
import { canUseDOM } from '~/lib/utils/can-use-dom'
import localforage from 'localforage'

import type PageTypes from '~/lib/config/page-types'
import {
  type ListingTrackingTypes,
  getListingTrackingTypeNameById,
} from '~/lib/config/listing-tracking-types'

import utils from './tracking-utils'

const storage = localforage.createInstance({
  name: 'Homely-tracking',
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
})

/*
  To reduce the hits to localStorage and the API this module employs both a batching memory cache
  and localStorage cache. The memory cache is used to handle rapid record inserts with a short
  timeout (e.g. 1 second). The memory cache timeout is then utilized to populate the localStorage
  cache which has a much longer timeout (e.g. 10 seconds). The localStorage timeout then kicks off
  the POST to the API.

  The end result is rapid batching and bundling of records with fewer but larger hits to the API.
*/

const batchTimeout = 1000 // milliseconds
const cacheTimeout = 3000 // milliseconds
const maxRecords = 20 // API-set maximum record limit

const cacheName = 'listingsCache'

let failRetries = 0
let batchedRecords = []
let viewTimer = null
let cacheTimer = null

const getBatchedRecords = () => batchedRecords
const getViewTimer = () => viewTimer
const getCacheTimer = () => cacheTimer
const setViewTimer = value => {
  viewTimer = value
}
const setCacheTimer = value => {
  cacheTimer = value
}

const reset = () => {
  batchedRecords = []
}

const getCache = () => {
  return storage
    .getItem(cacheName)
    .then(value => value)
    .catch(() => null)
}

const setCache = data => {
  // In case of failure, reinsert records into memory cache
  return storage
    .setItem(cacheName, data)
    .then(() => {})
    .catch(() => batchedRecords.push(data))
}

export const clearCache = () => {
  storage.removeItem(cacheName)
}

const startBatchTimer = () => {
  viewTimer = window.setTimeout(() => {
    // eslint-disable-next-line no-use-before-define
    cacheRecords()
  }, batchTimeout)
}

const postRecords = recordsToPost => {
  return utils.postRecords(recordsToPost, true).then(res => {
    if (res) {
      if (res === 'empty') {
        utils.consoleMessage(`[Listings] Attempted to post empty records ${recordsToPost}`)
      } else {
        const failedIds = res.map(record => record.ids)
        if (failRetries < 3) {
          failRetries += 1
          utils.consoleMessage(`Failed ${failedIds}`, null, true)
          batchedRecords.push(res)
          startBatchTimer()
        } else {
          utils.errorMessage(`[Listings] Too many failed attempts: ${failedIds}`)
          failRetries = 0
        }
      }
    } else {
      failRetries = 0
      utils.consoleMessage('Posted', recordsToPost, true)
    }
  })
}

const flushCache = () => {
  return getCache().then(cache => {
    if (cache) {
      // Flush that cache!
      utils.consoleMessage('Flushing cache', cache, true)
      clearCache()
      return postRecords(cache)
    }
    return Promise.resolve()
  })
}

const startCacheTimer = () => {
  cacheTimer = window.setTimeout(() => {
    flushCache()
  }, cacheTimeout)
}

const clearCacheTimer = () => {
  if (cacheTimer) {
    window.clearTimeout(cacheTimer)
  }
}

const clearBatchTimer = () => {
  if (viewTimer) {
    window.clearTimeout(viewTimer)
  }
  clearCacheTimer()
  startCacheTimer()
}

const cacheRecords = () => {
  if (batchedRecords.length < 1) {
    return Promise.resolve()
  }

  // Reset cache flushing timer
  clearCacheTimer()

  // Prevents a race condition where objects added during POST were being removed after success
  const newRecords = _cloneDeep(batchedRecords)
  reset()

  // Flush our memory batch into the cache in localStorage
  return getCache().then(cache => {
    if (cache) {
      const currentRecords = _cloneDeep(cache) as any[]
      newRecords.forEach(newRecord => {
        let isFound = false
        // Update our cloned array with the new records from the batch cache
        for (let i = 0; i < currentRecords.length; i += 1) {
          const currentRecord = currentRecords[i]
          if (newRecord.type === currentRecord.type) {
            currentRecord.ids = _concat(currentRecord.ids, newRecord.ids)
            isFound = true
          }
        }
        // A new type not yet in the cache
        if (!isFound) {
          currentRecords.push(newRecord)
        }
      })
      const allRecords = currentRecords

      utils.consoleMessage('Updating cache', newRecords, true)
      startCacheTimer()
      return setCache(allRecords)
    }

    utils.consoleMessage('Adding cache', newRecords, true)
    startCacheTimer()
    return setCache(newRecords)
  })
}

export const trackListings = (ids: any[], type: ListingTrackingTypes, pageType: PageTypes) => {
  ids = ids.filter(Number)
  if (!canUseDOM || !ids || ids.length < 1) return
  clearBatchTimer()
  utils.consoleMessage(
    `Merging ${getListingTrackingTypeNameById(type)}-${type}: ${ids}`,
    null,
    true,
  )
  batchedRecords = utils.mergeNewRecord(batchedRecords, ids, type, pageType)

  if (batchedRecords.length >= maxRecords) {
    // Need to purge our records as we've hit the maximum
    utils.consoleMessage(
      `Maxed out memory cache at ${batchedRecords.length} of ${maxRecords}`,
      null,
      true,
      true,
    )
    cacheRecords()
  } else {
    startBatchTimer()
  }
}

export default {
  trackListings,
  // Tests
  reset,
  clearCache,
  cacheRecords,
  postRecords,
  getBatchedRecords,
  getViewTimer,
  getCacheTimer,
  setViewTimer,
  setCacheTimer,
  getCache,
  setCache,
  startCacheTimer,
  startBatchTimer,
}
