import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const PageTemplateSc = styled.div`
  background-color: ${theme.colors.whiteBase};
`

export const MainSc = styled.main<{ isFlex: boolean }>`
  display: ${({ isFlex }) => (!isFlex ? 'block' : 'flex')};

  min-height: calc(
    100vh - ${theme.sizes.headerHeight + theme.sizes.footerHeightMobile}px
  );
  @media ${theme.breakpoints.desktop} {
    min-height: calc(
      100vh - ${theme.sizes.headerHeight + theme.sizes.footerHeightDesktop}px
    );
  }
`
