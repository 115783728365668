import { styled } from '@linaria/react'

export const ToggleLabelSc = styled.label<{ isDisabled: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  color: ${({ isDisabled, theme }) => (isDisabled ? theme.colors.grayLight2 : 'inherit')};
`

export const ToggleWithLabelSc = styled.div<{ isDisabled: boolean; justified: boolean }>`
  .react-toggle {
    display: ${({ justified }) => (justified ? 'flex' : 'inline-block')};
    flex-direction: column;
    flex: 0;
    position: relative;
    cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
    background-color: transparent;
    border: 0;
    padding: 0;

    -webkit-touch-callout: none;
    user-select: none;

    -webkit-tap-highlight-color: transparent;
  }

  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .react-toggle--disabled {
    opacity: 0.5;
    transition: opacity 0.25s;
  }

  .react-toggle-track {
    width: 35px;
    height: 22px;
    padding: 0;
    border-radius: 22px;
    background-color: #bfbfbf;
    // TODO uncomment after we tackle perf card
    /* -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease; */
  }

  body.no-touch .react-toggle:hover .react-toggle-track {
    background-color: darken(#bfbfbf, 10%);
  }

  .react-toggle--checked .react-toggle-track {
    background-color: ${({ theme }) => theme.colors.blueDark};
  }

  .react-toggle-track-check,
  .react-toggle-track-x {
    display: none;
  }

  .react-toggle-thumb {
    // TODO uncomment after we tackle perf card
    /* transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms; */
    position: absolute;
    top: 1px;
    left: 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.whiteBase};
    box-sizing: border-box;
    /* transition: all 0.25s ease; */
  }

  .react-toggle--checked .react-toggle-thumb {
    left: 14px;
    border-color: ${({ theme }) => theme.colors.redBase};
  }

  // TODO uncomment after we tackle perf card
  /* .keynav-mode .react-toggle--focus .react-toggle-thumb,
  .react-toggle:active .react-toggle-thumb {
    box-shadow: 0px 0px 2px 3px rgba(253, 73, 116, 0.4);
  } */
`

export default {
  ToggleWithLabelSc,
  ToggleLabelSc,
}
