import React from 'react'
import CircledText from '../circled-text'

type Props = {
  count: number
  maxCount?: number
}

const UnreadCountBadge = ({ count = 0, maxCount = 50 }: Props) =>
  count > 0 ? (
    <CircledText isPill isNotification>
      {count > maxCount ? `${maxCount}+` : count}
    </CircledText>
  ) : null

export default UnreadCountBadge
