import { canUseDOM } from '~/lib/utils/can-use-dom'
import localforage from 'localforage'
import _get from 'lodash/get'
import _pick from 'lodash/pick'
import { getCode } from '~/lib/config/australian-states'
import { addCache, checkCache } from '~/lib/utils/cache-helper'

import apiEndpoints from '~/lib/config/api-endpoints'
import { isLocalStorageEnabled } from './browser-support'
import type { LatLong } from '~/types/listing-v1'

export const noAddressString = 'Address available on request'
export const noPriceString = 'Price Undisclosed'
export const defaultAddress = 'this property'

let cachedGeolocation = null

export const defaultLocationObject = {
  id: 5713470,
  name: 'Melbourne (CBD), VIC 3000',
  state: { name: 'Victoria' },
  zipCode: { name: '3000' },
  uri: 'melbourne-cbd-melbourne-melbourne-greater-victoria',
  slug: 'melbourne-vic-3000',
  suburb: 'Melbourne (CBD)',
}

export const setCachedGeolocation = location => {
  cachedGeolocation = location
}

export const formatShortState = state => {
  const stateAbbr = getCode(state)
  return stateAbbr && stateAbbr.length > 0 ? stateAbbr : state
}

export const formatLocationName = (streetAddress, state?, postcode?) => {
  if (streetAddress === noAddressString) {
    return streetAddress
  }
  const locationName =
    state && typeof state === 'string'
      ? `${streetAddress}, ${formatShortState(state)}`
      : streetAddress
  if (postcode) {
    return `${locationName} ${postcode}`
  }
  return locationName
}

export const formatAddress = (location, useDefaultForUnknown = false) => {
  if (useDefaultForUnknown && location.address === noAddressString) {
    return defaultAddress
  }
  return `${location.address}, ${location.suburb}, ${location.stateCode}`
}

export const getLocationName = loc => {
  return formatLocationName(loc.name, _get(loc, 'state.name'), _get(loc, 'zipCode.name'))
}

// from /listings-faceted/statics
export const getLocationName2 = (location?) => {
  if (!location || !location.name) {
    return 'Australia'
  }
  if (location.state) {
    return `${location.name}, ${getCode(location.state.name)} ${_get(location, 'zipCode.name')}`
  }
  return location.name
}

export const formatShortLocationName = location => {
  if (_get(location, 'address') === noAddressString) {
    return noAddressString
  }
  return `${_get(location, 'streetNumber')} ${_get(location, 'street.name')}, ${_get(
    location,
    'suburb.name',
  )}`
}

// returns a latLong model: { latitude, longitude }
export const getCurrentLocation = (requestDevicePermission = true): Promise<LatLong | null> => {
  if (
    typeof window !== 'undefined' &&
    requestDevicePermission &&
    'geolocation' in navigator &&
    'permissions' in navigator
  ) {
    // Attempt to retrieve location from the browser, if it fails use default
    return navigator.permissions.query({ name: 'geolocation' as PermissionName }).then(permission => {
      if (_get(permission, 'state') === 'granted') {
        return new Promise<LatLong | null>(resolve => {
          navigator.geolocation.getCurrentPosition(
            position => resolve(_pick(position.coords, ['latitude', 'longitude']) as LatLong),
            () => resolve(null)
          )
        })
      }
      return Promise.resolve(null)
    })
  }
  return Promise.resolve(null)
}

export const findLocationByLatLong = async ({ latitude, longitude }: LatLong) => {
  const url = apiEndpoints.getLocationByLatLong(latitude, longitude, 5)
  const ajax = (await import('~/lib/utils/ajax')).default
  return ajax.getJSON(url).then(res => res || null)
}


const getLocationFallback = (requestDevicePermission?: boolean) => {
  if (cachedGeolocation) {
    return findLocationByLatLong(cachedGeolocation)
  }
  return getCurrentLocation(requestDevicePermission).then(res => {
    if (res) {
      cachedGeolocation = res
      return findLocationByLatLong(res)
    }
    return Promise.resolve(null)
  })
}

export const getLocationByUri = async (
  locationUri = defaultLocationObject.uri,
  includeSurrounding = false,
) => {
  const ajax = (await import('~/lib/utils/ajax')).default
  return ajax
    .getJSON(apiEndpoints.getLocation(locationUri, includeSurrounding))
    .then(res => res)
    .catch(err => {
      if (err.stack) {
        return Promise.resolve(err)
      }
      return Promise.resolve()
    })
}

export const getLocationSpatials = async locations => {
  const cacheName = 'locationsSpacial'
  const ajax = (await import('~/lib/utils/ajax')).default
  return Promise.all(
    locations.map(location => {
      const { id } = location
      const cache = checkCache(cacheName, id)
      return cache
        ? Promise.resolve(cache)
        : ajax.getJSON(apiEndpoints.getBoundary(id)).then(spacial => {
            addCache(cacheName, id, spacial)
            return spacial
          })
    }),
  )
}

// Main method to retrieve a location.
// It will first attempt to load from localStorage.
// If unavailable it will fallback to querying the device which then falls back to
// default location.
export const getLocation = (requestDevicePermission = true, key = 'visited') => {
  if (canUseDOM && isLocalStorageEnabled) {
    return localforage
      .getItem(key)
      .then((locations: any) => {
        if (locations && locations.length > 0) {
          return locations[0]
        }
        return getLocationFallback(requestDevicePermission)
      })
      .catch(() => getLocationFallback(requestDevicePermission))
  }
  return getLocationFallback(requestDevicePermission)
}


export default {
  noAddressString,
  noPriceString,
  defaultAddress,
  setCachedGeolocation,
  getLocationName,
  getLocationName2,
  formatLocationName,
  formatShortState,
  formatAddress,
  getCurrentLocation,
  findLocationByLatLong,
  getLocationFallback,
  getLocationByUri,
  getLocation,
  defaultLocationObject,
}
