import _find from 'lodash/find'
import _take from 'lodash/take'
import _union from 'lodash/union'
import _uniqBy from 'lodash/uniqBy'
import _without from 'lodash/without'

function combineItems(oldValues, newValues, uniqueKey, maxCount) {
  const combinedValues = _union(newValues, oldValues)
  const updatedValues = _uniqBy(combinedValues, uniqueKey)

  // Only store the item limit
  return maxCount ? _take(updatedValues, maxCount) : updatedValues
}

function removeItemsBy(oldValues, value) {
  const itemToRemove = _find(oldValues, value)
  const updatedValues = _without(oldValues, itemToRemove)

  return updatedValues
}

const storageAdapter = {
  constructor(options = {}) {
    this.options = { ...options }
    return this
  },

  addOrUpdateCache(key, value, date = null) {
    return new Promise((resolve, reject) => {
      const { storageObject, uniqueKey, updateKey, recentItemLimit } = this.options
      let nextItems = null

      storageObject.getItem(key).then(
        previousItems => {
          if (previousItems) {
            nextItems = combineItems(previousItems, value, uniqueKey, recentItemLimit)
          } else {
            nextItems = value
          }
          storageObject.setItem(key, nextItems)

          if (date) {
            // Only store the last updated date if one doesn't exist to ensure data doesn't go stale
            storageObject.getItem(updateKey).then(existingItem => {
              if (!existingItem) {
                storageObject.setItem(updateKey, date)
              }
            })
          }
          resolve(nextItems)
        },
        err => {
          reject(err)
        },
      )
    })
  },

  retrieveOrFetchRecords(query, url, key, cacheable = true, isPost = false, data = null) {
    return new Promise((resolve, reject) => {
      const { storageObject, minSearchQueryLength } = this.options
      const cacheKey = `${this.options.cacheKeyPrefix}${query}`
      storageObject.getItem(cacheKey).then(
        values => {
          if (values) {
            resolve(values)
          } else if (query.length >= minSearchQueryLength) {
            this.fetchRecords(query, url, key, cacheable, isPost, data).then(fetchedItems => {
              if (fetchedItems) {
                resolve(fetchedItems)
              }
            })
          }
        },
        err => {
          reject(err)
        },
      )
    })
  },

  fetchRecords(query, url, keys, cacheable = true, isPost = false, data = null) {
    return new Promise(async (resolve, reject) => {
      const ajax = (await import('~/lib/utils/ajax')).default
      const request = isPost ? ajax.postJSON : ajax.getJSON
      const opts = isPost ? { query, ...data } : null
      request(url, opts)
        .then(res => {
          // If we got a response object then use the body (even if it's null)
          const items = res?.body !== undefined ? res.body : res
          if (cacheable && items) {
            const cacheKey = `${this.options.cacheKeyPrefix}${query}`
            this.addOrUpdateCache(cacheKey, items, new Date()).then(() => {
              resolve(items)
            })
          } else {
            resolve(items)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },

  invalidateCache(keysToKeep = []) {
    // Clear storage object if adapter config threshold is reached
    return new Promise((resolve, reject) => {
      const { storageObject, cacheInvalidationThreshold, updateKey } = this.options
      storageObject.getItem(updateKey).then(
        lastUpdatedAt => {
          if (!lastUpdatedAt) {
            return
          }
          const differenceInDays = ((new Date() as any) - lastUpdatedAt) / 1000 / 60 / 60 / 24
          if (differenceInDays > cacheInvalidationThreshold) {
            storageObject.iterate((value, key) => {
              if (keysToKeep.indexOf(key) < 0) {
                storageObject.removeItem(key)
              }
            })
          }
          resolve(true)
        },
        err => {
          reject(err)
        },
      )
    })
  },

  removeFromVisited(removeOptions) {
    return new Promise((resolve, reject) => {
      const { storageObject, visitedKey } = this.options
      let nextItems = null

      storageObject.getItem(visitedKey).then(
        visitedItems => {
          if (visitedItems) {
            nextItems = removeItemsBy(visitedItems, removeOptions)
            storageObject.setItem(visitedKey, nextItems)
            resolve(nextItems)
          }
        },
        err => {
          reject(err)
        },
      )
    })
  },

  getSearchHistory() {
    return new Promise((resolve, reject) => {
      const { storageObject, visitedKey, uniqueKey, visitedLimit } = this.options

      storageObject.getItem(visitedKey).then(
        visitedItems => {
          if (visitedItems) {
            const isArray = visitedItems.constructor === Array
            // make sure visited always returns an array, otherwise _uniq derps out
            const uniqueVisitedItems = _uniqBy(isArray ? visitedItems : [visitedItems], uniqueKey)
            const recentVisitedItems = uniqueVisitedItems.slice(0, visitedLimit)

            resolve(recentVisitedItems)
          } else {
            resolve(null)
          }
        },
        err => {
          reject(err)
        },
      )
    })
  },
}

export default storageAdapter
