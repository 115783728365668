const memberClaims = {
  agents: {
    area: 'agents_permission',
    claims: {
      canGenerateVideo: 'CanGenerateVideo',
      canToggleVideoVisibility: 'CanToggleVideoVisibility',
      canTriggerPremierAgentWorkflow: 'CanTriggerPremierAgentWorkflow',
      canChangeAnyAgentsSetting: 'CanChangeAnyAgentsSetting',
      canManageAnyAgentReview: 'CanManageAnyAgentReview',
    },
  },
  listings: {
    area: 'listings_permission',
    claims: {
      canViewHidden: 'CanViewHidden',
      canBoost: 'CanBoost',
      canManageAny: 'CanManageAny',
    },
  },
  apps: {
    area: 'apps_permission',
    claims: {
      canToggleFeatures: 'CanToggleFeatures',
    },
  },
  collections: {
    area: 'collections_permission',
    claims: {
      canManageAny: 'CanManageAny',
    },
  },
  users: {
    area: 'users_permission',
    claims: {
      canViewAnyMember: 'CanViewAnyMember',
      canManageAny: 'CanManageAny',
    },
  },
  multiloaders: {
    area: 'multiloaders_permission',
    claims: {
      canViewFiles: 'CanViewFiles',
      canStoreAFile: 'CanStoreAFile',
      canViewErrors: 'CanViewErrors',
      canStoreAnError: 'CanStoreAnError',
    },
  },
  tracking: {
    area: 'tracking_permission',
    claims: {
      canAddListingEvent: 'CanAddListingEvent',
    },
  },
  admin: {
    area: 'ugc_permission',
    claims: {
      canManageAny: 'CanManageAny',
      canViewAny: 'CanViewAny',
    },
  },
};

export default memberClaims;
