import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const HamburgerButtonSc = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 4px 12px 12px;
  padding: 15px 0 0;
  width: 48px;
  height: 48px;
  color: ${theme.colors.grayDark};
  background: transparent;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;

  @media ${theme.breakpoints.desktop} {
    display: none;
  }
`
