export const homeFooterDefaultLocations = [
  {
    name: 'Berwick',
    uri: 'berwick-casey-melbourne-greater-victoria',
    slug: 'berwick-vic-3806',
  },
  {
    name: 'Craigieburn',
    uri: 'craigieburn-hume-melbourne-greater-victoria',
    slug: 'craigieburn-vic-3064',
  },
  {
    name: 'Doreen',
    uri: 'doreen-whittlesea-melbourne-greater-victoria',
    slug: 'doreen-vic-3754',
  },
  {
    name: 'Edithvale',
    uri: 'edithvale-kingston-melbourne-greater-victoria',
    slug: 'edithvale-vic-3196',
  },
  {
    name: 'Essendon',
    uri: 'essendon-moonee-valley-melbourne-greater-victoria',
    slug: 'essendon-vic-3040',
  },
  {
    name: 'Footscray',
    uri: 'footscray-maribyrnong-melbourne-greater-victoria',
    slug: 'footscray-vic-3011',
  },
  {
    name: 'Frankston',
    uri: 'frankston-frankston-melbourne-greater-victoria',
    slug: 'frankston-vic-3199',
  },
  {
    name: 'Glenroy',
    uri: 'glenroy-moreland-melbourne-greater-victoria',
    slug: 'glenroy-vic-3046',
  },
  {
    name: 'Heidelberg West',
    uri: 'heidelberg-west-banyule-melbourne-greater-victoria',
    slug: 'heidelberg-west-vic-3081',
  },
  {
    name: 'Melbourne (CBD)',
    uri: 'melbourne-cbd-melbourne-melbourne-greater-victoria',
    slug: 'melbourne-vic-3000',
  },
  {
    name: 'Pakenham',
    uri: 'pakenham-cardinia-melbourne-greater-victoria',
    slug: 'pakenham-vic-3810',
  },
  {
    name: 'Point Cook',
    uri: 'point-cook-wyndham-melbourne-greater-victoria',
    slug: 'point-cook-vic-3030',
  },
  {
    name: 'Port Melbourne',
    uri: 'port-melbourne-port-phillip-melbourne-greater-victoria',
    slug: 'port-melbourne-vic-3207',
  },
  {
    name: 'Reservoir',
    uri: 'reservoir-darebin-melbourne-greater-victoria',
    slug: 'reservoir-vic-3073',
  },
  {
    name: 'St Kilda',
    uri: 'st-kilda-port-phillip-melbourne-greater-victoria',
    slug: 'st-kilda-vic-3182',
  },
  {
    name: 'Sunshine',
    uri: 'sunshine-brimbank-melbourne-greater-victoria',
    slug: 'sunshine-vic-3020',
  },
  {
    name: 'Tarneit',
    uri: 'tarneit-wyndham-melbourne-greater-victoria',
    slug: 'tarneit-vic-3029',
  },
  {
    name: 'Truganina',
    uri: 'truganina-wyndham-melbourne-greater-victoria',
    slug: 'truganina-vic-3029',
  },
  {
    name: 'Werribee',
    uri: 'werribee-wyndham-melbourne-greater-victoria',
    slug: 'werribee-vic-3030',
  },
  {
    name: 'Wyndham Vale',
    uri: 'wyndham-vale-wyndham-melbourne-greater-victoria',
    slug: 'wyndham-vale-vic-3024',
  },
  {
    name: 'Auburn',
    uri: 'auburn-auburn-sydney-greater-new-south-wales',
    slug: 'auburn-nsw-2144',
  },
  {
    name: 'Bankstown',
    uri: 'bankstown-bankstown-sydney-greater-new-south-wales',
    slug: 'bankstown-nsw-2200',
  },
  {
    name: 'Baulkham Hills',
    uri: 'baulkham-hills-baulkham-hills-sydney-greater-new-south-wales',
    slug: 'baulkham-hills-nsw-2153',
  },
  {
    name: 'Blacktown',
    uri: 'blacktown-blacktown-sydney-greater-new-south-wales',
    slug: 'blacktown-nsw-2148',
  },
  {
    name: 'Campbelltown',
    uri: 'campbelltown-campbelltown-sydney-greater-new-south-wales',
    slug: 'campbelltown-nsw-2560',
  },
  {
    name: 'Castle Hill',
    uri: 'castle-hill-baulkham-hills-sydney-greater-new-south-wales',
    slug: 'castle-hill-nsw-2154',
  },
  {
    name: 'Gosford',
    uri: 'gosford-gosford-sydney-greater-new-south-wales',
    slug: 'gosford-nsw-2250',
  },
  {
    name: 'Hurstville',
    uri: 'hurstville-hurstville-sydney-greater-new-south-wales',
    slug: 'hurstville-nsw-2220',
  },
  {
    name: 'Kareela',
    uri: 'kareela-sutherland-shire-sydney-greater-new-south-wales',
    slug: 'kareela-nsw-2232',
  },
  {
    name: 'Kellyville',
    uri: 'kellyville-baulkham-hills-sydney-greater-new-south-wales',
    slug: 'kellyville-nsw-2155',
  },
  {
    name: 'Kings Langley',
    uri: 'kings-langley-blacktown-sydney-greater-new-south-wales',
    slug: 'kings-langley-nsw-2147',
  },
  {
    name: 'Liverpool',
    uri: 'liverpool-liverpool-sydney-greater-new-south-wales',
    slug: 'liverpool-nsw-2170',
  },
  {
    name: 'Maroubra',
    uri: 'maroubra-randwick-sydney-greater-new-south-wales',
    slug: 'maroubra-nsw-2035',
  },
  {
    name: 'Merrylands',
    uri: 'merrylands-holroyd-sydney-greater-new-south-wales',
    slug: 'merrylands-nsw-2160',
  },
  {
    name: 'Mount Druitt',
    uri: 'mount-druitt-blacktown-sydney-greater-new-south-wales',
    slug: 'mount-druitt-nsw-2770',
  },
  {
    name: 'Parramatta',
    uri: 'parramatta-parramatta-sydney-greater-new-south-wales',
    slug: 'parramatta-nsw-2150',
  },
  {
    name: 'Penrith',
    uri: 'penrith-penrith-sydney-greater-new-south-wales',
    slug: 'penrith-nsw-2750',
  },
  {
    name: 'Quakers Hill',
    uri: 'quakers-hill-blacktown-sydney-greater-new-south-wales',
    slug: 'quakers-hill-nsw-2763',
  },
  {
    name: 'Riverstone',
    uri: 'riverstone-blacktown-sydney-greater-new-south-wales',
    slug: 'riverstone-nsw-2765',
  },
  {
    name: 'Sydney (CBD)',
    uri: 'sydney-cbd-sydney-sydney-greater-new-south-wales',
    slug: 'sydney-nsw-2000',
  },
  {
    name: 'Brisbane (CBD)',
    uri: 'brisbane-cbd-brisbane-brisbane-queensland',
    slug: 'brisbane-qld-4000',
  },
  {
    name: 'Brookwater',
    uri: 'brookwater-ipswich-brisbane-queensland',
    slug: 'brookwater-qld-4300',
  },
  {
    name: 'Chapel Hill',
    uri: 'chapel-hill-brisbane-brisbane-queensland',
    slug: 'chapel-hill-qld-4069',
  },
  {
    name: 'Fairfield',
    uri: 'fairfield-brisbane-brisbane-queensland',
    slug: 'fairfield-qld-4103',
  },
  {
    name: 'Forest Lake',
    uri: 'forest-lake-brisbane-brisbane-queensland',
    slug: 'forest-lake-qld-4078',
  },
  {
    name: 'Ipswich',
    uri: 'ipswich-ipswich-brisbane-queensland',
    slug: 'ipswich-qld-4305',
  },
  {
    name: 'Mitchelton',
    uri: 'mitchelton-brisbane-brisbane-queensland',
    slug: 'mitchelton-qld-4053',
  },
  {
    name: 'Moorooka',
    uri: 'moorooka-brisbane-brisbane-queensland',
    slug: 'moorooka-qld-4105',
  },
  {
    name: 'North Lakes',
    uri: 'north-lakes-pine-rivers-brisbane-queensland',
    slug: 'north-lakes-qld-4509',
  },
  {
    name: 'Springfield Lakes',
    uri: 'springfield-lakes-ipswich-brisbane-queensland',
    slug: 'springfield-lakes-qld-4300',
  },
  {
    name: 'Bibra Lake',
    uri: 'bibra-lake-cockburn-perth-greater-western-australia',
    slug: 'bibra-lake-wa-6163',
  },
  {
    name: 'Booragoon',
    uri: 'booragoon-melville-perth-greater-western-australia',
    slug: 'booragoon-wa-6154',
  },
  {
    name: 'Canning Vale',
    uri: 'canning-vale-canning-perth-greater-western-australia',
    slug: 'canning-vale-wa-6155',
  },
  {
    name: 'Currambine',
    uri: 'currambine-joondalup-perth-greater-western-australia',
    slug: 'currambine-wa-6028',
  },
  {
    name: 'Melville',
    uri: 'melville-melville-perth-greater-western-australia',
    slug: 'melville-wa-6156',
  },
  {
    name: 'Aldgate',
    uri: 'aldgate-adelaide-hills-adelaide-greater-south-australia',
    slug: 'aldgate-sa-5154',
  },
  {
    name: 'Evandale',
    uri: 'evandale-norwood-payneham-st-peters-adelaide-greater-south-australia',
    slug: 'evandale-sa-5069',
  },
  {
    name: 'Mawson Lakes',
    uri: 'mawson-lakes-salisbury-adelaide-greater-south-australia',
    slug: 'mawson-lakes-sa-5095',
  },
  {
    name: 'Seaview Downs',
    uri: 'seaview-downs-marion-adelaide-greater-south-australia',
    slug: 'seaview-downs-sa-5049',
  },
];
