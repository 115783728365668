import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const HomeHeroSectionSc = styled.section`
  position: relative;
  margin: 0 0 100px;

  @media ${theme.breakpoints.desktop} {
    margin: 0 0 120px;
  }
`

export const HomeHeroBackgroundSc = styled.div`
  position: absolute;
  top: 0;
  left: 49.5%;
  right: 0;
  height: 474px;
  overflow: hidden;
  pointer-events: none;

  img {
    position: absolute;
    top: 0;
    left: 168px;
    width: 729px;
    max-width: 729px;
    height: 474px;
  }
`

export const HomeHeroSc = styled.div`
  padding: 32px 0;
  width: 100%;
  -webkit-touch-callout: none;
  user-select: none;

  @media ${theme.breakpoints.desktop} {
    padding: 64px 0 68px;
  }
`

export const HomeHeroHeaderSc = styled.h1`
  font-weight: ${theme.fontWeights.bold};
  font-size: 34px;
  line-height: 44px;
  color: ${theme.colors.headerText};

  width: 320px;
  @media (min-width: 429px) {
    width: auto;
  }

  @media ${theme.breakpoints.desktop} {
    font-size: 44px;
    line-height: 58px;
  }
`

export const HomeHeroHeaderColourSc = styled.div`
  color: ${theme.colors.redBase};

  & > span {
    display: block;
  }

  @media ${theme.breakpoints.desktop} {
    & > span {
      display: inline;
    }
  }
`

export const HomeHeroSubHeaderSc = styled.h2`
  margin: 24px 0 0;
  font-size: 15px;
  line-height: 29px;
  color: ${theme.colors.grayDark};

  /* Hide subheader on small devices */
  @media (max-device-height: 600px) {
    display: none;
  }

  @media ${theme.breakpoints.desktop} {
    font-size: 16px;
  }
`

export const NoWrapSc = styled.span`
  white-space: nowrap;
`

export const MobileImageSc = styled.div`
  img {
    width: 100%;
  }
`
