import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const FooterSocialSc = styled.span<{ isSearch: boolean }>`
  margin-left: -12px;
  margin-bottom: 12px;

  @media ${theme.breakpoints.desktop} {
    margin-bottom: ${({ isSearch }) => (!isSearch ? '0' : '12px')};
  }
`
