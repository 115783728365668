import React, { ReactNode } from 'react';

import TitleBreakSc from './title-break.style';

type Props = {
  inlineMobile?: boolean;
  inlineDesktop?: boolean;
  children: ReactNode;
};

const TitleBreak = ({ children, inlineMobile = false, inlineDesktop = false }: Props) => (
  <TitleBreakSc inlineMobile={inlineMobile} inlineDesktop={inlineDesktop}>{children}</TitleBreakSc>
);

export default TitleBreak;
