import { styled } from '@linaria/react'
import theme from '~/styles/theme'

import { wideBreakpoint } from './utils/helper'

export const HomeImagesSc = styled.div`
  width: 100%;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: touch;
  user-select: none;
`

export const HomeImageTrackSc = styled.div`
  display: flex;
`

export const HomeImageColSc = styled.div`
  @media ${theme.breakpoints.desktop} {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }

  /* Too wide - scale */
  @media (min-width: ${wideBreakpoint}) {
    flex: 1;
  }
`
