import React, { ReactNode } from 'react';

import { MobileOnlySc } from './mobile-only.style';

type Props = {
  isFlex?: boolean;
  children: ReactNode;
};

const MobileOnly = ({ isFlex = false, children }: Props) => <MobileOnlySc isFlex={isFlex}>{children}</MobileOnlySc>;

export default MobileOnly;
