import { styled } from '@linaria/react';

export const VisuallyHiddenSc = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  line-height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  position: absolute;

  // https://medium.com/@jessebeach/beware-smushed-off-screen-accessible-text-5952a4c2cbfe
  white-space: nowrap;
  word-wrap: normal;
`;
