import React, { useRef, useState, useEffect, type ReactNode } from 'react'
import { canUseDOM } from '~/lib/utils/can-use-dom'
import smoothscroll from 'smoothscroll-polyfill'

import debounce from '~/lib/utils/debounce'

import FlatButton from '~/components/buttons/flat-button'
import Icon from '~/components/icon'

import {
  ScrollListSc,
  ScrollListTrackSc,
  ScrollListContentSc,
  ScrollListItemSc,
  ScrollListButtonHolderSc,
} from './scroll-list.style'

if (canUseDOM) {
  smoothscroll.polyfill()
}

const scrollPadding = 20

type Props = {
  isAlt?: boolean
  isCenter?: boolean
  children?: ReactNode[]
  isSearch?: boolean
}

const ScrollList = ({
  isAlt = false,
  isCenter = false,
  isSearch = false,
  children = null,
}: Props) => {
  const track = useRef(null)
  const [showPrev, setShowPrev] = useState(false)
  const [showNext, setShowNext] = useState(false)

  const showArrow = children.length > 1
  const handleScroll = () => {
    const { current } = track
    if (current) {
      const { offsetWidth, scrollWidth, scrollLeft } = current
      setShowPrev(showArrow && scrollLeft > scrollPadding)
      setShowNext(showArrow && offsetWidth + scrollLeft < scrollWidth - scrollPadding)
    }
  }
  const handleScrollDebounced = debounce(handleScroll, 64)
  useEffect(() => {
    handleScroll()
  }, [showArrow])

  useEffect(() => {
    const { current } = track
    if (current?.scrollTo) {
      track.current.scrollTo(0, 0)
    }
    handleScroll()
  }, [children])

  const scrollTo = left =>
    track.current.scrollTo({
      left: track.current.scrollLeft + left,
      behavior: 'smooth',
    })
  const handlePrev = () => scrollTo(-track.current.offsetWidth)
  const handleNext = () => scrollTo(track.current.offsetWidth)
  return (
    <ScrollListSc isSearch={isSearch}>
      <ScrollListTrackSc ref={track} onScroll={handleScrollDebounced}>
        <ScrollListContentSc>
          {React.Children.map(children, child => (
            <ScrollListItemSc isCenter={isCenter}>{child}</ScrollListItemSc>
          ))}
        </ScrollListContentSc>
      </ScrollListTrackSc>
      {showPrev && (
        <ScrollListButtonHolderSc isSearch={isSearch} isAlt={isAlt} isPrev>
          <FlatButton aria-label="Previous" onClick={handlePrev}>
            <Icon icon="next" />
            <span className="hide">Previous</span>
          </FlatButton>
        </ScrollListButtonHolderSc>
      )}
      {showNext && (
        <ScrollListButtonHolderSc isSearch={isSearch} isAlt={isAlt}>
          <FlatButton aria-label="Next" onClick={handleNext}>
            <Icon icon="next" />
            <span className="hide">Next</span>
          </FlatButton>
        </ScrollListButtonHolderSc>
      )}
    </ScrollListSc>
  )
}

export default ScrollList
