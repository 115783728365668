import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const HeaderLineSc = styled.div`
  margin: 0 0 32px;
  width: 160px;
  height: 4px;
  background: ${theme.colors.redBase};
  border-radius: 2px;
  user-select: none;
`
