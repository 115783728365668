import { useRef, useState } from 'react'

import SearchAutoCompleteModal from '~/components/search-auto-complete-modal'
import SearchAutoCompleteMulti from '~/components/search-auto-complete-multi'

import { useOutsideClick } from '~/lib/hooks/index'
import { getResultType, getExtraData } from '~/modules/listing-search/utils/tracking'
import { trackEvent, eventTypes, contentTypes } from '~/lib/utils/google-tag-manager'
import { getSearchUrl } from '~/lib/utils/listings-search-helper'
import { defaultLocationObject } from '~/lib/utils/location-helper'
import storage from '~/lib/utils/storage'
import ContextTypes from '~/lib/config/listings-context-types'
import { getAgentSearchUrl, getQuestionDetailsUrl } from '~/lib/config/routing'
import { useLocationsApi } from '~/lib/providers/locations-context'
import SearchTabs from './components/search-tabs'
import categories from './home-search-categories'

import {
  HomeSearchSc,
  HomeSearchHolderSc,
  HomeSearchModalHolderSc,
  HomeSearchAutoCompleteSc,
} from './home-search.style'

const cacheKey = 'home-search'

type Props = {
  isDesktop?: boolean
}

const HomeSearch = ({ isDesktop = false }: Props) => {
  const ref = useRef()

  const { setLocations } = useLocationsApi()

  const [searchedlocations, setSearchedLocations] = useState([])
  const [prevSearchedLocations, setPrevSearchedLocations] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [isSurroundSearch, setIsSurroundSearch] = useState(true)
  const [showSurrounding, setShowSurrounding] = useState(true)
  const initialCategory = categories[0]
  const [selectedCategory, setSelectedCategory] = useState(initialCategory)
  const [prevCategory, setPrevCategory] = useState(initialCategory)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)
  const handleOpenMobile = () => setPrevCategory(selectedCategory)
  useOutsideClick(ref, (e: any) => {
    // BUG: Remove button registers as an outside click...
    if (e?.target?.classList?.contains('remove')) return
    handleClose()
  })
  // Limit agents/reviews/questions to 1 location.
  const isLimitedCategory = (category: any) =>
    ['agents', 'reviews', 'questions'].indexOf(category.value) >= 0
  const isPrev = (id: any) => prevSearchedLocations.findIndex(p => (p as any).id === id) !== -1
  const trackSearch = (locations: any) => {
    const { mode } = selectedCategory
    const deletedCount = prevSearchedLocations.length - locations.length
    const extraData = `${getExtraData(mode, locations, null, isSurroundSearch)}${
      deletedCount > 0 ? `_deleted_${deletedCount}` : ''
    }`
    const pageElementPosition = `${
      locations.length > 1
        ? locations
            .map(
              ({ isRecent, isCurrent, id }: any, index: any) =>
                `${index + 1}_${getResultType(isRecent, isCurrent, isPrev(id))}`,
            )
            .join('_')
        : getResultType(locations[0].isRecent, locations[0].isCurrent, isPrev(locations[0].id))
    }${mode !== prevCategory.mode ? '_all_mode' : ''}`
    trackEvent({
      event: eventTypes.formSubmit,
      contentType: contentTypes.locationSearch,
      pageElementPosition,
      extraData,
    })
  }
  const handleSelectLocations = (locations: any) => {
    setSearchedLocations(locations)
  }
  const handleSetDefaultLocations = (locations: any) => {
    setSearchedLocations(locations)
    setPrevSearchedLocations(locations)
    setLocations(locations)
  }
  const handleSearchButton = () => {
    const locations = searchedlocations.length > 0 ? searchedlocations : [defaultLocationObject]
    setLocations(locations)
    // Delay redirect so state can update.
    setTimeout(() => {
      const { uri, slug } = locations[0]
      const { value, searchType } = selectedCategory
      if (value === 'reviews') {
        router.push(`/${uri}`)
      } else if (value === 'questions') {
        router.push(getQuestionDetailsUrl(uri))
      } else if (value === 'agents') {
        router.push(getAgentSearchUrl(slug))
      } else {
        const searchUrl = getSearchUrl(searchType, locations, isSurroundSearch)
        router.push(searchUrl)
      }
      trackSearch(locations)
      setPrevSearchedLocations(locations)
    }, 100)
  }
  const handleSelectCategory = (category: any) => {
    setSelectedCategory(category)
    if (isLimitedCategory(category) && searchedlocations.length > 0) {
      setSearchedLocations([searchedlocations[0]])
      setShowSurrounding(false)
    } else {
      setShowSurrounding(true)
    }
    storage.setItem(cacheKey, category.value)
  }
  const handleToggleSurround = () => {
    setIsSurroundSearch(!isSurroundSearch)
  }
  // TODO: Preload ListingsFaceted.
  const handlePreload = () => {}
  const autoComplete = (
    <HomeSearchAutoCompleteSc ref={ref} isOpen={isOpen}>
      <SearchAutoCompleteMulti
        locations={searchedlocations}
        locationsLimit={isLimitedCategory(selectedCategory) ? 1 : 10}
        tagDisplayLimitDefault={isDesktop ? 3 : 1}
        isOpen={isOpen}
        prefillLocations
        showRecent
        alwaysShowRecent
        showIcon={!isDesktop}
        showOptions={isDesktop}
        showButtonIcon
        showButtonText
        isHero
        showRemove={isDesktop}
        showSurrounding={showSurrounding}
        isSurroundSearch={isSurroundSearch}
        context={ContextTypes.Location}
        onOpen={isDesktop ? handleOpen : handleOpenMobile}
        onClose={handleClose}
        onSelectResult={handleSelectLocations}
        onSetDefault={handleSetDefaultLocations}
        onSearchButton={handleSearchButton}
        onToggleSurrounding={handleToggleSurround}
      />
    </HomeSearchAutoCompleteSc>
  )
  return (
    <HomeSearchSc role="search" onMouseOver={handlePreload} onFocus={handlePreload}>
      <SearchTabs active={selectedCategory?.value} onSelect={handleSelectCategory} />
      <HomeSearchHolderSc>
        {isDesktop ? (
          autoComplete
        ) : (
          <SearchAutoCompleteModal
            label="Search locations"
            locations={searchedlocations}
            locationsLimit={isLimitedCategory(selectedCategory) ? 1 : 10}
            active={selectedCategory?.value}
            onSelectLocations={handleSelectLocations}
            onSelectMode={handleSelectCategory}
            onSearchButton={handleSearchButton}
            showSurrounding={showSurrounding}
            isSurroundSearch={isSurroundSearch}
            context={ContextTypes.Location}
            onToggleSurrounding={handleToggleSurround}
          >
            <HomeSearchModalHolderSc>{autoComplete}</HomeSearchModalHolderSc>
          </SearchAutoCompleteModal>
        )}
      </HomeSearchHolderSc>
    </HomeSearchSc>
  )
}
export default HomeSearch
