import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const DiscoverLinkHolderSc = styled.div`
  margin: 32px 0 0;
`

export const DiscoverLinkSc = styled.a`
  font-weight: ${theme.fontWeights.bold};
  font-size: 16px;
  line-height: 28px;
  color: ${theme.colors.redBase};
`
