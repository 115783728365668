import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const FeatureToggleSc = styled.div`
  position: fixed;
  top: 72px;
  right: 8px;
  padding-top: 8px;
  width: 220px;
  font-size: 14px;
  overflow: hidden;
  pointer-events: all;
  z-index: 200;
  background-color: ${theme.colors.whiteBase};
  box-shadow: 0 2px 7px 3px rgba(138, 151, 157, 0.2);
  border: 1px solid ${theme.colors.grayLight2};
  border-radius: 8px;
  opacity: 1;
`

export const FeatureToggleButtonSc = styled.button`
  display: block;
  margin: 20px 0;
  width: 100%;
  padding: 0;
  line-height: 24px;
  text-align: center;
  font-size: 15px;
  background: ${theme.colors.whiteBase};

  border: 0;
  &:hover {
    color: ${theme.colors.redBase};
  }
`
