import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const HomeAppSc = styled.div`
  position: relative;
  background: ${theme.colors.redBase};

  margin: 0 0 340px;
  padding: 56px 20px 337px;

  @media ${theme.breakpoints.desktop} {
    margin: 110px auto;
    padding: 56px;
    max-width: 1200px;
  }

  @media (min-width: 1200px) {
    border-radius: 12px;
  }
`

export const HomeAppTitleSc = styled.h3`
  margin: 0 0 32px;
  font-weight: ${theme.fontWeights.bold};
  font-size: 32px;
  line-height: 44px;
  color: ${theme.colors.whiteBase};

  @media ${theme.breakpoints.desktop} {
    font-size: 36px;
    line-height: 48px;
  }
`

export const HomeAppDescriptionSc = styled.p`
  margin: 0 0 32px;
  padding: 0;
  font-size: 16px;
  line-height: 28px;
  color: ${theme.colors.whiteBase};

  @media ${theme.breakpoints.desktop} {
    width: 50%;
    font-size: 17px;
    line-height: 29px;
  }
`

export const HomeAppImageHolderSc = styled.div`
  width: 373px;
  height: 551px;
  user-select: none;

  position: absolute;
  left: 50%;
  transform: translate(-50%, 60px);

  @media ${theme.breakpoints.desktop} {
    top: 50%;
    right: 10%;
    left: auto;
    transform: translate(0, -50%);
  }
`

export const BlurSc = styled.div`
  position: absolute;
  top: 378px;
  left: 10px;
  width: 353px;
  height: 115px;
  backdrop-filter: blur(15px);
  border-radius: 12px;
  z-index: -1;
`
