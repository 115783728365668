import { styled } from '@linaria/react'
import { ToggleWithLabelSc } from '~/components/toggle-with-label/toggle-with-label.style'

export const SearchAutoCompleteOptionsSc = styled.div`
  display: flex;
  padding: 11px 16px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    padding: 12px 32px;
  }
`

export const SearchAutoCompleteButtonSc = styled.button<{
  isActive?: boolean
  isDisabled?: boolean
  isInline?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  width: 50%;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: ${({ theme }) => theme.colors.grayDark};
  background-color: ${({ theme }) => theme.colors.whiteBase};
  border: 1px solid ${({ theme }) => theme.colors.grayLight2};
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  &:not(:first-child) {
    margin-left: 6px;
  }

  @media ${({ theme }) => theme.breakpoints.desktop} {
    min-width: 210px;
    width: auto;

    &:not(:first-child) {
      margin-left: 12px;
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme }) => theme.colors.redBase};
      border-color: ${({ theme }) => theme.colors.redBase};
    }
  }

  & > ${ToggleWithLabelSc} {
    width: 100%;
  }

  /* Active */
  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.colors.redBase};
    .icon {
      color: ${theme.colors.redBase};
    }
  `}

  // Disabled
  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    color: ${theme.colors.grayLight2};
    border-color: #F2F2F2;
    &:hover,
    &:active {
      color: ${theme.colors.grayLight2};
      border-color: #F2F2F2;
    }
    cursor: default;
  `}

  ${({ isInline }) =>
    isInline &&
    `
    border: none;
    padding-left: 0;
  `}
`

export const SearchAutoCompleteButtonLabelSc = styled.div<{ isDisabled?: boolean }>`
  flex: 1;
  margin: 0 24px 0 2px;
  cursor: ${({ isDisabled }) => (isDisabled ? 'default' : 'pointer')};
`

export const SpinnerSc = styled.div`
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;

  & > div {
    display: flex;
  }
`

export const ToolTipTargetSc = styled.span`
  width: 1px;
  text-align: right;
`

export default {
  SearchAutoCompleteOptionsSc,
  SearchAutoCompleteButtonSc,
  ToolTipTargetSc,
  SpinnerSc,
}
