import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const NavLinkSc = styled.a<{ isHeader?: boolean; isActive?: boolean }>`
  position: relative;
  display: block;
  margin: 0;
  padding: ${({ isHeader }) => (isHeader ? '0 16px' : '0')};
  font-weight: ${({ isHeader, isActive }) => (isActive ? theme.fontWeights.bold : isHeader ? theme.fontWeights.medium : theme.fontWeights.regular)} !important;
  font-size: ${({ isHeader }) => (isHeader ? '14px' : 'inherit')};
  line-height: ${({ isHeader }) => (isHeader ? '48px' : 'inherit')};
  text-align: ${({ isHeader }) => (isHeader ? 'center' : 'inherit')};
  border-radius: ${({ isHeader }) => (isHeader ? '8px' : '0')};
  color: ${({ isActive }) => (isActive ? theme.colors.redBase : theme.colors.headerText)} !important;
  background-color: transparent;
  border: 0;
  -webkit-touch-callout: none;
  user-select: none;

  /* Fix: Prevent size jumping on hover. */
  &:after {
    display: block;
    content: attr(title);
    font-weight: ${theme.fontWeights.bold};
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  /* Accessibility */
  body.no-touch &&:hover,
  body.keynav-mode &&:focus,
  &&:active,
  &&.active {
    outline: 0;
    color: ${theme.colors.redBase} !important;
    font-weight: ${({ isHeader }) =>
      isHeader ? theme.fontWeights.bold : theme.fontWeights.regular};
  }
`
