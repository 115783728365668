import ScrollList from '~/components/scroll-list'
import ListingCardEmpty from '~/components/listing-card-empty'

const HomeListingsSkeleton = () => (
  <ScrollList>
    {[1, 2, 3, 4].map((index: any) => (
      <ListingCardEmpty key={index} showLabels={false} />
    ))}
  </ScrollList>
)

export default HomeListingsSkeleton
