import React from 'react';

import { SectionSc } from './section.style';

type Props = {
  children: React.ReactNode;
};

const HomeSection = ({ children }: Props) => <SectionSc>{children}</SectionSc>;

export default HomeSection;
