import React, { useEffect, useRef, useCallback } from 'react'
import { useLocation } from '@remix-run/react'

import { trackPage as trackPageGtm } from '~/lib/utils/google-tag-manager'
import type { PageTypes } from '~/lib/config/page-types'

export type WithTrackingProps = {
  router?: NextRouter
  pageType?: PageTypes
  pageTrackingOptions?: null | Record<string, unknown>
}

const withTracking = <T extends WithTrackingProps = WithTrackingProps>(
  WrappedComponent: React.ComponentType<T>,
) => {
  const WithTracking: React.FC<T> = props => {
    const { pageType, pageTrackingOptions = null, router } = props

    // Get full URL for App Router (path + query string)
    const location = useLocation()
    const remixFullUrl = `${location.pathname}${location.search}`

    // Use ref to detect initial load
    const isInitialLoadRef = useRef(true)

    // Store timeout ID to clear it when necessary
    const timeoutRef = useRef<NodeJS.Timeout | null>(null)

    // Determine the current path
    const getPath = () => {
      if (router?.asPath) {
        // Pages Router (if still needed)
        const isMap = router.asPath.includes('context=')
        return isMap ? router.asPath.split('?')[0] : router.asPath
      } else {
        // Remix
        return remixFullUrl
      }
    }

    const currentPath = getPath()

    // Use ref to store previous path
    const previousPathRef = useRef<string | null>(null)

    // Track page view with a delay
    const trackPage = useCallback(() => {
      timeoutRef.current = setTimeout(() => {
        if (pageType) {
          trackPageGtm(pageType, { ...pageTrackingOptions })
        }
      }, 500)
    }, [pageType, pageTrackingOptions])

    // Clear the timeout when the component unmounts or dependencies change
    const clearTimeoutIfNeeded = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
        timeoutRef.current = null
      }
    }

    // Handle initial load tracking
    useEffect(() => {
      if (isInitialLoadRef.current) {
        trackPage()
        isInitialLoadRef.current = false // Mark initial load as done
        previousPathRef.current = currentPath // Set the previous path after the first track
      }
    }, [trackPage, currentPath])

    // Handle path changes for client-side navigation
    useEffect(() => {
      if (isInitialLoadRef.current) return // Skip for initial load

      const prevPath = previousPathRef.current
      const currentPathWithoutHash = currentPath.split('#')[0]
      const prevPathWithoutHash = prevPath ? prevPath.split('#')[0] : null

      // Track the page only if the path changed
      if (prevPathWithoutHash !== currentPathWithoutHash) {
        trackPage()
      }

      // Update the previous path
      previousPathRef.current = currentPath
    }, [currentPath, trackPage])

    // Clean up the timeout on unmount
    useEffect(() => {
      return () => {
        clearTimeoutIfNeeded()
      }
    }, [])

    return <WrappedComponent {...props} />
  }

  return WithTracking
}

export default withTracking
