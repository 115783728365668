import { styled } from '@linaria/react'

interface Props {
  isListingsModal: boolean
}

export const ResultHeaderSc = styled.li<Props>`
  line-height: 19px;
  padding: 12px 32px 16px;
  font-weight: 500;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.headerText};

  /* Listings Search - Modal */
  ${({ isListingsModal }) =>
    isListingsModal &&
    `
    padding: 18px 20px;
  `};
`

export const ResultHeaderLabelSc = styled.span``

export default {
  ResultHeaderSc,
  ResultHeaderLabelSc,
}
