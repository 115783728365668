import { styled } from '@linaria/react'
import theme from '~/styles/theme'

import { wideBreakpoint } from '../../utils/helper'

const getBackground = (color?: string, theme?: any) => {
  if (color === 'blue') return theme.colors.avatar.color10
  if (color === 'yellow') return theme.colors.avatar.color7
  // purple
  return theme.colors.avatar.color2
}

export const HomeImageReviewSc = styled.div<{ bgColor: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0;
  padding: 24px;
  width: 256px;
  height: 248px;
  font-weight: 500;
  text-align: right;
  color: ${theme.colors.whiteBase};
  background: ${({ bgColor }) => getBackground(bgColor, theme)};

  @media ${theme.breakpoints.desktop} {
    padding: 32px;
    width: 356px;
    height: 345px;
  }

  /* Too wide - scale */
  @media (min-width: ${wideBreakpoint}) {
    width: 100%;
    height: 100%;
  }
`

export const HomeImageReviewLogoSc = styled.div`
  margin: 0 0 15px;
  width: 22px;
  height: 23px;

  @media ${theme.breakpoints.desktop} {
    margin: 0 0 24px;
    width: 30px;
    height: 32px;
  }

  img {
    vertical-align: top;
  }
`

export const HomeImageReviewDescriptionSc = styled.p`
  flex: 1;
  margin: 0;
  padding: 0;
  width: 147px;
  font-size: 14px;
  line-height: 24px;

  @media ${theme.breakpoints.desktop} {
    width: 207px;
    font-size: 20px;
    line-height: 30px;
  }
`

export const HomeImageFooterSc = styled.footer`
  font-size: 12px;
  line-height: 22px;

  @media ${theme.breakpoints.desktop} {
    font-size: 16px;
    line-height: 30px;
  }
`

export const HomeImageLocationSc = styled.cite`
  font-style: normal;
`
