import React from 'react'

import { homeFooterDefaultLocations } from '~/lib/config/home-footer-default'

import defaultLinks from '~/components/footer-links/default-links'

import Column from '../column'
import FooterLinkList from './components/footer-link-list'

import { FooterLinksSc } from './footer-links.style'

type Props = {
  locations?: any[]
  links?: any[]
}

const FooterLinks = ({ locations = homeFooterDefaultLocations, links = defaultLinks }: Props) => (
  <FooterLinksSc>
    <Column>
      {links.map(link => (
        <FooterLinkList key={link.title} locations={locations} {...link} />
      ))}
    </Column>
  </FooterLinksSc>
)

export default FooterLinks
