import React from 'react'
import { trackEvent, eventTypes, contentTypes } from '~/lib/utils/google-tag-manager'
import SocialLink from '../social-link'
import { FooterSocialSc } from './footer-social.style'

const social = [
  { platform: 'facebook', url: 'https://www.facebook.com/homely.com.au' },
  { platform: 'instagram', url: 'https://www.instagram.com/homely.com.au' },
  { platform: 'twitter', url: 'https://twitter.com/homelycomau' },
  {
    platform: 'linkedin-boxed',
    url: 'https://www.linkedin.com/company/homely-com-au',
  },
]

type Props = {
  isSearch: boolean
}

const FooterSocial = ({ isSearch }: Props) => {
  const handleClick = platform => {
    trackEvent({
      contentType: contentTypes.homelySociaLink,
      pageElementPosition: 'footer',
      extraData: platform,
      event: eventTypes.selectContent,
    })
  }
  return (
    <FooterSocialSc isSearch={isSearch}>
      {social.map(link => (
        <SocialLink key={link.platform} {...link} isSmall onClick={handleClick} />
      ))}
    </FooterSocialSc>
  )
}

export default FooterSocial
