import React, { ReactNode, useRef } from 'react'

import { MobileOnly } from '~/components/responsive'
import ToggleWithLabel from '~/components/toggle-with-label'
import Icon from '~/components/icon'
import Spinner from '~/components/spinner'
import ContextTypes from '~/lib/config/listings-context-types'

import {
  SpinnerSc,
  ToolTipTargetSc,
  SearchAutoCompleteOptionsSc,
  SearchAutoCompleteButtonSc,
  SearchAutoCompleteButtonLabelSc,
} from './search-auto-complete-options.style'

type Props = {
  context?: string | object
  isCurrent?: boolean
  isSurroundSearch?: boolean
  showSurrounding?: boolean
  onCurrentLocation?: (...args: any[]) => any
  onToggleSurrounding?: (...args: any[]) => any
  currentLocationMessage: ReactNode | string
  moreOptionsResults?: ReactNode
}

const SearchAutoCompleteOptions = ({
  context,
  isCurrent,
  isSurroundSearch = true,
  showSurrounding = true,
  onCurrentLocation,
  onToggleSurrounding = null,
  currentLocationMessage,
  moreOptionsResults,
}: Props) => {
  const toolTipTargetRef = useRef<HTMLButtonElement>(null)
  const surroundDisabled = context !== ContextTypes.Location

  const handleToggle = event => {
    onToggleSurrounding(!isSurroundSearch)
    event.preventDefault()
  }

  const icon =
    currentLocationMessage === 'spinner' ? (
      <SpinnerSc>
        <Spinner size={22} />
      </SpinnerSc>
    ) : (
      <Icon icon="current-location-right" wrapper={false} />
    )

  return (
    <>
      <MobileOnly>{moreOptionsResults}</MobileOnly>
      <SearchAutoCompleteOptionsSc>
        <SearchAutoCompleteButtonSc
          type="button"
          isActive={isCurrent}
          isInline={!showSurrounding}
          onClick={onCurrentLocation}
        >
          {!showSurrounding && icon}
          <SearchAutoCompleteButtonLabelSc>Current Location</SearchAutoCompleteButtonLabelSc>
          {showSurrounding && icon}
        </SearchAutoCompleteButtonSc>
        {showSurrounding && (
          <>
            <SearchAutoCompleteButtonSc
              type="button"
              isDisabled={surroundDisabled}
              disabled={surroundDisabled}
              onClick={handleToggle}
            >
              <ToggleWithLabel
                className="surroundSearch"
                checked={isSurroundSearch}
                rightAlign
                justified
                disabled={surroundDisabled}
              >
                <SearchAutoCompleteButtonLabelSc isDisabled={surroundDisabled}>
                  Surrounding Suburbs
                </SearchAutoCompleteButtonLabelSc>
              </ToggleWithLabel>
            </SearchAutoCompleteButtonSc>
            <ToolTipTargetSc ref={toolTipTargetRef} />
          </>
        )}
      </SearchAutoCompleteOptionsSc>
    </>
  )
}

export default SearchAutoCompleteOptions
