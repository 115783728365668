import { defaultLocationObject } from '~/lib/utils/location-helper'

type Options = Option[]

type Option = {
  title: string
  items: FooterLinkItem[]
}

export type FooterLinkItem = {
  label: string
  url: string
  id?: string
  new?: string // shows a "new" badge on the link
  canRoute?: boolean // tells the footer this can use our Router instead of link
}

const options: Options = [
  {
    title: 'Company',
    items: [
      { label: 'About Us', url: '/about-us', canRoute: true },
      { label: 'Press Releases', url: '/news/media-releases', canRoute: true },
      { label: 'In the Media', url: '/news/homely-in-the-media', canRoute: true },
      { label: 'Brand Guidelines', url: '/brand-guidelines', canRoute: true },
      { label: 'Homely Apps', url: '/app', canRoute: true },
      { label: 'Contact Homely', url: '/contact-us' },
    ],
  },
  {
    title: 'Search Homely',
    items: [
      {
        label: 'Buy Properties',
        url: `/for-sale/${defaultLocationObject.slug}/real-estate`,
        canRoute: true,
      },
      {
        label: 'Rentals',
        url: `/for-rent/${defaultLocationObject.slug}/real-estate`,
        canRoute: true,
      },
      {
        label: 'Sold Properties',
        url: `/sold-properties/${defaultLocationObject.slug}`,
        id: 'sold',
        canRoute: true,
      },
      { label: 'Find Agents', url: '/real-estate-agents', canRoute: true },
      { label: 'Real Estate Agencies', url: '/real-estate-agencies', canRoute: true },
      { label: 'Local Discussions', url: '/discussions', canRoute: true },
      { label: 'Neighbourhood Profiles', url: '/where-to-live', canRoute: true },
      { label: 'Property Guides', url: '/advice', canRoute: true },
      { label: 'Lifestyle Tips', url: '/lifestyle', canRoute: true },
      { label: 'Property News', url: '/news/property-news', canRoute: true },
      { label: 'Connect Your Utilities', url: '/connect', canRoute: true },
    ],
  },
  {
    title: 'For Agents',
    items: [
      { label: 'List on Homely', url: '/agent-centre/list-on-homely', canRoute: true },
      {
        label: 'Change Multiloader',
        url: '/agent-centre/list-on-homely#change-multiloader',
        canRoute: true,
      },
      { label: 'Advertise on Homely', url: '/agent-centre/homely-plus', canRoute: true },
      { label: 'Claim Agent Profile', url: '/claim-your-agent-profile' },
      { label: 'Guides & Tips for Agents', url: '/agent-advice', canRoute: true },
      {
        label: 'The Upshot Podcast',
        url: '/agent-advice/the-upshot-podcast',
        canRoute: true,
      },
      { label: 'Agent Help Centre', url: '/agent-help-centre' },
    ],
  },
]

export default options
