import { lazy } from 'react'

import { isFeatureEnabled, availableFeatures } from '~/lib/providers/utils/features'
import { useUser } from '~/lib/providers/user-context'
import type { PageTypes } from '~/lib/config/page-types'

import HamburgerButton from '~/components/hamburger-button'

import Logo from '../logo'
import Nav from '../nav'

import { HeaderSc, LogoContainerSc } from './header.style'

const GoogleOneTap = lazy(() => import('~/components/auth/components/google-one-tap'))

type Props = {
  active?: string
  isFixed?: boolean
  isFixedDesktop?: boolean
  pageType: PageTypes
  onMenuToggle?: (...args: any[]) => any
  onNavClick?: (...args: any[]) => any
}

const Header = ({
  active = '',
  isFixed = false,
  isFixedDesktop = false,
  pageType,
  onMenuToggle = null,
  onNavClick = null,
}: Props) => {
  const { user } = useUser()

  const showThirdPartyScripts = isFeatureEnabled(availableFeatures.thirdPartyScripts.key)

  return (
    <HeaderSc isFixed={isFixed} isFixedDesktop={isFixedDesktop} role="banner">
      <HamburgerButton onClick={onMenuToggle} />
      <LogoContainerSc>
        <Logo />
      </LogoContainerSc>
      <Nav active={active} pageType={pageType} onNavClick={onNavClick} />
      {/* {!user && showThirdPartyScripts && <GoogleOneTap />} */}
    </HeaderSc>
  )
}

export default Header
