import { get } from '~/lib/config/app-config'

export const floorPlanOptions = {
  fit: 'contain',
}

export const getCdnPath = path => {
  const config = get()
  const cdnUrl = config?.cdnUrl || ''
  const cacheKey = config && config.appVersion ? `?${config.appVersion}` : ''
  return `${cdnUrl}${path}${cacheKey}`
}

export const getStaticSrc = (imageName, bucket = 'images') => {
  return `https://s3-ap-southeast-2.amazonaws.com/homely.assets/${bucket}/${imageName}`
}
