import React, { ReactNode, RefObject, forwardRef, ElementType } from 'react';
import SolidButtonSc from './solid-button.style';

type Props = {
  children: ReactNode;
  as?: ElementType;
  type?: string;
  short?: boolean;
  shortest?: boolean;
  small?: boolean;
  smallest?: boolean;
  large?: boolean;
  tall?: boolean;
  bold?: boolean;
  fullWidth?: boolean;
  isGradient?: boolean;
  title?: string;
  disabled?: boolean;
  isBlue?: boolean;
  isGallery?: boolean;
  onClick?: (...args: any[]) => any;
};

const SolidButton = forwardRef<RefObject<typeof SolidButtonSc>, Props>(
  ({ children, ...props }, ref) => (
    <SolidButtonSc type="button" ref={ref} {...props}>
      {children}
    </SolidButtonSc>
  )
);

SolidButton.displayName = 'SolidButton';

export default SolidButton;
