import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const SkeletonLineSc = styled.div<{ width50: boolean; large: boolean }>`
  width: ${({ width50 }) => (width50 ? '50' : '100')}%;
  height: ${({ large }) => (large ? '40' : '25')}px;
  background-color: ${theme.colors.grayLight2};
  border-radius: ${({ large }) => (large ? theme.spacing[5] : theme.spacing[3])}px;
`

export default {
  SkeletonLineSc,
}
