import { styled } from '@linaria/react'

export const FeatureToggleEnvSc = styled.div`
  padding: 0 8px 8px;
  font-size: 11px;
  line-height: 24px;
  text-align: left;
  color: #7394a3;
`

FeatureToggleEnvSc.displayName = 'FeatureToggleEnvSc'

export default {
  FeatureToggleEnvSc,
}
