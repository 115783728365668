import React from 'react'
import { getUserAvatarUrl } from '~/lib/utils/image-helpers'
import { userImageSizes } from '~/lib/config/user-image-sizes'
import DefaultAvatar from './components/default-avatar'
import { UserAvatarSc } from './user-avatar.style'

const getUserAltText = user => {
  const name = user ? user.displayDescription || user.name || user.username : ''
  return user?.isAgent ? `${name} real estate agent` : name
}

type Props = {
  user?: any // User | ListingAgent | AgentSearchItems | AgentProfile | XWingUser;
  width?: number
  outline?: boolean
  useDefault?: boolean
  isAgent?: boolean
  loading?: 'lazy' | 'eager'
  opts?: any
}

const UserAvatar = ({
  user = null,
  width = userImageSizes.Standard.w,
  outline = true,
  useDefault = false,
  isAgent = false,
  loading = 'lazy',
  opts = {},
}: Props) => {
  const agent = user?.isAgent || isAgent
  const isDefault = !user || !user.avatar || user.avatar.isDefault || useDefault
  return agent || !isDefault ? (
    <UserAvatarSc
      src={getUserAvatarUrl(user, width, opts, useDefault)}
      style={{ width, height: width }}
      width={width}
      height={width}
      alt={getUserAltText(user)}
      isOutline={outline}
      loading={loading}
    />
  ) : (
    <DefaultAvatar user={user} width={width} outline={outline} />
  )
}

export default UserAvatar
