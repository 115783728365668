import React, { ReactNode, useState, useEffect } from 'react'

import { Listing } from '~/graphql/generated/graphql'
import { usePageInfo } from '~/lib/providers/pageInfo-context'
import { PageTypes } from '~/lib/config/page-types'
import { ListingTrackingTypes } from '~/lib/config/listing-tracking-types'
import { trackListings } from '~/lib/utils/listing-tracking'
import { trackEvent, eventTypes, contentTypes, GtmEvent } from '~/lib/utils/google-tag-manager'

import ModalTrigger from '~/components/modal-trigger'
import FlatButton from '~/components/buttons/flat-button'

import { getShareProps } from '../../share-modal/utils/helper'
import ShareModal from '../../share-modal'

type Props = {
  listing?: Listing
  gtmOptions?: GtmEvent
  label?: string
  children?: ReactNode
  url?: string
  title?: string
  text?: string
  shareType?: string
  showPrint?: boolean
  pageType: PageTypes
}

const ShareButton = ({
  listing = null,
  gtmOptions,
  label = 'Share',
  children,
  url = null,
  title = '',
  text = '',
  shareType = 'listing',
  showPrint = false,
  pageType,
}: Props) => {
  const { isDesktop } = usePageInfo()

  const [isNative, setIsNative] = useState(false)

  useEffect(() => {
    setIsNative(!isDesktop && !!navigator.share)
  }, [])

  const handleShare = () => {
    if (isNative) {
      navigator.share(getShareProps(listing, { url, title }))
    }
    trackEvent({
      event: eventTypes.openModal,
      contentType: contentTypes.sharing,
      ...gtmOptions,
    })
    if (listing?.id) {
      trackListings([listing.id], ListingTrackingTypes.SharedListing, pageType)
    }
  }

  const handleCopy = () => {
    if (listing?.id) {
      trackListings([listing.id], ListingTrackingTypes.SharedListing, pageType)
    }
  }

  const handleShareModalOpen = () => {
    trackEvent({
      event: eventTypes.openModal,
      contentType: contentTypes.sharing,
      ...gtmOptions,
    })
  }

  const button = (
    <FlatButton aria-label={label} title={label} onClick={handleShare}>
      {children}
    </FlatButton>
  )
  return !isNative ? (
    <ModalTrigger
      modal={
        <ShareModal
          listing={listing}
          url={url}
          title={title}
          text={text}
          header={shareType}
          showPrint={showPrint}
          gtmOptions={gtmOptions}
          pageType={pageType}
          onCopy={handleCopy}
        />
      }
      onShow={handleShareModalOpen}
    >
      {button}
    </ModalTrigger>
  ) : (
    button
  )
}

export default ShareButton
