import React from 'react'

import { getCdnPath } from '~/lib/utils/cdn-image'

type Props = {
  src: string
  isPNG?: boolean
  alt?: string
  width?: number
  height?: number
  loading?: 'lazy' | 'eager'
  isMobile?: boolean
  isDesktop?: boolean
}

const Picture = ({
  src,
  isPNG = false,
  alt,
  width,
  height,
  loading = 'lazy',
  isMobile = false,
  isDesktop = false,
}: Props) => {
  const srcWebp = getCdnPath(`/assets/images/${src}.webp`)
  const srcJpg = getCdnPath(`/assets/images/${src}.${isPNG ? 'png' : 'jpg'}`)

  return (
    <picture>
      {isDesktop && (
        <source
          srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          media="(max-width: 960px)"
        />
      )}
      {isMobile && (
        <source
          srcSet="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
          media="(min-width: 961px)"
        />
      )}
      <source srcSet={srcWebp} type="image/webp" />
      <source srcSet={srcJpg} type={`image/${isPNG ? 'png' : 'jpeg'}`} />
      <img
        src={srcJpg}
        alt={alt}
        width={width}
        height={height}
        loading={loading}
        fetchPriority={loading === 'eager' ? 'high' : 'low'}
      />
    </picture>
  )
}

export default Picture
