import React from 'react';

import HomeImage from './components/home-image';
import HomeImageReview from './components/home-image-review';

import { HomeImagesSc, HomeImageTrackSc, HomeImageColSc } from './home-images.style';

const images = [
  {
    uri: 'homely-glass-house-listing-backyard-setting-doors-open',
    alt: 'Backyard setting of Homely glass house listing with doors open',
  },
  {
    uri: 'homely-townhouse-listing-living-room-brown-furniture',
    alt: 'Inside living room of Homely townhouse listing with brown furniture',
  },
  {
    uri: 'homely-double-story-unit-listing-living-room-cream-and-red-furniture',
    alt: 'Inside living room of Homely double story unit listing with cream and red furniture',
  },
  {
    uri: 'homely-city-apartment-listing-living-room-grey-furniture',
    alt: 'Inside living room of Homely city apartment listing with grey furniture',
  },
  {
    uri: 'homely-split-level-home-listing-downstairs-living-room',
    alt: 'Inside living room of Homely split level home listing',
  },
  {
    uri: 'homely-cottage-listing-front-yard',
    alt: 'Front yard of Homely cottage listing',
  },
];

type Props = {
  isReviews?: boolean;
};

const HomeImages = ({ isReviews = false }: Props) => (
  <HomeImagesSc>
    <HomeImageTrackSc>
      {!isReviews ? (
        images.map(({ uri, alt }) => (
          <HomeImageColSc key={uri}>
            <HomeImage image={uri} alt={alt} />
          </HomeImageColSc>
        ))
      ) : (
        <>
          <HomeImageColSc>
            <HomeImageReview
              color="yellow"
              description="Exciting city life without the hefty price tag."
              name="RubyShoes"
              locationName="Parramatta, NSW"
              locationUri="parramatta-parramatta-sydney-greater-new-south-wales"
            />
          </HomeImageColSc>
          <HomeImageColSc>
            <HomeImage
              image="homely-review-of-parramatta-nsw-ice-cream-truck"
              alt="Ice cream truck on Homely’s review of a Parramatta, NSW"
            />
          </HomeImageColSc>
          <HomeImageColSc>
            <HomeImageReview
              color="purple"
              description="You can easily walk along the beach for hours and find yourself just taken by the beauty."
              name="susandj"
              locationName="Indented Head, VIC"
              locationUri="indented-head-greater-geelong-barwon-victoria"
            />
          </HomeImageColSc>
          <HomeImageColSc>
            <HomeImage
              image="homely-review-of-indented-head-vic-beach-view"
              alt="Beach view on Homely’s review of a Indented Head, VIC"
            />
          </HomeImageColSc>
          <HomeImageColSc>
            <HomeImageReview
              color="blue"
              description="We love the friendly locals and have a great choice of cafes, restaurants and wine bars."
              name="Sarah Thom"
              locationName="Yarraville, VIC"
              locationUri="yarraville-maribyrnong-melbourne-greater-victoria"
            />
          </HomeImageColSc>
          <HomeImageColSc>
            <HomeImage
              image="homely-review-of-yarraville-vic-outdoor-cafe"
              alt="Outdoor cafe view on Homely’s review of a Yarraville, VIC"
            />
          </HomeImageColSc>
        </>
      )}
    </HomeImageTrackSc>
  </HomeImagesSc>
);

export default HomeImages;
