import React, { ReactNode } from 'react';
import { VisuallyHiddenSc } from './visually-hidden.style';

type Props = {
  children: ReactNode;
};

const VisuallyHidden = ({ children }: Props) => <VisuallyHiddenSc>{children}</VisuallyHiddenSc>;

export default VisuallyHidden;
