import React from 'react';
import { ResultHeaderSc, ResultHeaderLabelSc } from './result-header.style';

const headerTypes = {
  locations: {
    icon: 'map',
    label: 'Locations',
  },
  recent: {
    icon: 'search-small',
    label: 'Recent searches',
  },
};

type Props = {
  field: string;
  isListingsModal?: boolean;
};

const SearchAutoCompleteResultHeader = ({ field, isListingsModal = false }: Props) => {
  const type = headerTypes[field];
  if (!type) {
    return null;
  }
  return (
    <ResultHeaderSc isListingsModal={isListingsModal}>
      <ResultHeaderLabelSc>{type.label}</ResultHeaderLabelSc>
    </ResultHeaderSc>
  );
};

SearchAutoCompleteResultHeader.headerTypes = headerTypes;

export default SearchAutoCompleteResultHeader;
