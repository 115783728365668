import { Listing } from '~/graphql/generated/graphql'

import { searchParamsModeTypes } from '~/lib/config/listing-search-mode-types'

export enum PageTypes {
  ForSaleListingDetails = 'for_sale_listing_details',
  ForRentListingDetails = 'for_rent_listing_details',
  SoldListingDetails = 'sold_listing_details',
  LeasedListingDetails = 'leased_listing_details',
  ForSaleListingSearch = 'for_sale_listing_search',
  ForRentListingSearch = 'for_rent_listing_search',
  SoldListingSearch = 'sold_listing_search',
  OfficeForSaleListingSearch = 'office_for_sale_listing_search',
  OfficeForRentListingSearch = 'office_for_rent_listing_search',
  OfficeSoldListingSearch = 'office_sold_listing_search',
  AgentFinder = 'agent_finder',
  AgentSearch = 'agent_search',
  AgentProfile = 'agent_profile',
  RegionInStateSearch = 'region_in_state_search',
  SuburbInRegionSearch = 'suburb_in_region_search',
  StreetInSuburbSearch = 'street_in_suburb_search',
  RegionLocationReviews = 'region_details',
  SuburbLocationReviews = 'suburb_details',
  StreetLocationReviews = 'street_details',
  RegionQuestionDetail = 'region_q&a_details',
  SuburbQuestionDetail = 'suburb_q&a_details',
  StreetQuestionDetail = 'street_q&a_details',
  RegionQuestionList = 'q&a_region_search',
  SuburbQuestionList = 'q&a_suburb_search',
  StreetQuestionList = 'q&a_street_search',
  QuestionsSearch = 'q&a_search',
  LocationSearch = 'location_search',
  Home = 'home',
  Alerts = 'all_alerts',
  AlertsUnsubscribe = 'alerts_unsubscribe',
  AlertDetails = 'alert_details',
  Collections = 'collections',
  CollectionDetails = 'collection_details',
  CollectionConfirmInvite = 'collection_confirm_invite',
  AboutUs = 'about_us',
  Awards = 'awards',
  BrandGuidelines = 'brand_guidelines',
  HomelyApp = 'homely_app',
  HomelyConnect = 'homely_connect',
  HomelyPlus = 'homely_plus',
  Hougarden = 'hougarden',
  InstantList = 'instant_list',
  PrivacyPolicy = 'privacy_policy',
  SoldPriceDisclaimer = 'sold_price_disclaimer',
  MovingPromotion = 'moving_promotion',
  ListOnHomely = 'list_on_homely',
  TermsOfUse = 'terms_of_use',
  TermsOfUseGuestAuthors = 'guest_authors_terms_of_use',
  TermsOfUseResidentialPropertyListings = 'residential_property_listings_terms_of_use',
  TermsOfUseSpring20k = 'spring_$20K_giveaway_terms_of_use',
  TermsOfUseSuburbReviewComp = 'suburb_review_comp_terms_of_use',
  Login = 'login',
  Signup = 'signup',
  UserProfile = 'user_profile',
  RequestVendorReview = 'vsr_agent_send_client_email_page',
  VendorReviews = 'vsr_agent_dashboard_page',
}

export const getListingSearchPageType = (mode: number, isOffice: boolean): PageTypes => {
  switch (mode) {
    case searchParamsModeTypes.buy:
      return isOffice ? PageTypes.OfficeForSaleListingSearch : PageTypes.ForSaleListingSearch
    case searchParamsModeTypes.rent:
      return isOffice ? PageTypes.OfficeForRentListingSearch : PageTypes.ForRentListingSearch
    case searchParamsModeTypes.sold:
      return isOffice ? PageTypes.OfficeSoldListingSearch : PageTypes.SoldListingSearch
    default:
      return PageTypes.ForSaleListingSearch
  }
}

export const getListingDetailsPageType = (listing: any): PageTypes => {
  let isSale = true
  if (listing) {
    const { listingType, statusType } = listing
    isSale = listingType === 'sale'
    if (statusType === 'completed') {
      return isSale ? PageTypes.SoldListingDetails : PageTypes.LeasedListingDetails
    }
  }
  return isSale ? PageTypes.ForSaleListingDetails : PageTypes.ForRentListingDetails
}

export const getLocationSearchPageType = (type: string): PageTypes => {
  switch (type?.toLowerCase()) {
    case 'region':
      return PageTypes.RegionInStateSearch
    case 'suburb':
      return PageTypes.SuburbInRegionSearch
    case 'street':
      return PageTypes.StreetInSuburbSearch
    default:
      return PageTypes.StreetInSuburbSearch
  }
}

export const getLocationReviewsPageType = (type: string): PageTypes => {
  switch (type?.toLowerCase()) {
    case 'region':
      return PageTypes.RegionLocationReviews
    case 'suburb':
      return PageTypes.SuburbLocationReviews
    case 'street':
      return PageTypes.StreetLocationReviews
    default:
      return PageTypes.StreetLocationReviews
  }
}

export const getQuestionDetailPageType = (type: string | null | undefined): PageTypes => {
  switch (type?.toLowerCase()) {
    case 'region':
      return PageTypes.RegionQuestionDetail
    case 'suburb':
      return PageTypes.SuburbQuestionDetail
    case 'street':
      return PageTypes.StreetQuestionDetail
    default:
      return PageTypes.StreetQuestionDetail
  }
}

export const getQuestionListPageType = (type: string | null | undefined): PageTypes => {
  switch (type?.toLowerCase()) {
    case 'region':
      return PageTypes.RegionQuestionList
    case 'suburb':
      return PageTypes.SuburbQuestionList
    case 'street':
      return PageTypes.StreetQuestionList
    default:
      return PageTypes.StreetQuestionList
  }
}

export default PageTypes
