import { useState } from 'react'

import { useLocationsState } from '~/lib/providers/locations-context'
import { routes } from '~/lib/config/routing'
import { types as listingSearchTypes, searchTypes } from '~/lib/config/listing-search-mode-types'
import type { PageTypes } from '~/lib/config/page-types'
import { getSearchUrl } from '~/lib/utils/listings-search-helper'

import { NavTypes } from '~lib/config/nav-types'
import NavDropdown from './components/nav-dropdown'
import NavDropdownList from './components/nav-dropdown-list'
import NavRight from './components/nav-right'
import NavLink from './components/nav-link'

import { NavSc, NavLeftSc } from './nav.style'
import { NavLinkSc } from './components/nav-link/nav-link.style'
import { useNavigate } from '@remix-run/react'

type Props = {
  active: string
  pageType: PageTypes
  onNavClick: (href: string, as?: string) => void
  onSelectMode?: (any) => void
}

const Nav = ({ active, pageType, onNavClick, onSelectMode }: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const { locations } = useLocationsState()
  const getListingSearchUrl = type => getSearchUrl(type, locations)
  const navigate = useNavigate()

  const handleNavClick = (e: Event, href: string, as?: string) => {
    if (onSelectMode) {
      if (as?.indexOf(searchTypes.saleFaceted) !== -1) {
        onSelectMode(listingSearchTypes.Buy)
      } else if (as?.indexOf(searchTypes.rentFaceted) !== -1) {
        onSelectMode(listingSearchTypes.Rent)
      } else if (as?.indexOf(searchTypes.sold) !== -1) {
        onSelectMode(listingSearchTypes.Sold)
      }
    }
    if (onNavClick) {
      onNavClick(href, as)
    } else {
      navigate(href)
    }
    e.preventDefault()
  }

  const handleMenuHide = () => {
    // Delay to avoid open/close conflict.
    setTimeout(() => {
      if (open) {
        setOpen(false)
      }
    }, 100)
  }
  return (
    <NavSc className="screen-only" data-topbar role="navigation">
      <NavLeftSc>
        <li>
          <NavLink
            href={routes.listingsSearch}
            as={getListingSearchUrl(searchTypes.sale)}
            title="Buy"
            isActive={active === NavTypes.Buy}
            isHeader
            onClick={handleNavClick}
          >
            Buy
          </NavLink>
        </li>
        <li>
          <NavLink
            href={routes.listingsSearch}
            as={getListingSearchUrl(searchTypes.rent)}
            title="Rent"
            isActive={active === NavTypes.Rent}
            isHeader
            onClick={handleNavClick}
          >
            Rent
          </NavLink>
        </li>
        <li>
          <NavLink
            href={routes.listingsSearch}
            as={getListingSearchUrl(searchTypes.sold)}
            title="Sold"
            isActive={active === NavTypes.Sold}
            isHeader
            onClick={handleNavClick}
          >
            Sold
          </NavLink>
        </li>
        <li>
          <NavLink
            href="/real-estate-agents"
            as="/real-estate-agents"
            title="Agent Finder"
            isActive={active === NavTypes.AgentFinder}
            isHeader
            prefetch={false}
            onClick={handleNavClick}
          >
            Agent Finder
          </NavLink>
        </li>
        <li>
          <NavLink
            href="/where-to-live"
            title="Suburb Reviews"
            isActive={active === NavTypes.Locations}
            isHeader
            prefetch={false}
            onClick={handleNavClick}
          >
            Suburb Reviews
          </NavLink>
        </li>
        <li>
          <NavLink
            href="/discussions"
            title="Questions"
            isActive={active === NavTypes.Questions}
            isHeader
            prefetch={false}
            onClick={handleNavClick}
          >
            Questions
          </NavLink>
        </li>
        <li>
          <NavLinkSc
            as="button"
            title="Blog"
            isActive={active === NavTypes.Blog}
            isHeader
            onClick={() => setOpen(!open)}
          >
            Blog
          </NavLinkSc>
          <NavDropdown isOpen={open} onHide={handleMenuHide}>
            <NavDropdownList>
              <li>
                <NavLink href="/advice" title="Guides">
                  Guides
                </NavLink>
              </li>
              <li>
                <NavLink href="/lifestyle" title="Lifestyle">
                  Lifestyle
                </NavLink>
              </li>
              <li>
                <NavLink href="/news" title="News">
                  News
                </NavLink>
              </li>
            </NavDropdownList>
          </NavDropdown>
        </li>
      </NavLeftSc>
      <NavRight active={active} pageType={pageType} onNavClick={handleNavClick} />
    </NavSc>
  )
}

export default Nav
