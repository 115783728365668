import React from 'react'
import { useFeatures } from '~/lib/providers/features-context'
import cookies from '~/lib/utils/cookies'
import EnvironmentVariables from './components/environment-variables'
import FeatureToggleItem from './components/feature-toggle-item'
import { FeatureToggleSc, FeatureToggleButtonSc } from './feature-toggle.style'

type Props = {
  onClose?: () => void
}

const FeatureToggle = ({ onClose }: Props) => {
  const { features, updateFeature } = useFeatures()

  const handleUpdate = name => {
    const isEnabled = !features[name].enabled
    if (isEnabled) {
      cookies().set(name, isEnabled)
    } else {
      cookies().remove(name)
    }
    updateFeature(name, isEnabled)
  }

  return (
    <FeatureToggleSc>
      {Object.keys(features).map(feature => (
        <FeatureToggleItem
          key={features[feature].key}
          name={feature}
          label={features[feature].label}
          isChecked={features[feature].enabled}
          onClick={() => {
            handleUpdate(feature)
          }}
        />
      ))}
      <FeatureToggleButtonSc type="button" title="Hide Feature" onClick={onClose}>
        Hide Features
      </FeatureToggleButtonSc>
      <EnvironmentVariables />
    </FeatureToggleSc>
  )
}

export default FeatureToggle
