import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const NavSc = styled.nav`
  display: flex;
  flex: 1;
`

export const NavLeftSc = styled.ul`
  display: none;
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    position: relative;
    float: left;
  }

  @media ${theme.breakpoints.desktop} {
    display: flex;
    align-items: center;
  }
`
