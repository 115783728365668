import React from 'react';

import Picture from '../../../picture';

import { HomeImageHolderSc } from './home-image.style';

type Props = {
  image: string;
  alt: string;
};

const HomeImage = ({ image, alt }: Props) => (
  <HomeImageHolderSc>
    <Picture src={`home/${image}`} width={356} height={345} alt={alt} loading="lazy" />
  </HomeImageHolderSc>
);

export default HomeImage;
