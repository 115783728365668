import React, { useState, ReactNode } from 'react';
import Icon from '../icon';
import Modal from '../modal';
import SearchAutoCompleteMulti from '../search-auto-complete-multi';
import SearchOptions from './components/search-options';
import {
  SearchFilterLocationModalSc,
  SearchFilterLocationModalTriggerButtonSc,
  SearchFilterLocationModalCancelButtonSc,
} from './search-auto-complete-modal.style';

type Props = {
  locations: object[];
  locationsLimit: number;
  active: string;
  children: ReactNode;
  showSurrounding?: boolean;
  isSurroundSearch?: boolean;
  context: string;
  label?: string;
  onToggleSurrounding?: (...args: any[]) => any;
  onSelectLocations: (...args: any[]) => any;
  onSelectMode: (...args: any[]) => any;
  onSearchButton: (...args: any[]) => any;
};

const SearchFilterLocationModal = ({
  locations,
  locationsLimit,
  active,
  children,
  showSurrounding = false,
  isSurroundSearch = true,
  context = null,
  label = null,
  onToggleSurrounding = null,
  onSelectLocations,
  onSelectMode,
  onSearchButton,
}: Props) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const handleSelectMode = (item) => onSelectMode(item);
  const handleSelectLocations = (list, selected) => {
    onSelectLocations(list, selected);
  };
  const handleSearchButton = () => {
    onSearchButton();
    handleHide();
  };
  const moreOptions = <SearchOptions active={active} onSelect={handleSelectMode} />;
  return (
    <SearchFilterLocationModalSc>
      <SearchFilterLocationModalTriggerButtonSc
        role="button"
        aria-label={label}
        onClick={handleShow}
      >
        {children}
      </SearchFilterLocationModalTriggerButtonSc>
      <Modal show={show} isFull isAnimated={false} showClose={false} onHide={handleHide}>
        <>
          <SearchAutoCompleteMulti
            locations={locations}
            locationsLimit={locationsLimit}
            isOpen
            autoFocus
            alwaysFocus
            showRecent
            alwaysShowRecent
            showClear
            isSmall
            isListings
            isListingsModal
            showSurrounding={showSurrounding}
            isSurroundSearch={isSurroundSearch}
            context={context}
            onToggleSurrounding={onToggleSurrounding}
            minSearchQueryLength={1}
            moreOptionsResults={moreOptions}
            onSelectResult={handleSelectLocations}
            onSearchButton={handleSearchButton}
            onClose={handleHide}
          />
          <SearchFilterLocationModalCancelButtonSc type="button" onClick={handleHide}>
            <Icon icon="back-popup" />
          </SearchFilterLocationModalCancelButtonSc>
        </>
      </Modal>
    </SearchFilterLocationModalSc>
  );
};

export default SearchFilterLocationModal;
