'use client'
import React, { useEffect } from 'react'

import { usePrevious } from '~lib/hooks/usePrevious'
import { searchTypes } from '~/lib/config/listing-search-mode-types'
import { PageTypes } from '~/lib/config/page-types'
import { ListingTrackingTypes } from '~/lib/config/listing-tracking-types'
import { routes } from '~/lib/config/routing'
import { getSearchUrl } from '~/lib/utils/listings-search-helper'
import { trackListings } from '~/lib/utils/listing-tracking'
import { contentTypes, eventTypes, trackEvent } from '~/lib/utils/google-tag-manager'

import ListingsScrollList from '~/components/listings-scroll-list'
import Icon from '~/components/icon'

import ContentLink from '../content-link'

import { HomeListingsSc, HomeListingsLinkSc } from './home-listings.style'

type Props = {
  list?: any[]
  location?: any
  pageType: PageTypes
}

const HomeListings = ({ list = [], location = null, pageType }: Props) => {
  const prevListings = usePrevious(list) || {}
  // Listings updated, perform tracking and force check lazy images.
  useEffect(() => {
    // Compare the first listing id to assume (!) that we have a new set of listings
    if (prevListings?.[0]?.id !== list?.[0]?.id) {
      const listingIds = list.map(listing => listing.id)
      if (listingIds.length > 0) {
        trackListings(listingIds, ListingTrackingTypes.CardView, pageType)
        trackListings(listingIds, ListingTrackingTypes.PhotoView, pageType)
      }
    }
  }, [list])
  const handleClick = () =>
    trackEvent({
      event: eventTypes.selectContent,
      contentType: contentTypes.listingSearchLink,
      pageElementPosition: 'listings_sect',
      locationId: location?.id,
    })
  return (
    <>
      <HomeListingsSc>
        <ListingsScrollList
          listings={list}
          emptyMessage="No new properties found"
          showBoost
          showLabels={false}
          pageType={pageType}
        />
      </HomeListingsSc>
      <HomeListingsLinkSc>
        {location && (
          <ContentLink
            href={routes.listingsSearch}
            as={getSearchUrl(searchTypes.sale, [location])}
            onClick={handleClick}
          >
            <span>{`Search ${location.name} properties for sale`}</span>
            <Icon icon="forward-popup" />
          </ContentLink>
        )}
      </HomeListingsLinkSc>
    </>
  )
}

export default HomeListings
