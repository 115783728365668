import React, { ReactNode } from 'react';

import { HeaderSc } from './header.style';

type Props = {
  isShort?: boolean;
  children: ReactNode;
};

const HomeHeader = ({ isShort = false, children }: Props) => (
  <HeaderSc isShort={isShort}>
    {children}
  </HeaderSc>
);

export default HomeHeader;
