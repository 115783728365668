import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const HomeSearchSc = styled.div`
  padding: 26px 20px 40px;
  width: 100%;
  background: ${theme.colors.grayLight};

  @media ${theme.breakpoints.desktop} {
    padding: 42px 32px 58px;
    border-radius: 24px;
  }
`

export const HomeSearchHolderSc = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 920px;

  position: relative;
  height: 62px;

  @media ${theme.breakpoints.desktop} {
    height: 70px;
  }
`

export const HomeSearchModalHolderSc = styled.div`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
  /* Disables SearchAutoComplete events to open modal  */
  & > div {
    pointer-events: none;
  }
`

export const HomeSearchAutoCompleteSc = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  width: 100%;
  border-radius: 35px;
  background-color: ${theme.colors.whiteBase};

  /* Open */
  box-shadow: ${({ isOpen }) => (isOpen ? '0 5px 8px 0 rgba(0, 0, 0, 0.2)' : '0 6px 5px 0 rgba(0, 0, 0, 0.05)')};

  & li:last-of-type button:first-child {
    border-radius: 0 0 40px 40px;
  }
`
