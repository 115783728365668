import { styled } from '@linaria/react'

export const SearchFilterButtonSc = styled.button<{
  isFlex: boolean
  isDropdown: boolean
  isLast: boolean
  isMapListView: boolean
  isMap: boolean
  isActive: boolean
  isDisabled: boolean
  primary: boolean
}>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 18px;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 13px;
  line-height: 34px;
  text-align: center;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.grayDark};
  background-color: ${({ theme }) => theme.colors.whiteBase};
  border: 1px solid ${({ theme }) => theme.colors.grayLight2};
  border-radius: 20px;
  cursor: pointer;
  -webkit-touch-callout: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  & svg {
    margin-right: 2px;
  }

  min-width: auto;
  @media ${({ theme }) => theme.breakpoints.desktop} {
    min-width: 52px;
  }

  ${({ isFlex }) =>
    isFlex &&
    `
    flex: 1;
  `}

  /* Map/List toggle button */
  ${({ isMapListView }) =>
    isMapListView &&
    `
    padding: 0;
  `}

  /* On map */
  ${({ isMap, theme }) =>
    isMap &&
    `
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.35);
    border-color: ${theme.colors.whiteBase};
  `}

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${({ theme }) => theme.colors.redBase};
      background-color: ${({ theme }) => theme.colors.whiteBase};
      border-color: ${({ theme }) => theme.colors.redBase};
    }
  }

  ${({ isActive, theme }) =>
    isActive &&
    `
    color: ${theme.colors.redBase};
    background-color: ${theme.colors.button.hoverRed};
    border-color: ${theme.colors.redBase};

    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &:active {
        color: ${theme.colors.redBase};
        background-color: ${theme.colors.button.hoverRed};
        border-color: ${theme.colors.redBase};
      }
    }
  `}

  /* Disabled */
  ${({ isDisabled, theme }) =>
    isDisabled &&
    `
    color: ${theme.colors.grayLight2};
    border-color: #F2F2F2;
    cursor: default;

    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &:active {
        color: ${theme.colors.grayLight2};
        border-color: #F2F2F2;
      }
    }
  `}
`

export const SearchFilterButtonArrowSc = styled.span<{ isOpen }>`
  position: relative;
  display: inline-block;
  margin-left: 4px;
  width: 10px;
  height: 10px;
  line-height: 10px;

  .icon {
    position: absolute;
    top: 1px;
    left: 0;
    width: 10px;
    height: 10px;
    line-height: 10px;
    transform: rotate(${({ isOpen }) => (isOpen ? '270' : '90')}deg);
  }
`
