import { canUseDOM } from '~/lib/utils/can-use-dom'
import _startsWith from 'lodash/startsWith'
import { userImageSizes } from '~/lib/config/user-image-sizes'
import { buildCloudinaryOptions, getImageSrc, imageDomain, imagePathPrefix } from './cloudinary'

export const getUserAvatarOptions = (user, width, opts) => {
  const size = width > userImageSizes.Standard.w ? userImageSizes.Large : userImageSizes.Standard
  return { g: 'face', ...size, ...opts }
}

export const getUserAvatarUrl = (
  user,
  width = 100,
  opts = {},
  useDefault = false,
  domain = imageDomain,
  pathPrefix = imagePathPrefix,
) => {
  if (!user || !user.avatar || user.avatar.isDefault || useDefault) {
    return getImageSrc('user-default')
  }
  const cloudinary = getUserAvatarOptions(user, width, opts)
  const { avatar } = user
  return getImageSrc(avatar.identifier, avatar.version, { cloudinary }, domain, pathPrefix)
}

export const formatAbsoluteUrl = path => {
  const protocol = canUseDOM ? document.location.protocol : 'https:'
  return _startsWith(path, 'http') ? path : `${protocol}${path}`
}

export const encodeCloudinarySafeText = (textToEncode: string = '') => {
  return encodeURIComponent(textToEncode.replace(/(,|\/)/g, ' ∙'))
}

export const getAgentProfileShareImageUrl = (
  agent,
  shareTextLine1 = '',
  shareTextLine2 = '',
  ctaText = 'view my profile',
) => {
  const defaultWidth = 1200
  const defaultHeight = 628
  const agentWidth = 360 // 440;
  const agentMarginX = 100 // 46;
  // const agentMarginY = 70;
  const bgVersion = 'v1636087476'
  // const bgImageUri = 'agent-review-bg.png';
  const bgImageUri = 'homely-agent-card2.jpg'

  // Background template base image
  const baseImageUri = buildCloudinaryOptions({
    w: defaultWidth,
    h: defaultHeight,
  })

  // Overlay Office logo
  const officeLogo = agent?.office?.logo?.identifier
    ? `/${buildCloudinaryOptions({
        l: agent.office.logo.identifier,
        w: agentWidth,
        h: 90,
        g: 'south_west',
        x: agentMarginX,
        y: 70,
        c: 'pad',
        b: 'auto', // agent.office?.color || 'auto',
      })}`
    : ''

  // Overlay agent avatar
  const agentAvatar =
    agent?.avatar?.identifier && !agent.avatar.isDefault
      ? `/${buildCloudinaryOptions({
          l: agent.avatar.identifier,
          w: agentWidth,
          h: agentWidth,
          g: 'north_west',
          c: 'fill', // crop to fill
          r: 'max', // round corners to form a circle
          x: agentMarginX,
          y: 150,
        })}`
      : ''

  // Overlay text
  const textOptions = {
    c: 'fit',
    co: 'white',
    g: 'north_east',
    w: 600,
  }
  const textUri1 =
    shareTextLine1.length > 0
      ? `/${buildCloudinaryOptions({
          l: `text:arial_86_center:${encodeCloudinarySafeText(shareTextLine1)}`,
          ...textOptions,
          h: 100,
          x: 130,
          y: 110,
          co: 'gold',
        })}`
      : ''
  const textUri2 =
    shareTextLine2.length > 0
      ? `/${buildCloudinaryOptions({
          l: `text:arial_56_center:${encodeCloudinarySafeText(shareTextLine2)}`,
          ...textOptions,
          h: 300,
          x: 40,
          y: 210,
        })}`
      : ''
  const textCta =
    ctaText.length > 0
      ? `/${buildCloudinaryOptions({
          l: `text:arial_32_center:${encodeCloudinarySafeText(ctaText)}`,
          ...textOptions,
          co: 'black',
          g: 'south_east',
          h: 300,
          x: 220,
          y: 100,
        })}`
      : ''

  const imageUri = `${imageDomain}${imagePathPrefix}/${baseImageUri}${agentAvatar}${officeLogo}${textUri1}${textUri2}${textCta}/${bgVersion}/${bgImageUri}`
  return formatAbsoluteUrl(imageUri)
}

export default { getUserAvatarUrl, formatAbsoluteUrl }
