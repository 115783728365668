import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const HeaderSc = styled.div<{ isShort: boolean }>`
  margin: 0 0 48px;

  @media ${theme.breakpoints.desktop} {
    margin: 0 0 ${({ isShort }) => (!isShort ? '72' : '32')}px;
  }
`
