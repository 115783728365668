import React, { HTMLProps } from 'react';
import { capitalize } from '~lib/transforms/capitalize';
import { getPlatformUrl } from '../../lib/utils/social-links';
import { getCdnPath } from '../../lib/utils/cdn-image';
import Icon from '../icon';
import { SocialLinkSc } from './social-link.style';

type Props = {
  platform: string;
  url: string;
  title?: string;
  nofollow?: boolean;
  isSmall?: boolean;
  imageIcons?: boolean;
  onClick?: (...args: any[]) => any;
} & HTMLProps<HTMLLinkElement>;

const SocialLink = ({
  platform,
  url,
  title = null,
  nofollow = false,
  isSmall = false,
  imageIcons = false,
  onClick = null,
}: Props) => {
  const handleClick = () => {
    if (onClick) onClick(platform);
  };
  const alt = capitalize(title || platform);
  const icon = platform === 'email' ? 'contact' : platform;
  return (
    <SocialLinkSc
      href={getPlatformUrl(url, platform)}
      title={platform === 'linkedin-boxed' ? 'Linkedin' : alt}
      nofollow={nofollow}
      onClick={handleClick}
    >
      {imageIcons ? (
        <img src={getCdnPath(`/assets/images/icons/${icon}.svg`)} alt={alt} />
      ) : (
        <Icon icon={icon} isSmall={isSmall} wrapper={false} />
      )}
    </SocialLinkSc>
  );
};

export default SocialLink;
