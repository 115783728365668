import React from 'react'

import { getCdnPath } from '~/lib/utils/cdn-image'

import {
  HomeImageReviewSc,
  HomeImageReviewLogoSc,
  HomeImageReviewDescriptionSc,
  HomeImageFooterSc,
  HomeImageLocationSc,
} from './home-image-review.style'

type Props = {
  color: string
  description: string
  name: string
  locationName: string
  locationUri: string
}

const HomeImageReview = ({ color, description, name, locationName, locationUri }: Props) => (
  <HomeImageReviewSc
    as="blockquote"
    cite={`https://www.homely.com.au/${locationUri}`}
    bgColor={color}
  >
    <HomeImageReviewLogoSc>
      <img
        src={getCdnPath('/assets/images/homely-logo-icon.svg')}
        alt="Homely logo"
        width={30}
        height={32}
        loading="lazy"
      />
    </HomeImageReviewLogoSc>
    <HomeImageReviewDescriptionSc>{`“${description}”`}</HomeImageReviewDescriptionSc>
    <HomeImageFooterSc>
      <span>{`- ${name}, `}</span>
      <HomeImageLocationSc>{locationName}</HomeImageLocationSc>
    </HomeImageFooterSc>
  </HomeImageReviewSc>
)

export default HomeImageReview
