import React, { ReactNode } from 'react';
import { NavDropdownListSc } from './nav-dropdown-list.style';

interface NavDropdownListProps {
  children: ReactNode;
}

const NavDropdownList = ({ children }: NavDropdownListProps) => (
  <NavDropdownListSc>{children}</NavDropdownListSc>
);

export default NavDropdownList;
