import { styled } from '@linaria/react'
import theme from '~/styles/theme'

// Variations of SearchAutoCompleteMulti.
// * Home
//   - isHero
// * Listings Search
//   - isListingsModal

export const SearchAutoCompleteSc = styled.div<{ isListingsModal: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  /* Listings Search - Modal */
  height: ${({ isListingsModal }) => (isListingsModal ? '100%' : 'auto')};
  overflow-y: ${({ isListingsModal }) => (isListingsModal ? 'auto' : 'inherit')};
`

export const SearchFormSc = styled.form<{ isListingsModal: boolean }>`
  // display: inline-flex;
  flex: 1;
  flex-shrink: 1;

  /* Mobile but not Listings Seach Modal */
  display: ${({ isListingsModal }) => (!isListingsModal ? 'none' : 'inline-flex')};
  @media ${theme.breakpoints.desktop} {
    display: inline-flex;
  }
`

export const SearchInputSc = styled.input<{ isListingsModal: boolean; isOpen: boolean }>`
  display: inline-block;
  padding: 4px 0 4px 8px;
  width: 100%;
  line-height: 24px;
  color: ${theme.colors.grayBase};
  background: ${theme.colors.whiteBase};
  border: 0;
  -webkit-appearance: none;

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  &:focus {
    outline: 0;
  }

  /* Listings Search - Modal */
  // 16px minimum to prevent iOS zooming page on focus.
  font-size: ${({ isListingsModal }) => (isListingsModal ? '16px' : '14px')};

  /* Open */
  min-width: ${({ isOpen }) => (isOpen ? '240px' : 'auto')};
`

export const SearchMoreOptionsSearchSc = styled.div`
  display: flex;
`

export const SearchMoreOptionsResultsSc = styled.div`
  align-items: center;
`

export const SearchResultsSc = styled.div`
  &:not(:empty) {
    border-top: 1px solid ${theme.colors.border2};
  }
`

const getPadding = (isListingsModal, isHero, isInline) => {
  if (isListingsModal) return '0'
  if (isHero || isInline) return '8px'
  return '7px'
}

const getMargin = (isListingsModal, isHero, isInline) => {
  if (isListingsModal) return '12px 12px 12px 40px'
  if (isHero || isInline) return '0'
  return '0'
}

const getMarginDesktop = (isListingsModal, isHero, isInline) => {
  if (isListingsModal) return '12px 12px 12px 40px'
  if (isHero || isInline) return '0'
  return '16px 38px'
}

const getBorder = (isListingsModal, isHero, isInline, isMap) => {
  if (isListingsModal || isHero || isInline) return '0'
  if (isMap) return `1px solid ${theme.colors.whiteBase}`
  return `1px solid ${theme.colors.grayLight2}`
}

const getBorderRadius = (isListingsModal, isHero, isInline) => {
  if (isListingsModal || isHero || isInline) return '0'
  return '27px'
}

const getBackground = isHero => {
  if (isHero) return 'none'
  return theme.colors.whiteBase
}

export const SearchBarSc = styled.div<{
  isListingsModal: boolean
  isHero: boolean
  isMap: boolean
  isInline: boolean
}>`
  display: flex;
  padding: ${({ isListingsModal, isHero, isInline }) => getPadding(isListingsModal, isHero, isInline)};
  margin: ${({ isListingsModal, isHero, isInline }) => getMargin(isListingsModal, isHero, isInline)};
  background: ${({ isHero }) => getBackground(isHero)};
  border: ${({ isListingsModal, isHero, isInline }) => getBorder(isListingsModal, isHero, isInline)};
  border-radius: ${({ isListingsModal, isHero, isInline }) => getBorderRadius(isListingsModal, isHero, isInline)};

  /* Search Results */
  @media ${theme.breakpoints.desktop} {
    margin: ${({ isListingsModal, isHero, isInline }) => getMarginDesktop(isListingsModal, isHero, isInline)};
  }

  padding-top: ${({ isListingsModal, isHero, isInline }) =>
    isListingsModal ? 'env(safe-area-inset-top)' : getPadding(isListingsModal, isHero, isInline)};

  box-shadow: ${({ isMap }) => (isMap ? '0 2px 6px 0 rgba(0, 0, 0, 0.35)' : 'inherit')};
`

export const SearchBoxSc = styled.div<{
  isListingsModal: boolean
  isHero: boolean
  isOpen: boolean
}>`
  display: flex;
  align-items: ${({ isOpen, isHero }) => (isOpen || isHero ? 'flex-start' : 'center')};
  flex: 1;
  min-width: 0;

  cursor: ${({ isOpen }) => (isOpen ? 'text' : 'pointer')};
  -webkit-tap-highlight-color: transparent;

  /* Hero */
  // padding: 0;

  /* Listings Search - Modal */
  max-height: ${({ isListingsModal }) => (isListingsModal ? '89px' : 'auto')};
  overflow-y: ${({ isListingsModal }) => (isListingsModal ? 'auto' : 'inherit')};
`

const getMarginLeft = (isHero, isDesktop) => {
  if (isHero && isDesktop) return '24px'
  if (isHero) return '0'
  return '2px'
}

const getPaddingTop = (isHero, isDesktop) => {
  if (isHero && isDesktop) return '9px'
  if (isHero) return '5px'
  return '0'
}

const getHeight = (isHero, isOpen, isDesktop) => {
  if (isHero && !isOpen && isDesktop) return '50px'
  if (isHero && !isOpen) return '42px'
  return 'auto'
}

export const SearchTagListSc = styled.div<{
  isListingsModal: boolean
  isHero: boolean
  isOpen: boolean
}>`
  display: flex;
  flex-wrap: ${({ isOpen }) => (isOpen ? 'wrap' : 'nowrap')};
  flex: 1;
  gap: 8px;
  min-width: 0;
  margin: 2px;

  margin-left: ${({ isHero }) => getMarginLeft(isHero, false)};
  @media ${theme.breakpoints.desktop} {
    margin-left: ${({ isHero }) => getMarginLeft(isHero, true)};
  }

  padding-top: ${({ isHero }) => getPaddingTop(isHero, false)};
  padding-bottom: ${({ isHero }) => getPaddingTop(isHero, false)};
  @media ${theme.breakpoints.desktop} {
    padding-top: ${({ isHero }) => getPaddingTop(isHero, true)};
    padding-bottom: ${({ isHero }) => getPaddingTop(isHero, true)};
  }

  height: ${({ isHero, isOpen }) => getHeight(isHero, isOpen, false)};
  @media ${theme.breakpoints.desktop} {
    height: ${({ isHero, isOpen }) => getHeight(isHero, isOpen, true)};
  }
`

export const SearchOptionsSc = styled.div`
  display: none;

  @media ${theme.breakpoints.desktop} {
    display: flex;
  }
`

const getMarginHero = (isHero, isDesktop) => {
  if (isHero && isDesktop) return '9px'
  if (isHero) return '5px'
  return '0'
}

export const SearchClearSc = styled.button<{ isHero: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 8px;
  padding: 0;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 0;
  background: none;
  border-radius: 18px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${theme.colors.grayLight};
    }
  }

  &:focus {
    background-color: ${theme.colors.grayLight};
  }

  /* Hero */
  margin-top: ${({ isHero }) => getMarginHero(isHero, false)};
  @media ${theme.breakpoints.desktop} {
    margin-top: ${({ isHero }) => getMarginHero(isHero, true)};
  }
`

export const SearchClearLargeSc = styled.button`
  display: inline-block;
  margin-top: 4px;
  width: 42px;
  height: 44px;
  background: none;
  border: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;

  .icon-wrapper {
    margin-left: 2px;
    width: 24px;
    height: 24px;
  }
`

const getPaddingButton = (showButtonText, isHero) => {
  if (showButtonText && isHero) return '0 40px'
  if (showButtonText && !isHero) return '0 20px'
  return '0'
}

const getWidthButton = showButtonText => {
  if (showButtonText) return 'auto'
  return '36px'
}

const getHeightButton = (showButtonText, isHero) => {
  if (showButtonText && isHero) return '54px'
  return '36px'
}

const getFontWeight = (showButtonText, isHero) => {
  if (showButtonText && isHero) return theme.fontWeights.bold
  if (showButtonText && !isHero) return theme.fontWeights.medium
  return theme.fontWeights.regular
}

const getFontSize = (showButtonText, isHero) => {
  if (showButtonText && isHero) return '15px'
  if (showButtonText && !isHero) return '13px'
  return 'inherit'
}

const getBorderRadiusButton = (showButtonText, isHero) => {
  if (showButtonText && isHero) return '26px'
  if (showButtonText && !isHero) return '27px'
  return '18px'
}

export const SearchButtonSc = styled.button<{ showButtonText?: boolean; isHero?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: ${({ showButtonText, isHero }) => getPaddingButton(showButtonText, isHero)};
  width: ${({ showButtonText }) => getWidthButton(showButtonText)};
  height: ${({ showButtonText, isHero }) => getHeightButton(showButtonText, isHero)};
  color: ${theme.colors.whiteBase};
  stroke: ${theme.colors.whiteBase};
  background: ${theme.colors.redBase};
  border: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: ${theme.colors.button.redBaseHover};
    }
  }

  & > svg {
    margin-right: 4px;
  }

  font-weight: ${({ showButtonText, isHero }) => getFontWeight(showButtonText, isHero)};
  font-size: ${({ showButtonText, isHero }) => getFontSize(showButtonText, isHero)};
  border-radius: ${({ showButtonText, isHero }) => getBorderRadiusButton(showButtonText, isHero)};
`

const getMarginIcon = (isHero, isDesktop) => {
  if (isHero && isDesktop) return '15px 12px 0 20px'
  if (isHero) return '12px 4px 0 10px'
  return '0 3px 0 5px'
}

export const SearchIconSc = styled.div<{ isHero: boolean }>`
  display: flex;
  color: ${theme.colors.grayDark};

  margin: ${({ isHero }) => getMarginIcon(isHero, false)};
  @media ${theme.breakpoints.desktop} {
    margin: ${({ isHero }) => getMarginIcon(isHero, true)};
  }
`

const getMarginRight = (isListingsModal, isHero, isDesktop) => {
  if (isHero && !isDesktop) return '16px'
  if (!isListingsModal && !isDesktop) return '8px'
  return '0'
}

export const SearchMoreSc = styled.span<{
  isListingsModal: boolean
  isHero: boolean
}>`
  display: inline-flex;
  flex-shrink: 0;
  font-size: 13px;
  line-height: 32px;
  color: ${theme.colors.bodyText};
  user-select: none;
  pointer-events: none;

  margin-right: ${({ isListingsModal, isHero }) => getMarginRight(isListingsModal, isHero, false)};
  @media ${theme.breakpoints.desktop} {
    margin-right: ${({ isListingsModal, isHero }) => getMarginRight(isListingsModal, isHero, true)};
  }
`

export const SearchOptionsSeperatorSc = styled.span`
  width: 1px;
  background: ${theme.colors.border2};
  margin: 0 8px;

  @media ${theme.breakpoints.desktop} {
    margin: 0 24px;
  }
`

export const SearchMoreOptionsButtonSc = styled.div`
  padding-left: 4px;

  & svg {
    margin-right: 0 !important;
  }
`

export const SearchPlaceholderSc = styled.div`
  pointer-events: none;
  line-height: 32px;

  @media ${theme.breakpoints.desktop} {
    line-height: inherit;
  }
`
