import { DesktopOnly, MobileOnly } from '~/components/responsive'

import Column from '../column'
import HomeSearch from '../home-search'
import Picture from '../picture'

import {
  HomeHeroSectionSc,
  HomeHeroBackgroundSc,
  HomeHeroSc,
  HomeHeroHeaderSc,
  HomeHeroHeaderColourSc,
  HomeHeroSubHeaderSc,
  NoWrapSc,
  MobileImageSc,
} from './home-hero.style'

const HomeHero = () => (
  <HomeHeroSectionSc>
    <DesktopOnly>
      <HomeHeroBackgroundSc>
        <Picture
          src="home/home-hero-desktop-4@2x"
          alt=""
          width={729}
          height={474}
          loading="eager"
          isDesktop
        />
      </HomeHeroBackgroundSc>
    </DesktopOnly>
    <Column>
      <HomeHeroSc>
        <HomeHeroHeaderSc>
          <HomeHeroHeaderColourSc>{'Australian real estate search, '}</HomeHeroHeaderColourSc>
          suburb reviews and local Q&A
        </HomeHeroHeaderSc>
        <HomeHeroSubHeaderSc>
          {'Find hundreds of thousands of properties for sale and rent. '}
          <NoWrapSc>New homes listed daily.</NoWrapSc>
        </HomeHeroSubHeaderSc>
      </HomeHeroSc>
      <DesktopOnly>
        <HomeSearch isDesktop />
      </DesktopOnly>
    </Column>
    <MobileOnly>
      <HomeSearch />
      <MobileImageSc>
        <Picture
          src="home/home-hero-mobile-4@2x"
          alt=""
          width={714}
          height={333}
          loading="eager"
          isMobile
        />
      </MobileImageSc>
    </MobileOnly>
  </HomeHeroSectionSc>
)

export default HomeHero
