import React, { PureComponent } from 'react';

type Props = {
  modal: any;
  disabled?: boolean;
  children?: any | any[];
  onShow?: (...args: any[]) => any;
  onHide?: (...args: any[]) => any;
};

type State = {
  show: boolean;
};

class ModalTrigger extends PureComponent<Props, State> {
  static defaultProps: Props = {
    modal: null,
    disabled: false,
    children: null,
    onShow: null,
    onHide: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
    this.close = this.close.bind(this);
    this.toggle = this.toggle.bind(this);
  }

  close(e) {
    const { disabled, onHide } = this.props;
    if (!disabled) {
      if (onHide) onHide(e);
      this.setState({ show: false });
    }
  }

  open(e) {
    const { disabled, onShow } = this.props;
    if (!disabled) {
      if (onShow) onShow(e);
      this.setState({ show: true });
    }
  }

  toggle(e) {
    const { show } = this.state;
    if (show) {
      this.close(e);
    } else {
      this.open(e);
    }
  }

  renderModal() {
    const { modal } = this.props;
    const { show } = this.state;
    if (!modal || modal.type === 'div' || modal.type === 'span') {
      return modal;
    }
    return React.cloneElement(modal, {
      show,
      onHide: this.close,
    });
  }

  renderChildren() {
    const { children } = this.props;
    return React.Children.map(children, (child) =>
      React.cloneElement(child, { onClick: this.toggle })
    );
  }

  render() {
    return (
      <>
        {this.renderModal()}
        {this.renderChildren()}
      </>
    );
  }
}

export default ModalTrigger;
