import { json, type LoaderFunction, type MetaFunction } from '@remix-run/cloudflare'

import HomePage from '~/modules/home/home'

export const meta: MetaFunction = () => {
  return [
    { title: 'Homely - Real Estate for Sale & Rent, Suburb & Street Reviews, Local Q&A' },
    {
      name: 'description',
      content:
        'Find real estate for sale and rent Australia-wide, plus suburb and street reviews with local Q&A. Try the Homely property search experience.',
    },
    {
      canonical: 'https://www.homely.com.au',
    },
  ]
}

export const loader: LoaderFunction = async () => {
  try {
    const response = await fetch('https://dev-api.homely.com.au/v1/listings')
    if (!response.ok) {
      throw new Error(`Failed to fetch listings: ${response.status} ${response.statusText}`)
    }
    const data = await response.json()
    return json({ listingsData: data.results })
  } catch (error) {
    console.error('Error in @home loader:', error)
    return json({ error: 'Failed to fetch listings' }, { status: 500 })
  }
}

export default function Index() {
  return <HomePage />
}
