import React from 'react'

import { Link } from '@remix-run/react'

import { ContentLinkSc } from './content-link.style'

type Props = {
  href: string
  as?: string
  children: React.ReactNode
  onClick?: ((...args: any[]) => any) | undefined
}

const HomeContentLink = ({ href, as = '', children, onClick }: Props) => (
  <Link to={as} prefetch={false}>
    <ContentLinkSc onClick={onClick}>{children}</ContentLinkSc>
  </Link>
)

export default HomeContentLink
