import _get from 'lodash/get'
import _indexOf from 'lodash/indexOf'

import communicationTypes from '~/lib/config/communications-types'
import userTypes from '~/lib/config/user-types'

export default {
  isAgent(user) {
    return _get(user, 'type') === userTypes.Agent
  },

  hasClaim(user, memberClaim, claim) {
    const userClaims = _get(user, `claims.${memberClaim.area}`) || []
    return _indexOf(userClaims, claim) > -1
  },

  getEmail(user) {
    if (!user || !user.communications || !user.communications.length) {
      return ''
    }
    let email
    user.communications.forEach(comm => {
      if (comm.communicationType === communicationTypes.Email) {
        email = comm.details
      }
    })
    return email || ''
  },

  isPhone(communicationType: number): boolean {
    return (
      communicationType === communicationTypes.Landline ||
      communicationType === communicationTypes.Mobile
    )
  },

  getPhone(user) {
    if (!user || !user.communications || !user.communications.length) {
      return ''
    }
    let phone
    user.communications.forEach(comm => {
      if (comm.communicationType === communicationTypes.Mobile) {
        phone = comm.details
      } else if (!phone && comm.communicationType === communicationTypes.Landline) {
        phone = comm.details
      }
    })
    return phone || ''
  },
}
