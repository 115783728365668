export const userImageSizes = {
  Standard: {
    w: 76,
    h: 76,
  },
  Large: {
    w: 200,
    h: 200,
  },
};

export default userImageSizes;
