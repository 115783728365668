import React from 'react';

import { TitleSc } from './title.style';

type Props = {
  children: React.ReactNode;
};

const HomeTitle = ({ children }: Props) => <TitleSc>{children}</TitleSc>;

export default HomeTitle;
