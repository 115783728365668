import React from 'react'
import { userImageSizes } from '~/lib/config/user-image-sizes'
import { getInitials } from '~lib/utils/helpers'
import { DefaultAvatarSc } from './default-avatar.style'

type Props = {
  user?: any // User | ListingAgent | AgentSearchItems | AgentProfile | XWingUser;
  width?: number
  outline?: boolean
}

const DefaultAvatar = ({
  user = null,
  width = userImageSizes.Standard.w,
  outline = true,
}: Props) => {
  const name = user ? user.name || user.username || '' : ''
  const text = getInitials(name)
  return (
    <DefaultAvatarSc title={name} text={text} $width={width} isOutline={outline}>
      {text}
    </DefaultAvatarSc>
  )
}

export default DefaultAvatar
