import VisuallyHidden from '~/components/visually-hidden'
import { LogoSc, LogoLinkSc, LogoImageSc } from './logo.style'
import { Link } from '@remix-run/react'

type Props = {
  width?: number
  height?: number
}

const Logo = ({ width = 128, height = 29 }: Props) => (
  <LogoSc>
    <Link to="/">
      <LogoLinkSc>
        <LogoImageSc id="homely-logo" width={width} height={height}>
          <use xlinkHref="#icon-logo" />
        </LogoImageSc>
        <VisuallyHidden>Homely</VisuallyHidden>
      </LogoLinkSc>
    </Link>
  </LogoSc>
)

export default Logo
