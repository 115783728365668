import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const DiscoverBadgeHolderSc = styled.span`
  display: inline-block;

  @media ${theme.breakpoints.desktop} {
    margin: -4px 0 0 6px;
  }
`

export const DiscoverBadgeSc = styled.span`
  display: inline-block;
  padding: 0 6px;
  font-size: 13px;
  line-height: 23px;
  color: ${theme.colors.whiteBase};
  background: ${theme.colors.redBase};
  border-radius: 7px;
  vertical-align: middle;
`
