import React, { useState } from 'react'
import { Link } from '@remix-run/react'

import { useAuth } from '~/lib/providers/auth-context'
import memberClaims from '~/lib/config/member-claims'
import userTypes from '~/lib/config/user-types'
import { PageTypes } from '~/lib/config/page-types'
import userUtil from '~/lib/utils/user'
import isProduction from '~/lib/utils/is-production'

import ShareButton from '~/components/buttons/share-button'

import FeatureToggle from '../feature-toggle'
import NavDropdownList from '../nav-dropdown-list'

type Props = {
  user: any
  pageType: PageTypes
  onSignOut?: () => void
}

const NavAuthMenu = ({ user, pageType, onSignOut }: Props) => {
  const [showFeatures, setShowFeatures] = useState(false)
  const { signOut } = useAuth()

  const toggleShowFeatures = () => {
    setShowFeatures(!showFeatures)
  }

  const signOutAction = e => {
    e.preventDefault()
    signOut(user)
    if (onSignOut) {
      onSignOut()
    }
  }

  const renderFeatureToggleLink = () => {
    if (
      user &&
      userUtil.hasClaim(user, memberClaims.apps, memberClaims.apps.claims.canToggleFeatures)
    ) {
      const label = showFeatures ? 'Hide Features' : 'Show Features'
      return (
        <li>
          <button type="button" onClick={toggleShowFeatures}>
            {label}
          </button>
        </li>
      )
    }
    return null
  }

  const renderAdminLinks = () => {
    // Ensure admin users go to admin.homely rather than www.homely
    const adminLink = route => `${isProduction ? 'https://admin.homely.com.au' : ''}${route}`
    if (
      user &&
      userUtil.hasClaim(user, memberClaims.listings, memberClaims.listings.claims.canManageAny)
    ) {
      return (
        <>
          <li key="offices">
            <a href={adminLink('/admin/agency')}>Offices</a>
          </li>
          <li key="users">
            <a href={adminLink('/admin/user')}>Users</a>
          </li>
          <li key="listings">
            <a href={adminLink('/admin/listings')}>Listings</a>
          </li>
          <li key="logging">
            <a href={adminLink('/admin/listinglogging')}>Listing Logging</a>
          </li>
          <li key="dashboard">
            <a href={adminLink('/dashboard/dashboard')}>Dashboard</a>
          </li>
        </>
      )
    }
    return null
  }

  return (
    <div>
      <NavDropdownList>
        <li>
          <ShareButton
            shareType="Homely content"
            title="Check out what I found on Homely.com.au"
            pageType={pageType}
          >
            Share...
          </ShareButton>
        </li>
        <li>
          <Link to={`/${user.type === userTypes.Agent ? 'agent' : 'user'}/${user.username}`}>
            Profile
          </Link>
        </li>
        <li>
          <a href="/settings">Settings</a>
        </li>
        {renderAdminLinks()}
        {renderFeatureToggleLink()}
        <li>
          <a href="/user/signout" onClick={signOutAction}>
            Sign Out
          </a>
        </li>
      </NavDropdownList>
      {showFeatures && <FeatureToggle onClose={toggleShowFeatures} />}
    </div>
  )
}

export default NavAuthMenu
