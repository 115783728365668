import { styled } from '@linaria/react'
import theme from '~/styles/theme'

const TitleBreakSc = styled.span<{ inlineMobile: boolean; inlineDesktop: boolean }>`
  @media screen {
    display: ${({ inlineMobile }) => (inlineMobile ? 'inline' : 'block')};
  }

  @media ${theme.breakpoints.desktop} {
    @media screen {
      display: ${({ inlineDesktop }) => (inlineDesktop ? 'inline' : 'block')};
    }
  }
`

export default TitleBreakSc
