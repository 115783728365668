import React, { ReactNode } from 'react'
import { Link } from '@remix-run/react'
import { NavLinkSc } from './nav-link.style'

interface NavLinkProps {
  href: string
  as?: string
  title?: string
  isActive?: boolean
  isHeader?: boolean
  prefetch?: boolean
  children?: ReactNode
  onClick?: (e: Event, href: string, as: string) => void // TODO
}

const NavLink = ({
  as,
  title,
  isActive,
  isHeader,
  children,
  onClick,
  href = null,
}: NavLinkProps) => {
  const handleClick = e => (onClick ? onClick(e, href, as) : null)

  return (
    <Link to={as}>
      <NavLinkSc
        title={title}
        aria-label={title}
        aria-current={isActive ? 'page' : null}
        isActive={isActive}
        isHeader={isHeader}
        onClick={handleClick}
      >
        {children}
      </NavLinkSc>
    </Link>
  )
}

export default NavLink
