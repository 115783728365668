import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const CircledTextSc = styled.div<{
  isPill: boolean
  isNotification: boolean
}>`
  display: inline-block;
  height: 20px;
  min-width: 20px;
  line-height: 15px;
  font-size: 11px;
  font-weight: ${theme.fontWeights.bold};
  border-radius: 11px;
  padding: 1.5px 5px;

  text-align: center;
  color: ${theme.colors.whiteBase};
  background-color: ${({ isNotification }) =>
    isNotification ? theme.colors.notification : theme.colors.redBase};
  border: 1px solid
    ${({ isNotification }) => (isNotification ? theme.colors.whiteBase : theme.colors.redBase)};

  @media ${theme.breakpoints.desktop} {
    border-width: 1.5px;
    height: 23px;
    min-width: 23px;
    line-height: 18px;
    font-size: 12px;
    border-radius: 11.5px;
    padding: 1px 6px;
  }

  /* Pill? */
  width: ${({ isPill }) => (isPill ? 'auto' : 'inherit')};
`
