import { lazy } from 'react'
import { usePageInfo } from '~/lib/providers/pageInfo-context'

const ShareModal = lazy(() => import('./share-modal'))
const ShareBottomSheet = lazy(() => import('./share-bottom-sheet'))

const ShareLoader = props => {
  const { isDesktop } = usePageInfo()
  return isDesktop ? <ShareModal {...props} /> : <ShareBottomSheet {...props} />
}

export default ShareLoader
