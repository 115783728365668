import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const NavDropdownSc = styled.div<{ isOpen: boolean; isRight: boolean }>`
  position: absolute;
  top: 59px;
  left: ${({ isRight }) => (!isRight ? 0 : 'auto')};
  right: ${({ isRight }) => (isRight ? '8px' : 'auto')};
  padding: 8px 20px;
  width: 200px;
  background-color: ${theme.colors.whiteBase};
  box-shadow: 0 2px 7px 3px rgba(138, 151, 157, 0.2);
  border: 1px solid ${theme.colors.grayLight2};
  border-radius: 8px;
  z-index: 1000;
  -webkit-touch-callout: none;
  user-select: none;

  /* Open */
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`
