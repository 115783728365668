import React from 'react'

import { getCdnPath } from '~/lib/utils/cdn-image'

import Icon from '~/components/icon'
import ContentLink from '../../../content-link'

import {
  BetterDecisionSc,
  BetterDecisionIconSc,
  BetterDecisionTitleSc,
  BetterDecisionDescriptionSc,
} from './better-decision.style'

type Props = {
  icon: string
  iconAlt: string
  title: string
  description: string
  linkText: string
  linkHref: string
  linkTo: string
  pageElementPosition: string
  onClick: (...args: any[]) => any
}

const BetterDecision = ({
  icon,
  iconAlt,
  title,
  description,
  linkText,
  linkHref,
  linkTo,
  pageElementPosition,
  onClick,
}: Props) => {
  const handleClick = () => onClick(pageElementPosition)

  return (
    <BetterDecisionSc>
      <BetterDecisionIconSc
        src={getCdnPath(`/assets/images/home/${icon}.svg`)}
        width={54}
        height={46}
        alt={iconAlt}
        loading="lazy"
      />
      <BetterDecisionTitleSc>{title}</BetterDecisionTitleSc>
      <BetterDecisionDescriptionSc>{description}</BetterDecisionDescriptionSc>
      <ContentLink href={linkHref} as={linkTo} onClick={handleClick}>
        <span>{linkText}</span>
        <Icon icon="forward-popup" />
      </ContentLink>
    </BetterDecisionSc>
  )
}

export default BetterDecision
