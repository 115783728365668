import React from 'react';
import Icon from '../icon';
import { HamburgerButtonSc } from './hamburger-button.style';

type Props = {
  onClick: (...args: any[]) => any;
};

const HamburgerButton = ({ onClick }: Props) => (
  <HamburgerButtonSc type="button" className="screen-only" aria-label="Open Menu" onClick={onClick}>
    <Icon icon="menu" wrapper={false} />
  </HamburgerButtonSc>
);

export default HamburgerButton;
