import { styled } from '@linaria/react';

const getSize = (isSmall, isTiny) => {
  if (isTiny) return '20px';
  if (isSmall) return '24px';
  return '32px';
};

// TODO: Fix all .icon & .icon-wrapper references...
export const IconWrapperSc = styled.span<{ isSmall: boolean; isTiny: boolean }>`
  overflow: hidden; // to prevent strange button :focus appearance
  display: inline-block;
  vertical-align: middle;
  margin-top: -4px;
  width: ${({ isSmall, isTiny }) => getSize(isSmall, isTiny)};
  height: ${({ isSmall, isTiny }) => getSize(isSmall, isTiny)};
  line-height: ${({ isSmall, isTiny }) => getSize(isSmall, isTiny)};
`;

export const IconSc = styled.svg<{ isSmall: boolean; isTiny: boolean; rotate: number }>`
  display: inline-block;
  fill: currentColor;
  pointer-events: none;
  width: ${({ isSmall, isTiny }) => getSize(isSmall, isTiny)};
  height: ${({ isSmall, isTiny }) => getSize(isSmall, isTiny)};

  /* Rotate */
  transform: ${({ rotate }) => rotate !== 0 ? `rotate(${rotate}deg)` : 'none'};
`;
