export type AdType = {
  adSenseId: string;
  // zoneId: string;
  // unitName: string;
};

export const AdTypes = {
  BannerAboveHeader: {
    adSenseId: '4895720956',
    // zoneId: '23144887905',
    // unitName: 'header',
  },
  HomeAdBottom: {
    adSenseId: '2105809345',
    // zoneId: '23145879146',
    // unitName: 'home_footer',
  },
  HomeAdAfterLatestProperties: {
    adSenseId: '8260818808',
    // zoneId: '23145879143',
    // unitName: 'home_incontent_1',
  },
  HomeAdBeforeDiscoverAndLearn: {
    adSenseId: '3092252602',
    // zoneId: '23145251083',
    // unitName: 'home_incontent_2',
  },
  ListingSearchAdBelowPagination: {
    adSenseId: '6656342148',
    // zoneId: '23145251101',
    // unitName: 'results_footer',
  },
  ListingSearchAdIncontent1: {
    adSenseId: '1889134180',
    // zoneId: '',
    // unitName: 'listing_search_incontent_1',
  },
  ListingSearchAdIncontent2: {
    adSenseId: '5298232286',
    // zoneId: '',
    // unitName: 'listing_search_incontent_2',
  },
  ListingSearchAdIncontent3: {
    adSenseId: '4994694542',
    // zoneId: '',
    // unitName: 'listing_search_incontent_3',
  },
  ListingSearchAdIncontent4: {
    adSenseId: '5222210666',
    // zoneId: '',
    // unitName: 'listing_search_incontent_4',
  },
  ListingSearchAdStickyFooter: {
    adSenseId: '6699156471',
    // zoneId: '',
    // unitName: 'results_sticky_footer',
  },
  ListingSearchAdEnquiryModalDesktop: {
    adSenseId: '9107695950',
    // zoneId: '',
    // unitName: 'listing_search_enquiry_modal_sidebar_desktop',
  },
  ListingSearchAdEnquiryModalMobile: {
    adSenseId: '8341409194',
    // zoneId: '',
    // unitName: 'listing_search_enquiry_modal_footer_mobile',
  },
  ListingDetailsVerticalGalleryBottom: {
    adSenseId: '6263493464',
    // zoneId: '',
    // unitName: 'listing_details_vertical_gallery_sticky_footer',
  },
  ListingDetailsVerticalGallerySidebarsLeft: {
    adSenseId: '9301869820',
    // zoneId: '23145251089',
    // unitName: 'verticalgallery_sidebar_vrec_lhs',
  },
  ListingDetailsVerticalGallerySidebarsRight: {
    adSenseId: '6674192043',
    // zoneId: '23144899077',
    // unitName: 'verticalgallery_sidebar_vrec_rhs',
  },
  ListingDetailsFullscreenGalleryAfterPhotos: {
    adSenseId: '6621133410',
    // zoneId: '',
    // unitName: 'listing_details_fullscreen_gallery_after_photos',
  },
  ListingDetailsFullscreenGalleryCarousel: {
    adSenseId: '3816495421',
    // zoneId: '23149239183',
    // unitName: 'carouselgallery_incontent_1',
  },
  ListingDetailsFullscreenGalleryMobileBottom: {
    adSenseId: '3209990762',
    // zoneId: '23145879164',
    // unitName: 'carouselgallery_mob',
  },
  ListingDetailsFullscreenGalleryDesktopSidebars: {
    adSenseId: '7512445274',
    // zoneId: '23145251095',
    // unitName: 'carouselgallery_sidebar_vrec',
  },
  ListingDetailsAfterInspectionButton: {
    adSenseId: '8690894999',
    // zoneId: '',
    // unitName: 'listing_details_after_inspection_btn',
  },
  ListingDetailsMapModalDesktop: {
    adSenseId: '8461361521',
    // zoneId: '',
    // unitName: 'listing_details_map_modal_sidebar_desktop',
  },
  ListingDetailsMapModalMobile: {
    adSenseId: '6074871331',
    // zoneId: '',
    // unitName: 'listing_details_map_modal_footer_mobile',
  },
  ListingDetailsAfterAgentCards: {
    adSenseId: '8133075341',
    // zoneId: '23144899074',
    // unitName: 'listingdetails_incontent_1',
  },
  ListingDetailsAdBottomOfPage: {
    adSenseId: '2338068977',
    // zoneId: '23145879158',
    // unitName: 'listingdetails_footer',
  },
  ListingDetailsAfterSchools: {
    adSenseId: '4875809157',
    // zoneId: '23145879161',
    // unitName: 'listingdetails_incontent_2',
  },
  ListingDetailsAdAboveDiscussions: {
    adSenseId: '5554764413',
    // zoneId: '23145879155',
    // unitName: 'listingdetails_incontent_3',
  },
  ListingDetailsAfterDiscussions: {
    adSenseId: '3231554999',
    // zoneId: '23144899080',
    // unitName: 'listingdetails_incontent_4',
  },
  ListingDetailsAdSidebar: {
    adSenseId: '5123768624',
    // zoneId: '23145251092',
    // unitName: 'listingdetails_sidebar_mrec',
  },
  ListingDetailsAdStickyFooter: {
    adSenseId: '8865606073',
    // zoneId: '',
    // unitName: 'listing_details_sticky_footer',
  },
  ListingDetailsAdStickySideLeft: {
    adSenseId: '1372676169',
    // zoneId: '',
    // unitName: 'listing_details_sticky_scroller_left_desktop',
  },
  ListingDetailsAdStickySideRight: {
    adSenseId: '8646862659',
    // zoneId: '',
    // unitName: 'listing_details_sticky_scroller_right_desktop',
  },
  ListingDetailsAdEnquiryModalDesktop: {
    adSenseId: '3855369275',
    // zoneId: '',
    // unitName: 'listing_details_enquiry_modal_sidebar_desktop',
  },
  ListingDetailsAdEnquiryModalMobile: {
    adSenseId: '2183194953',
    // zoneId: '',
    // unitName: 'listing_details_enquiry_modal_footer_mobile',
  },
  AgentOfficeFinderBottom: {
    adSenseId: '8321023393',
    // zoneId: '23145879128',
    // unitName: 'agentoffice_footer',
  },
  AgentOfficeFinderUnderGetEverythingYouNeed: {
    adSenseId: '8233146062',
    // zoneId: '23145251074',
    // unitName: 'agentoffice_incontent_1',
  },
  AgentProfilesBasicBottom: {
    adSenseId: '3423919936',
    // zoneId: '23144899065',
    // unitName: 'agentprofile_footer',
  },
  AgentProfileAdEnquiryModalDesktop: {
    adSenseId: '5551594321',
    // zoneId: '',
    // unitName: 'agent_profile_enquiry_modal_sidebar_desktop',
  },
  AgentProfileAdEnquiryModalMobile: {
    adSenseId: '8337539763',
    // zoneId: '',
    // unitName: 'agent_profile_enquiry_modal_footer_mobile',
  },
  AgentLocationSearchAfterEvery5Results: {
    adSenseId: '2302409953',
    // zoneId: '23145879125',
    // unitName: 'agentsearch_incontent_1',
  },
  OfficeLocationSearchBottom: {
    adSenseId: '5283016260',
    // zoneId: '23145879185',
    // unitName: 'officesearch_footer',
  },
  LocationSearchBestLocationsAfterEvery3Cards: {
    adSenseId: '9017412996',
    // zoneId: '23145879179',
    // unitName: 'locationsearch_incontent_1',
  },
  LocationDetailsAfterEvery3rdReview: {
    adSenseId: '4165910179',
    // zoneId: '23145879173',
    // unitName: 'location_details_after_3_reviews_infinite',
  },
  LocationDetailsIncontent1: {
    adSenseId: '2126320799',
    // zoneId: '',
    // unitName: 'location_details_incontent_1',
  },
  LocationDetailsIncontent2: {
    adSenseId: '5095848461',
    // zoneId: '',
    // unitName: 'location_details_incontent_2',
  },
  LocationDetailsIncontent3: {
    adSenseId: '4560912440',
    // zoneId: '',
    // unitName: 'location_details_incontent_3',
  },
  LocationDetailsIncontent4: {
    adSenseId: '1540165697',
    // zoneId: '',
    // unitName: 'location_details_incontent_4',
  },
  LocationDetailsIncontent5: {
    adSenseId: '6995504099',
    // zoneId: '',
    // unitName: 'location_details_incontent_5',
  },
  LocationDetailsIncontent6: {
    adSenseId: '4369340753',
    // zoneId: '',
    // unitName: 'location_details_incontent_6',
  },
  QAndASearchIncontent1: {
    adSenseId: '6442004165',
    // zoneId: '',
    // unitName: 'qna_search_incontent_1',
  },
  QAndASearchIncontent2: {
    adSenseId: '9870947435',
    // zoneId: '',
    // unitName: 'qna_search_incontent_2',
  },
  QAndASearchIncontent3: {
    adSenseId: '5291535379',
    // zoneId: '',
    // unitName: 'qna_search_incontent_3',
  },
  QAndASearchIncontent4: {
    adSenseId: '3305539087',
    // zoneId: '',
    // unitName: 'qna_search_incontent_4',
  },
  QAndADetailsAfterEvery3rdAnswer: {
    adSenseId: '5838084492',
    // zoneId: '',
    // unitName: 'qna_details_after_3_answers_infinite',
  },
  QAndADetailsIncontent1: {
    adSenseId: '4934984797',
    // zoneId: '',
    // unitName: 'qna_details_incontent_1',
  },
  QAndADetailsIncontent2: {
    adSenseId: '4123796738',
    // zoneId: '',
    // unitName: 'qna_details_incontent_2',
  },
  QAndADetailsIncontent3: {
    adSenseId: '5053735021',
    // zoneId: '',
    // unitName: 'qna_details_incontent_3',
  },
  QAndADetailsIncontent4: {
    adSenseId: '2427571686',
    // zoneId: '',
    // unitName: 'qna_details_incontent_4',
  },
  UGCAboveHeader: {
    adSenseId: '4732069427',
    // zoneId: '',
    // unitName: 'all_ugc_pages_above_h1',
  },
  UGCStickySideLeft: {
    adSenseId: '8545423323',
    // zoneId: '',
    // unitName: 'all_ugc_pages_sticky_scroller_left_desktop',
  },
  UGCStickySideRight: {
    adSenseId: '5969362889',
    // zoneId: '',
    // unitName: 'all_ugc_pages_sticky_scroller_right_desktop',
  },
  UGCFooter: {
    adSenseId: '2643576335',
    // zoneId: '',
    // unitName: 'all_ugc_pages_footer',
  },
  UGCStickyFooter: {
    adSenseId: '9770875089',
    // zoneId: '',
    // unitName: 'all_ugc_pages_sticky_footer_mobiler',
  },
  BlogMainCatBottomOfPage: {
    adSenseId: '4600075935',
    // zoneId: '23145251080',
    // unitName: 'blog_footer',
  },
  BlogMainCatAfterEverySubcatSection: {
    adSenseId: '5343506266',
    // zoneId: '23145251077',
    // unitName: 'blog_incontent_1',
  },
  BlogMainCatAfterEverySubcatSection2: {
    adSenseId: '5343506266',
    // zoneId: '23145879137',
    // unitName: 'blog_incontent_2',
  },
  BlogArticleAdBelowTopSearchBanner: {
    adSenseId: '1706186221',
    // zoneId: '23144899068',
    // unitName: 'blogarticle_incontent_1',
  },
  BlogArticleAdBelowBottomSearchBanner: {
    adSenseId: '5837002926',
    // zoneId: '23145879131',
    // unitName: 'blogarticle_incontent_2',
  },
  BlogSubcatAfterEvery4Cards: {
    adSenseId: '9091179586',
    // zoneId: '23144899071',
    // unitName: 'blogcategory_incontent_1',
  },
  BlogStickyFooter: {
    adSenseId: '2673115708',
    // zoneId: '',
    // unitName: 'blog_sticky_footer',
  },
}
