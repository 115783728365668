import { styled } from '@linaria/react'
import theme from '~/styles/theme'

import baseButtonStyle from '../utils/base-button-style'

const getSize = (small, tiny) => {
  if (tiny) return 14
  if (small) return 16
  return 17
}

const getLineHeight = (small, tiny) => {
  if (tiny) return 24
  if (small) return 28
  return 30
}

export const FlatButtonSc = styled.button<{
  primary: boolean
  small: boolean
  tiny: boolean
  bold: boolean
}>`
  ${baseButtonStyle}

  font-size: ${({ small, tiny }) => getSize(small, tiny)}px;
  line-height: ${({ small, tiny }) => getLineHeight(small, tiny)}px;

  /* Bold */
  font-weight: ${({ bold }) => (bold ? theme.fontWeights.medium : 'inherit')};

  /* Primary */
  color: ${({ primary }) => (primary ? theme.colors.redBase : 'inherit')};
`
