import React, { useState } from 'react'

import { useUser } from '~/lib/providers/user-context'
import { useAlertsState } from '~/lib/providers/alerts-context'
import { PageTypes } from '~/lib/config/page-types'

import UnreadCountBadge from '~/components/unread-count-badge'
import UserAvatar from '~/components/user-avatar'
import Icon from '~/components/icon'

import NavAuthDropdown from '../nav-auth-dropdown'

import {
  NavAuthLinkSc,
  NavAuthLinkButtonSc,
  NavAuthDropdownSc,
  BadgeHolderSc,
} from './nav-auth-link.style'

type Props = {
  active?: string
  isAlt?: boolean
  pageType: PageTypes
  onNavClick?: (...args: any[]) => any
  onAuthClick?: (...args: any[]) => any
}

const NavAuthLink = ({
  active = '',
  isAlt = false,
  pageType,
  onNavClick = null,
  onAuthClick = null,
}: Props) => {
  const { user } = useUser()
  const { unreadCount } = useAlertsState()
  const [open, setOpen] = useState(false)

  const handleToggle = () => (user ? setOpen(!open) : onAuthClick())
  const handleHide = () => {
    // Delay to avoid nav-auth-link open/close conflict.
    setTimeout(() => {
      if (open) {
        setOpen(false)
      }
    }, 100)
  }

  const handleAuth = () => {
    onAuthClick()
    handleHide()
  }

  return (
    <NavAuthLinkSc isAlt={isAlt}>
      <NavAuthLinkButtonSc
        type="button"
        aria-label={user ? 'Profile' : 'Log in'}
        onClick={handleToggle}
      >
        {user && <UserAvatar user={user} width={40} />}
        {!user && <Icon icon="profile" wrapper={false} />}
        <BadgeHolderSc>
          <UnreadCountBadge count={unreadCount} />
        </BadgeHolderSc>
      </NavAuthLinkButtonSc>
      <NavAuthDropdownSc open={open}>
        <NavAuthDropdown
          user={user}
          active={active}
          isOpen
          pageType={pageType}
          onNavClick={onNavClick}
          onAuthClick={handleAuth}
          onHide={handleHide}
        />
      </NavAuthDropdownSc>
    </NavAuthLinkSc>
  )
}

export default NavAuthLink
