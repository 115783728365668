import React from 'react'
import Icon from '~/components/icon'
import { SearchTagSc, SearchTagLabelSc, SearchTagRemoveSc } from './search-tag.style'

type Props = {
  label: string
  location?: object
  onRemove?: (...args: any[]) => any
}

const SearchTag = ({ label, location = null, onRemove = null }: Props) => {
  const handleClick = () => onRemove(location)
  return (
    <SearchTagSc>
      <SearchTagLabelSc>{label}</SearchTagLabelSc>
      {onRemove && (
        <SearchTagRemoveSc
          type="button"
          className="remove"
          title="Remove"
          aria-label={`Remove ${label}`}
          onClick={handleClick}
        >
          <Icon icon="remove" wrapper={false} />
        </SearchTagRemoveSc>
      )}
    </SearchTagSc>
  )
}

export default SearchTag
