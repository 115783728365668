import { useLoaderData } from '@remix-run/react'
import HomeListings from '../../components/home-listings'
import HomeListingsSkeleton from '../../components/home-listings-skeleton'

type Props = {
  location?: any
  pageType: PageTypes
}

const HomeListingsLoader = ({ location = null, pageType }: Props) => {
  const data = useLoaderData()

  if (!data.listingsData && data.error) {
    return (
      <>
        <HomeListingsSkeleton />
        <div>There was a problem loading new properties.</div>
      </>
    )
  }

  return <HomeListings list={data.listingsData || []} location={location} pageType={pageType} />
}

export default HomeListingsLoader
