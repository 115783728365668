import { lazy } from 'react'

import { isFeatureEnabled, availableFeatures } from '~/lib/providers/utils/features'
import type { AdType } from '~/lib/config/ad-types'

const AdSenseAd = lazy(() => import('./components/adsense'))

type Props = {
  type: AdType
  id?: string
  width?: string // force dimensions
  height?: string // force dimensions
  format?: 'auto' | 'fluid' | 'rectangle' | 'horizontal' | 'vertical' // rectangle, horizontal, vertical are desktop only
}

const Ad = ({ type, width = '100%', height = 'auto', format = 'auto' }: Props) => {
  const showThirdPartyScripts = isFeatureEnabled(availableFeatures.thirdPartyScripts.key)

  return showThirdPartyScripts ? (
    <AdSenseAd type={type} width={width} height={height} format={format} />
  ) : null
}

export default Ad
