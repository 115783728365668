import React, { ReactNode } from 'react'

import Icon from '~/components/icon'

import { SearchFilterButtonSc, SearchFilterButtonArrowSc } from './search-filter-button.style'

type Props = {
  isActive?: boolean
  isDisabled?: boolean
  isFlex?: boolean
  isOpen?: boolean
  isDropdown?: boolean
  isLast?: boolean
  isMap?: boolean
  isMapListView?: boolean
  primary?: boolean
  label?: string
  children: ReactNode | ReactNode[]
  onClick?: (...args: any[]) => any
  onKeyDown?: (...args: any[]) => any
}

const SearchFilterButton = ({
  isActive = false,
  isDisabled = false,
  isFlex = false,
  isLast = false,
  isOpen = false,
  isDropdown = false,
  isMap = false,
  isMapListView = false,
  primary = false,
  label = '',
  children = null,
  onClick = null,
  onKeyDown = null,
}: Props): JSX.Element => (
  <SearchFilterButtonSc
    type="button"
    aria-label={label}
    isActive={isActive}
    isDisabled={isDisabled}
    isFlex={isFlex}
    isMap={isMap}
    isMapListView={isMapListView}
    isLast={isLast}
    isDropdown={isDropdown}
    primary={primary}
    onClick={onClick}
    onKeyDown={onKeyDown}
  >
    {children}
    {isDropdown && (
      <SearchFilterButtonArrowSc isOpen={isOpen}>
        <Icon icon="next" isTiny wrapper={false} />
      </SearchFilterButtonArrowSc>
    )}
  </SearchFilterButtonSc>
)

export default SearchFilterButton
