import React, { ReactNode, forwardRef, RefObject } from 'react'
import Toggle from 'react-toggle'
import { FormInputInlineErrorSc } from '~/components/form-input/form-input.style'
import { ToggleWithLabelSc, ToggleLabelSc } from './toggle-with-label.style'

type Props = {
  children: ReactNode
  className?: string
  value?: string
  checked: boolean
  rightAlign?: boolean
  justified?: boolean
  disabled?: boolean
  errors?: any
  name?: string
  onChange?: (boolean) => any
  onChangeEvent?: (...args: any[]) => any // used by react-hook-form
}

const ToggleWithLabel = forwardRef(
  (
    {
      className,
      value,
      checked,
      rightAlign = false,
      justified = false,
      children,
      disabled = false,
      errors = null,
      name = null,
      onChange = null,
      onChangeEvent = null,
    }: Props,
    ref: RefObject<HTMLInputElement>,
  ) => {
    const changeAction = event => {
      if (onChange) {
        onChange(event.target.checked)
      }
      if (onChangeEvent) {
        onChangeEvent(event)
      }
    }

    return (
      <ToggleWithLabelSc isDisabled={disabled} justified={justified}>
        <ToggleLabelSc isDisabled={disabled} aria-label="Toggle" className={className}>
          {rightAlign && children}
          <Toggle
            ref={ref}
            tabIndex={0}
            checked={checked}
            value={value}
            onChange={changeAction}
            disabled={disabled}
            name={name}
          />
          {!rightAlign && children}
        </ToggleLabelSc>
        {errors?.message && <FormInputInlineErrorSc>{errors.message}</FormInputInlineErrorSc>}
      </ToggleWithLabelSc>
    )
  },
)

ToggleWithLabel.displayName = 'ToggleWithLabel'

export default ToggleWithLabel
