import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const BetterDecisionsSc = styled.div`
  display: flex;
  flex-direction: column;

  @media ${theme.breakpoints.desktop} {
    flex-direction: row;
  }
`
