import { styled } from '@linaria/react'
import theme from '~/styles/theme'

const getPaddingBottom = (paddingBottom, stickyAdMobile) => {
  if (stickyAdMobile) return '100px'
  if (paddingBottom) return '64px'
  return '0'
}

export const FooterSc = styled.footer<{
  paddingBottom: boolean
  stickyAdMobile: boolean
  stickyAdDesktop: boolean
  isSearch: boolean
}>`
  position: relative;
  padding-top: 64px;
  -webkit-touch-callout: none;
  user-select: none;

  // showPartners - mobile
  padding-bottom: ${({ paddingBottom, stickyAdMobile }) => getPaddingBottom(paddingBottom, stickyAdMobile)};
  @media ${theme.breakpoints.desktop} {
    padding-bottom: ${({ stickyAdDesktop }) => (stickyAdDesktop ? '100px' : '0')};
  }
`

export const FooterNavSc = styled.nav`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 20px;

  @media ${theme.breakpoints.desktop} {
    flex-direction: row;
  }

  @media ${theme.breakpoints.desktopLarge} {
    padding: 0;
  }
`

export const FooterBrandingContainerSc = styled.div``

export const LogoContainerSc = styled.div`
  padding-bottom: 32px;
`

export const MobileAppsContainerSc = styled.div`
  margin-bottom: 24px;
`

export const FooterListsContainerSc = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  @media ${theme.breakpoints.desktop} {
    flex-wrap: nowrap;
  }

  @media (min-width: 641px) {
    width: 580px;
  }
`

export const FooterListSc = styled.div`
  margin: 0 0 32px;

  &:last-child {
    margin: 0;
  }
  @media ${theme.breakpoints.desktop} {
    margin: 0;
  }

  width: 100%;
  @media (min-width: 376px) {
    width: 50%;
  }
  @media (min-width: 641px) {
    width: auto;
  }

  &:not(:last-child) li a span {
    position: relative;
    @media (min-width: 641px) {
      position: absolute;
    }

    top: 0;
    @media (min-width: 641px) {
      top: 12px;
    }
  }

  /* HACK: Shift For Agents section up on mobile */
  @media (min-width: 376px) and (max-width: 641px) {
    &:nth-of-type(3) {
      margin-top: -200px;
    }
  }
`

export const FooterListGroupTitleSc = styled.h3`
  font-weight: 700;
  padding: 0 8px 10px;
  text-transform: uppercase;
  font-size: 13px;
  color: ${theme.colors.headerText};
`

export const FooterLinkSc = styled.a`
  font-size: 14px;
  line-height: 26px;
  color: ${theme.colors.bodyText};
  padding: 6px;
  display: block;
  white-space: nowrap;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${theme.colors.redBase};
    }
  }
`

export const FooterLinkNewLabelSc = styled.span`
  position: relative;
  margin-left: 8px;
  padding: 2px 6px;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: ${theme.colors.whiteBase};
  background: linear-gradient(90deg, #843ee0 0, #eb25d7 48.92%, #f17494 100%);
  border-radius: 24px;
`

export const FooterCopyrightNavSc = styled(FooterNavSc)<{ isSearch: boolean }>`
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: 32px 20px 0 20px;
  padding: 32px 0 0 0;
  border-top: 1px solid ${theme.colors.border2};

  /* Not Search */
  @media ${theme.breakpoints.desktop} {
    flex-direction: ${({ isSearch }) => (!isSearch ? 'row' : 'column-reverse')};
    align-items: ${({ isSearch }) => (!isSearch ? 'center' : 'flex-start')};
    margin: ${({ isSearch }) => (!isSearch ? '32px auto 0 auto' : '32px 20px 0 20px')};
    padding: ${({ isSearch }) => (!isSearch ? '32px 20px 0 20px' : '32px 0 0 0')};
  }
`

export const CopyrightTextSc = styled.p<{ isSearch: boolean }>`
  margin: 0;
  font-size: 14px;
  line-height: 26px;
  color: ${theme.colors.bodyTextLight};

  span {
    display: block;

    /* Not Search */
    @media ${theme.breakpoints.desktop} {
      display: ${({ isSearch }) => (!isSearch ? 'inline' : 'block')};
    }
  }
`

export const LegalLinksContainerSc = styled.span<{ isSearch: boolean }>`
  margin: 8px 0;

  /* Not Search */
  @media ${theme.breakpoints.desktop} {
    margin: ${({ isSearch }) => (!isSearch ? '0 0 0 40px' : '8px 0')};
  }
`

export const LegalLinkSc = styled(FooterLinkSc)`
  display: inline-block;
  padding: 6px 0;

  &:not(:last-child) {
    margin-right: 40px;
  }
`

export const LineSc = styled.div`
  margin: 0 auto;
  max-width: 1200px;

  height: 1px;
  border-top: 1px solid ${theme.colors.border2};
  user-select: none;
`
