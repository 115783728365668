import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const ListingsScrollListSc = styled.div`
  padding: 0 0 28px;
  width: 100%;
`

export const ListingCardSc = styled.div`
  width: 310px;

  @media ${theme.breakpoints.desktop} {
    width: 288px;
  }
`
