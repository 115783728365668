import React, { useState, useEffect } from 'react';

import categories from '../../home-search-categories';

import SearchTabButton from '../search-tab-button';

import { SearchTabsSc } from './search-tabs.style';

type Props = {
  active: string;
  onSelect: (...args: any[]) => any;
};

const SearchTabs = ({ active, onSelect }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!isOpen) return;
      // Move left/right to select tab.
      if (event.key === 'ArrowLeft' || event.key === 'ArrowRight') {
        const index =
          categories.findIndex((category) => category.value === active) +
          (event.key === 'ArrowLeft' ? -1 : 1);
        if (index >= 0 && index < categories.length) {
          onSelect(categories[index]);
        }
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen, active, onSelect]);

  return (
    <SearchTabsSc role="tablist" aria-label="Search types">
      {categories.map((category) => (
        <SearchTabButton
          key={category.value}
          category={category}
          isActive={active === category.value}
          onFocus={handleOpen}
          onBlur={handleClose}
          onClick={onSelect}
        />
      ))}
    </SearchTabsSc>
  );
};

export default SearchTabs;
