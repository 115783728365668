import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const TitleSc = styled.h2`
  width: 100%;
  font-weight: ${theme.fontWeights.bold};
  font-size: 32px;
  line-height: 44px;
  color: ${theme.colors.headerText};

  @media ${theme.breakpoints.desktop} {
    font-size: 36px;
    line-height: 48px;
  }
`
