import { styled } from '@linaria/react'

export const ResultListSc = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  border-top: 1px solid ${({ theme }) => theme.colors.border2};
`

export default { ResultListSc }
