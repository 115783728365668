import React from 'react'

import MobileAppBadges from '~/components/mobile-app-badges'

import Picture from '../picture'

import {
  HomeAppSc,
  HomeAppTitleSc,
  HomeAppDescriptionSc,
  HomeAppImageHolderSc,
  BlurSc,
} from './home-app.style'

const HomeApp = () => (
  <HomeAppSc>
    <HomeAppTitleSc>Ready to get started?</HomeAppTitleSc>
    <HomeAppDescriptionSc>
      Set instant or daily emails and push notifications to be alerted about homes that match what
      you&apos;re looking for. Perfect!
    </HomeAppDescriptionSc>
    <MobileAppBadges pageElementPosition="mob_apps_sect" isLarger />
    <HomeAppImageHolderSc>
      <BlurSc />
      <Picture
        src="home/homely-mobile-app-new-property-listing-view-2"
        isPNG
        width={373}
        height={551}
        alt="Homely mobile app property view of a new listing"
        loading="lazy"
      />
    </HomeAppImageHolderSc>
  </HomeAppSc>
)

export default HomeApp
