import React from 'react';
import { GradientTextSc } from './gradient-text.style';

type Props = {
  isInlineBlock?: boolean;
  children: React.ReactNode;
};

const GradientText = ({ isInlineBlock = false, children }: Props) => (
  <GradientTextSc isInlineBlock={isInlineBlock}>{children}</GradientTextSc>
);

export default GradientText;
