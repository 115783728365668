import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const NavDropdownListSc = styled.ul`
  list-style: none;
  margin: 0;

  a,
  button {
    display: block;
    padding: 0;
    line-height: 40px;
    font-size: 15px;
    color: ${theme.colors.grayBase};
    background-color: transparent;
    border: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    /* Accessibility */
    cursor: pointer;
    body.no-touch &:hover,
    body.keynav-mode &:focus,
    &.active,
    &:active {
      color: ${theme.colors.redBase};
      outline: 0;
    }
  }
`
