import React, { ReactNode } from 'react';

import { DesktopOnlySc } from './desktop-only.style';

type Props = {
  isFlex?: boolean;
  children: ReactNode;
};

const DesktopOnly = ({ isFlex = false, children }: Props) => <DesktopOnlySc isFlex={isFlex}>{children}</DesktopOnlySc>;

export default DesktopOnly;
