import React, { ReactNode, RefObject, forwardRef, ElementType } from 'react';

import { FlatButtonSc } from './flat-button.style';

type Props = {
  children: ReactNode;
  primary?: boolean;
  small?: boolean;
  tiny?: boolean;
  bold?: boolean;
  as?: ElementType;
  title?: string;
  onClick?: () => void;
};

const FlatButton = forwardRef<RefObject<typeof FlatButtonSc>, Props>(
  ({ children, bold = true, ...props }, ref) => (
    <FlatButtonSc type="button" ref={ref} bold={bold} {...props}>
      {children}
    </FlatButtonSc>
  )
);

FlatButton.displayName = 'FlatButton';

export default FlatButton;
