import { styled } from '@linaria/react'
import theme from '~/styles/theme'

export const ListEmptySc = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  p {
    margin: 0 0 16px;
    font-weight: ${theme.fontWeights.bold};
    font-size: 20px;
    line-height: 32px;
  }
`

export const ListEmptyImageSc = styled.img`
  margin: 0 auto 32px;
`

export default {
  ListEmptySc,
  ListEmptyImageSc,
}
