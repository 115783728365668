import { styled } from '@linaria/react'
import theme from '~/styles/theme'

import ExternalHyperlink from '../external-hyperlink'

export const SocialLinkSc = styled(ExternalHyperlink)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  color: ${theme.colors.headerText};

  &:hover {
    color: ${theme.colors.grayBase};
  }

  &:not(:last-child) {
    margin-right: 12px;
  }
`
