import React from 'react'

import { contentTypes } from '~/lib/utils/google-tag-manager'

import DiscoverLink from './components/discover-link'

import { DiscoverDescriptionSc } from './discover.style'

const Discover = () => (
  <>
    <DiscoverDescriptionSc>
      Homely has Australia’s largest collection of street and suburb reviews, empowering you to find
      the right area for you to move to. With tens of thousands of local community topics and
      discussions, find the answers to all your questions about an area, or ask the locals.
    </DiscoverDescriptionSc>
    <DiscoverLink href="/where-to-live" contentType={contentTypes.locationSearchLink}>
      Find neighbourhood profiles
    </DiscoverLink>
    <DiscoverLink href="/discussions" contentType={contentTypes.qASearchLink}>
      View local discussions and forums
    </DiscoverLink>
  </>
)

export default Discover
