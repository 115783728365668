import type { ReactNode } from 'react'

import { getCdnPath } from '~/lib/utils/cdn-image'
import { ListEmptySc, ListEmptyImageSc } from './list-empty.style'

type Props = {
  image?: string
  children: ReactNode
}

const ListEmpty = ({ image = null, children }: Props) => (
  <ListEmptySc>
    {image && (
      <ListEmptyImageSc
        src={getCdnPath(`/assets/images/empty-${image}.svg`)}
        alt=""
        width={200}
        height={image === '1' ? 109 : 123}
      />
    )}
    {children}
  </ListEmptySc>
)

export default ListEmpty
