import React, { useRef, useState, useEffect } from 'react'
import { useUser } from '~/lib/providers/user-context'
import { useAlertsState } from '~/lib/providers/alerts-context'
import { checkSocialSignUp } from '~/lib/utils/auth'
import SolidButton from '~/components/buttons/solid-button'
import UnreadCountBadge from '~/components/unread-count-badge'
import ModalTrigger from '~/components/modal-trigger'
import Auth from '~/components/auth/components/modal'
import { NavTypes } from '~lib/config/nav-types'
import NavLink from '../nav-link'
import NavAuthLink from '../nav-auth-link'
import {
  NavRightSc,
  NavCollapseItemSc,
  NavSignupItemSc,
  NavAlertsLinkLabelSc,
} from './nav-right.style'

type Props = {
  active: string
  isAlt?: boolean
  pageType: PageTypes
  onNavClick?: (e: Event, url: string, as?: string) => void
}

const NavRight = ({ active, isAlt = false, pageType, onNavClick }: Props) => {
  const authTrigger = useRef<any>()
  const { user } = useUser()
  const { unreadCount } = useAlertsState()

  const [showSignInFirst, setShowSignInFirst] = useState(false)
  const [pageElementPosition, setPageElementPosition] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)

  const handleAuthClick = () => {
    setShowSignInFirst(false)
    setPageElementPosition('nav_join_btn')
    if (authTrigger.current) {
      authTrigger.current.open()
    }
  }

  const handleAuthLoginClick = () => {
    setShowSignInFirst(true)
    setPageElementPosition('nav_sign_in_btn')
    if (authTrigger.current) {
      authTrigger.current.open()
    }
  }

  /* Open auth modal to success screen if signed up via social. */
  useEffect(() => {
    if (user) {
      const isSocialSignUp = checkSocialSignUp()
      if (isSocialSignUp) {
        setShowSignInFirst(false)
        setPageElementPosition('nav_join_btn')
        setShowSuccess(true)
        if (authTrigger.current) {
          authTrigger.current.open()
        }
      }
    }
  }, [user])

  return (
    <>
      <NavRightSc>
        <NavCollapseItemSc isLoggedOut={!user}>
          <NavLink
            href="/collections"
            as="/collections"
            title="Collections"
            isActive={active === NavTypes.Collections}
            isHeader
            onClick={onNavClick}
          >
            Collections
          </NavLink>
        </NavCollapseItemSc>
        <NavCollapseItemSc isLoggedOut={!user}>
          <NavLink
            href="/alerts"
            as="/alerts"
            title="My Alerts"
            isActive={active === NavTypes.Alerts}
            isHeader
            onClick={onNavClick}
          >
            <NavAlertsLinkLabelSc>My Alerts</NavAlertsLinkLabelSc>
            <UnreadCountBadge count={unreadCount} />
          </NavLink>
        </NavCollapseItemSc>
        {!user && (
          <NavSignupItemSc>
            <SolidButton shortest smallest bold onClick={handleAuthClick}>
              Sign up
            </SolidButton>
          </NavSignupItemSc>
        )}
        <li>
          <NavAuthLink
            active={active}
            isAlt={isAlt}
            pageType={pageType}
            onNavClick={onNavClick}
            onAuthClick={handleAuthLoginClick}
          />
        </li>
      </NavRightSc>
      <ModalTrigger
        modal={
          <Auth
            showSignInFirst={showSignInFirst}
            showSuccess={showSuccess}
            pageElementPosition={pageElementPosition}
          />
        }
        ref={authTrigger}
      >
        <span />
      </ModalTrigger>
    </>
  )
}

export default NavRight
