import { useState, useEffect, useCallback } from 'react'

import type { PageTypes } from '~/lib/config/page-types'
import { getLocation, formatShortState } from '~/lib/utils/location-helper'
import requestIdleCallback from '~/lib/utils/request-idle-callback'

import TitleBreak from '~/components/title-break'

import Column from '../../components/column'
import Section from '../../components/section'
import Header from '../../components/header'
import HeaderLine from '../../components/header-line'
import Title from '../../components/title'

import HomeListingsLoader from './loader'

type Props = {
  pageType: PageTypes
}

const HomeListingsSection = ({ pageType }: Props) => {
  const [location, setLocation] = useState(null)

  const onPageLoad = useCallback(() => {
    requestIdleCallback(() => {
      getLocation(false).then(loc => setLocation(loc))
    })
  }, [])

  useEffect(() => {
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad)
    }
    return () => window.removeEventListener('load', onPageLoad)
  }, [])

  return (
    <Section>
      <Column>
        <Header>
          <HeaderLine />
          {!location ? (
            <Title>
              New properties <TitleBreak inlineMobile>on Homely</TitleBreak>
            </Title>
          ) : (
            <Title>
              {'Latest properties '}
              <TitleBreak inlineMobile>
                {`in ${location.name}, ${formatShortState(location.state?.name)}`}
              </TitleBreak>
            </Title>
          )}
        </Header>
        <HomeListingsLoader location={location} pageType={pageType} />
      </Column>
    </Section>
  )
}

export default HomeListingsSection
