import React, { ReactNode } from 'react';
import ScreenOnlySc from './screen-only.style';

type Props = {
  children: ReactNode;
};

const ScreenOnly = ({ children, ...props }: Props) => (
  <ScreenOnlySc {...props}>{children}</ScreenOnlySc>
);

export default ScreenOnly;
