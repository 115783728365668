import { styled } from '@linaria/react';

export const SearchFilterLocationModalSc = styled.div`
  width: 100%;
`;

export const SearchFilterLocationModalTriggerButtonSc = styled.button`
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background: none;
  border: 0;
  border-radius: 35px;
  -webkit-tap-highlight-color: transparent;
`;

export const SearchFilterLocationModalCancelButtonSc = styled.button`
  position: absolute;
  top: calc(5px + env(safe-area-inset-top));
  left: 0;
  display: inline-block;
  padding: 0 8px;
  line-height: 48px;
  background: none;
  border: 0;
  border-radius: 24px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
`;
