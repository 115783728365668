import { styled } from '@linaria/react'

export const SearchTagSc = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 6px 6px 6px 12px;
  min-width: 0;
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  font-size: 13px;
  line-height: 18px;
  user-select: none;
  color: ${({ theme }) => theme.colors.grayDark};
  border: 1px solid ${({ theme }) => theme.colors.blueDark};
  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: 16px;
`

export const SearchTagLabelSc = styled.span`
  flex: 1;
  margin: 0 6px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const SearchTagRemoveSc = styled.button`
  flex-shrink: 0;
  padding: 0;
  width: 18px;
  height: 18px;
  line-height: 18px;
  background-color: #e0e2e6;
  border: 0;
  border-radius: 9px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #c9cbce;
    }
  }

  &:focus {
    background-color: #c9cbce;
  }

  .icon {
    width: 18px;
    height: 18px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.blueDark};
    pointer-events: none;
  }
`

export default {
  SearchTagSc,
  SearchTagLabelSc,
  SearchTagRemoveSc,
}
