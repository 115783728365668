import url from 'url';

const socialPlatformUrlTemplates = {
  facebook: 'https://www.facebook.com/%s',
  instagram: 'https://www.instagram.com/%s/',
  linkedin: 'https://www.linkedin.com/in/%s',
  twitter: 'https://twitter.com/%s',
  youtube: 'https://youtube.com/%s',
  google: 'https://google.com/%s',
  pinterest: 'https://www.pinterest.com/%s',
};

export const getPlatformUrl = (userInput, platform) => {
  const parseQueryString = false;
  const slashesDenoteHost = true;
  const parsedUrl = url.parse(userInput, parseQueryString, slashesDenoteHost);

  // If a host was parsed out we leave it alone.
  // If not, we assume you gave as a username and create the URL for you from a template string.
  if (parsedUrl.host) {
    return userInput;
  }
  if (socialPlatformUrlTemplates[platform]) {
    return socialPlatformUrlTemplates[platform].replace('%s', userInput);
  }
  return userInput;
};

export default getPlatformUrl;
